import EE from 'eventemitter3';

export default class EventEmitter {
  private emitter: EE;

  constructor() {
    this.emitter = new EE();
  }

  subscribe = (event: string, listener: (...args: unknown[]) => void) => {
    this.emitter.on(event, listener);
  };

  unsubscribe = (event: string, listener: (...args: unknown[]) => void) => {
    this.emitter.removeListener(event, listener);
  };

  emit = (event: string, payload: unknown, error = false) => {
    this.emitter.emit(event, payload, error);
  };
}
