import { paramsToObject } from './paramsToObject';
import { removeEmptyStringInObject } from './removeEmptyStringInObject';

export default function prepareSearchFilters(
  filters: string | Record<string, string>
) {
  const urlParamsFilters = new URLSearchParams(filters);
  const entriesFilters = urlParamsFilters.entries();
  const objectFilters = paramsToObject(entriesFilters);

  return removeEmptyStringInObject({
    first_name2: objectFilters?.first_name2 ?? '',
    birth_day: objectFilters?.birth_day ?? '',
    birth_month: objectFilters?.birth_month ?? '',
    birth_year: objectFilters?.birth_year ?? '',
    birth_country: objectFilters?.birth_country ?? '',
    birth_region: objectFilters?.birth_region ?? '',
    birth_city: objectFilters?.birth_city ?? '',
    death_day: objectFilters?.death_day ?? '',
    death_month: objectFilters?.death_month ?? '',
    death_year: objectFilters?.death_year ?? '',
    death_country: objectFilters?.death_country ?? '',
    death_region: objectFilters?.death_region ?? '',
    death_city: objectFilters?.death_city ?? '',
    sex: objectFilters?.sex?.length > 0 ? objectFilters?.sex : 'both',
    sort: objectFilters?.sort ? objectFilters?.sort : '',
    alive: objectFilters?.alive?.length > 0 ? objectFilters?.alive : 'yes',
    birth_margin: objectFilters?.birth_margin ?? '',
    death_margin: objectFilters?.death_margin ?? '',
    maiden_name: objectFilters?.maiden_name ?? '',
  }) as Record<string, string>;
}
