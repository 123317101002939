import axios from 'axios';

export const checkRequestContentLength = (url: string) => {
  axios
    .get(url) // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      // Get the size of the response from the Content-Length header
      const contentLength = response.headers['content-length'];

      if (contentLength) {
        // Log the formatted response size
        console.log('Size:', formatSizeUnits(parseInt(contentLength, 10)));
      } else {
        // If Content-Length is not available, fallback to calculating size from response data
        const responseSize = Buffer.byteLength(
          JSON.stringify(response.data),
          'utf8'
        );
        console.log('Size', formatSizeUnits(responseSize));
      }
    }) // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((error: any) => {
      console.error('Error:', error);
    });
};
// Helper function to format the size in human-readable form

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatSizeUnits(bytes: any) {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + ' GB';
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + ' MB';
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes > 1) {
    bytes = bytes + ' bytes';
  } else if (bytes === 1) {
    bytes = bytes + ' byte';
  } else {
    bytes = '0 bytes';
  }
  return bytes;
}
