import * as React from 'react';

type IconProps = {
  height: number;
  width: number;
};

function MenuIcon({ height = 35, width = 35 }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <rect width="35" height="35" fill="white" />
      <mask id="a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.5 9.75h-28v-2h28v2zm0 8.75h-28v-2h28v2zm-28 8.75h28v-2h-28v2z"
        />
      </mask>
      <path
        d="M3.5 9.75h-2v2h2v-2zm28 0v2h2v-2h-2zm-28-2v-2h-2v2h2zm28 0h2v-2h-2v2zM3.5 18.5h-2v2h2v-2zm28 0v2h2v-2h-2zm-28-2v-2h-2v2h2zm28 0h2v-2h-2v2zm0 10.75v2h2v-2h-2zm-28 0h-2v2h2v-2zm28-2h2v-2h-2v2zm-28 0v-2h-2v2h2zm0-13.5h28v-4h-28v4zm-2-4v2h4v-2h-4zm30-2h-28v4h28v-4zm2 4v-2h-4v2h4zM3.5 20.5h28v-4h-28v4zm-2-4v2h4v-2h-4zm30-2h-28v4h28v-4zm2 4v-2h-4v2h4zm-2 6.75h-28v4h28v-4zm-2 0v2h4v-2h-4zm-26 2h28v-4h-28v4zm2 0v-2h-4v2h4z"
        fill="black"
        mask="url(#a)"
      />
    </svg>
  );
}

export default MenuIcon;
