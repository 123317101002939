/* eslint-disable @typescript-eslint/no-explicit-any */
import type { TypeOptions } from 'react-toastify';
import { toast } from 'react-toastify';

/**
 * Displays an error toast with a message
 * @param message {String}
 * @param type {'error'|'success'|'info'|'warn'}
 * @return {String}
 */

export const displayToast = (
  message: string,
  type: TypeOptions = 'error',
  icon?: any,
  toastId?: string | number
) =>
  toast(message, {
    autoClose: 5000,
    closeOnClick: true,
    style: { width: 'fit-content' },
    pauseOnHover: true,
    draggable: true,
    type: type,
    icon: icon ? icon : '',
    position: 'bottom-center',
    toastId: toastId,
  });
