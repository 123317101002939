/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';

export interface CountryReducerState {
  searchAttributeCameWith:
    | 'birth location'
    | 'birth country'
    | 'birth date'
    | 'death date'
    | 'death location'
    | 'death country'
    | null;
}

const initialState: CountryReducerState = {
  searchAttributeCameWith: null,
};

const { actions, reducer: editReducer } = createSlice({
  name: 'edit',
  initialState,
  reducers: {
    setSearchTermAttribute: (state, action) => {
      state.searchAttributeCameWith = action.payload.searchAttributeCameWith;
    },
  },
});

export { actions as editActions, editReducer };
