import axios from 'axios';
import * as AxiosLogger from 'axios-logger';
import axiosRetry from 'axios-retry';
import { formatSizeUnits } from './checkRequestContentLength';

axiosRetry(axios, {
  // This retries value is 0 because I am setting the retry condition only for get requests manually
  retries: 0,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  retryCondition(error: any) {
    // Conditional check the error status code
    switch (error?.response?.status) {
      case 500:
      case 502:
      case 503:
      case 504:
        return true; // Retry request with response status code 500, 503 and 504
      default:
        return false; // Do not retry the others
    }
  },
});

export const axiosInstance = axios.create();

if (process.env.NODE_ENV === 'development') {
  axiosInstance.interceptors.response.use(
    (response) => {
      const contentLength = response.headers['content-length'];

      if (contentLength) {
        // Log method, url, size
        console.log(
          'Method:',
          response.config.method?.toLocaleUpperCase(),
          'URL:',
          response.config.url,
          'Size:',
          formatSizeUnits(parseInt(contentLength, 10))
        );
      } else if (response?.data) {
        // If Content-Length is not available, fallback to calculating size from response data

        const responseSize = Buffer.byteLength(
          JSON.stringify(response.data),
          'utf8'
        );
        console.log(
          'Method:',
          response.config.method?.toLocaleUpperCase(),
          'URL:',
          response.config.url,
          'Size:',
          formatSizeUnits(responseSize)
        );
      }

      return response;
    },

    AxiosLogger.errorLogger
  );
}
