/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';

export interface SearchReducerState {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  first_name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  last_name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  country: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allCountries: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchResults: any;
  isMegaMenuOpen: boolean;
  isLoadingSearchResults: boolean;
  totalFound: number | null;
  isZoomIn: boolean | null;
  isZoomOut: boolean | null;
  regionFromRedux: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchedCountry: any;
  isDrawerOpen: boolean;
  totalMatches: number | null;
  matchesInResponse: number | null;
  isSearchTriggeredWithFilters: boolean;
  searchInAnotherCountry: boolean;
  isFiltersHidden?: boolean;
  redirectedFrom?: string;
  popularSearches?: {
    firstName: string;
    lastName: string;
    country: string;
    id: number;
    alive: string;
    birth_day: string;
    birth_month: string;
    birth_year: string;
  }[];
}

const initialState: SearchReducerState = {
  first_name: '',
  last_name: '',
  country: {},
  allCountries: [],
  searchResults: [],
  isMegaMenuOpen: false,
  isLoadingSearchResults: false,
  totalFound: null,
  isZoomIn: false,
  isZoomOut: false,
  regionFromRedux: 'world',
  searchedCountry: {},
  isDrawerOpen: true,
  totalMatches: null,
  matchesInResponse: null,
  isSearchTriggeredWithFilters: false,
  searchInAnotherCountry: false,
  isFiltersHidden: true,
  popularSearches: [],
  redirectedFrom: '',
};

const { actions, reducer: searchReducer } = createSlice({
  name: 'search',
  initialState,
  reducers: {
    reset: () => initialState,
    setSearchByCountry: (state, action) => {
      state.country = action.payload.country;
    },
    setAllCountries: (state, action) => {
      state.allCountries = action.payload.allCountries;
    },
    setSearchResult: (state, action) => {
      state.searchResults = action.payload.searchResults;
    },
    setIsMegaMenuOpen: (state, action) => {
      state.isMegaMenuOpen = action.payload.isMegaMenuOpen;
    },
    setIsLoadingSearchResults: (state, action) => {
      state.isLoadingSearchResults = action.payload.isLoadingSearchResults;
    },
    setTotalFound: (state, action) => {
      state.totalFound = action.payload.totalFound;
    },
    setIsZoomIn: (state, action) => {
      state.isZoomIn = action.payload.isZoomIn;
    },
    setIsZoomOut: (state, action) => {
      state.isZoomOut = action.payload.isZoomOut;
    },
    setRegionFromRedux: (state, action) => {
      state.regionFromRedux = action.payload.regionFromRedux;
    },
    setSearchedCountry: (state, action) => {
      state.searchedCountry = action.payload.searchedCountry;
    },
    setIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload.isDrawerOpen;
    },
    setTotalMatches: (state, action) => {
      state.totalMatches = action.payload.totalMatches;
    },
    setMatchesInResponse: (state, action) => {
      state.matchesInResponse = action.payload.matchesInResponse;
    },
    setFirstName: (state, action) => {
      state.first_name = action.payload.first_name;
    },
    setLastName: (state, action) => {
      state.last_name = action.payload.last_name;
    },
    setIsSearchTriggeredWithFilters: (state, action) => {
      state.isSearchTriggeredWithFilters =
        action.payload.isSearchTriggeredWithFilters;
    },
    setSearchInAnotherCountry: (state, action) => {
      state.searchInAnotherCountry = action.payload.searchInAnotherCountry;
    },
    setPopularSearches: (state, action) => {
      state.popularSearches = action.payload.popularSearches;
    },
    setIsFiltersHidden: (state, action) => {
      state.isFiltersHidden = action.payload.isFiltersHidden;
    },
    setRedirectedFromCountry: (state, action) => {
      state.redirectedFrom = action.payload.redirectedFrom;
    },
  },
});

export { actions, searchReducer };
