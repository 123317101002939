import * as React from 'react';

interface IconProperties {
  className?: string;
  width?: number;
  height?: number;
  viewBox?: string;
  title?: string;
  onClick?: () => void;
  opacity?: number;
  rotate?: string;
  fill?: string;
}

const ArrowDownIcon = ({ rotate, ...props }: IconProperties) => (
  <svg width={12} height={8} viewBox="0 0 12 8" fill="none" {...props}>
    <path
      rotate={rotate || '0'}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.58934 0.744078C1.2639 0.418641 0.736263 0.418641 0.410826 0.744078C0.0853888 1.06951 0.0853888 1.59715 0.410826 1.92259L5.41083 6.92259C5.73626 7.24803 6.2639 7.24803 6.58934 6.92259L11.5893 1.92259C11.9148 1.59715 11.9148 1.06951 11.5893 0.744078C11.2639 0.418641 10.7363 0.418641 10.4108 0.744078L6.00008 5.15482L1.58934 0.744078Z"
      fill="#00000080"
    />
  </svg>
);
export default ArrowDownIcon;
