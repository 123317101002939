import React from 'react';

interface ParagraphProps {
  children: React.ReactNode;
  className?: string;
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: number;
}

export const Paragraph = ({
  children,
  className,
  fontSize = 18,
  fontWeight = 400,
  lineHeight = 19,
}: ParagraphProps) => {
  return (
    <p
      className={`font-[${fontWeight}] text-[${fontSize}px] leading-[${lineHeight}px] ${className}`}
    >
      {children}
    </p>
  );
};
