import { nextUrl } from 'src/config';

export const checkDomainNames = (domainName: string) => {
  if (!domainName) {
    return '';
  }

  if (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    nextUrl === 'https://dev.thepopulationproject.org/api' ||
    nextUrl === 'http://localhost:3000/api'
  ) {
    return domainName?.includes('test_') ? domainName : `test_${domainName}`;
  } else {
    return domainName;
  }
};
