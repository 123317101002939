import { createSlice } from '@reduxjs/toolkit';

export interface UserReducerState {
  option: 'stats' | 'info';
  animatePopUp: boolean;
  ionutVariant: boolean;
  dorianVariant: boolean;
  tour: boolean;
}

const initialState: UserReducerState = {
  option: 'stats',
  animatePopUp: false,
  ionutVariant: false,
  dorianVariant: false,
  tour: false,
};

const { actions, reducer: homepageReducer } = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
    setOption: (state, action) => {
      state.option = action.payload.option;
    },
    setAnimatePopUp: (state, actions) => {
      state.animatePopUp = actions.payload;
    },
    setIonutVariant: (state, actions) => {
      state.ionutVariant = actions.payload;
    },
    setDorianVariant: (state, actions) => {
      state.dorianVariant = actions.payload;
    },
    setTour: (state, action) => {
      state.tour = action.payload.tour;
    },
    reset: () => initialState,
  },
});

export { actions as homepageActions, homepageReducer };
