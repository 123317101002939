import { checkDomainNames } from 'src/utils/checkDomainNames';

const projectName = 'The Population Project';
const siteName = 'thepopulationproject.org';

export const companyName = projectName; // For copyright ownership

export const appConfig = {
  brand: {
    appName: projectName,
    companyName,
    tagline:
      "A nonprofit organization striving to compile a list of every living person's full name and place and date of birth.",
    description: `Why ${projectName}? Because all lives deserve to be represented. In a generation or two, most of us will be forgotten. ${projectName} will keep a log of who lived when. It will be a resource for historians and genealogists alike. No record will ever be deleted.`,
    siteName,
    supportEmail: `support@${siteName}`,
    volunteerEmail: `volunteers@${siteName}`,
  },
  projectName,
} as const;

// export const apiUrl = 'https://tppmirrortest.objsyshost.com/API'; // * DEV
// export const apiUrlDomain = 'tppmirrortest'; // * DEV
export const apiUrl = 'https://tpprepository.objsyshost.com/API'; // * PROD
export const apiUrlDomain = 'tpprepository'; // * PROD

export const strapiApiUrl =
  'https://strapi-dashboard.thepopulationproject.org/api';
// 'http://localhost:1337/api';

export const showDevPages = true;

export const websiteUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://thepopulationproject.org' // ! Don't forget to change this
    : 'http://localhost:3000';

export const nextUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://thepopulationproject.org/api' // ! Don't forget to change this
    : 'http://localhost:3000/api';

export const worldDomainName = checkDomainNames('world_1_api.objsyshost.com');
