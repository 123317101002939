/* eslint-disable @typescript-eslint/ban-ts-comment */

import { nextUrl } from 'src/config';

/* eslint-disable @typescript-eslint/naming-convention */
export const trackCustomEvent = (
  category: string,
  action: string,
  name?: string,
  value?: number
) => {
  if (
    typeof window !== 'undefined' &&
    // @ts-ignore
    nextUrl === 'https://thepopulationproject.org/api'
  ) {
    // @ts-ignore
    const _paq = window._paq || [];
    // @ts-ignore
    const mtm = window?._mtm || _mtm || [];
    _paq.push(['trackEvent', category, action, name || category, value]);
    setTimeout(() => {
      mtm?.push({
        event:
          category === 'Bookmark created' ||
          category === 'Human Created' ||
          category === 'Edit'
            ? action
            : category,
      });
    }, 5000);
  }
};
