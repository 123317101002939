/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const paramsToObject = (entries: any) => {
  const result = {};
  for (const [key, value] of entries) {
    // @ts-ignore
    result[key] = value;
  }
  return result as { [key: string]: string };
};
