import cx from 'clsx';
import type { ElementType, HTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';
import type {
  AlignItemsClassName,
  ClassNames,
  SpaceBetweenClassName,
} from 'src/types/classNames';

export interface StackProps
  extends Partial<HTMLAttributes<JSX.IntrinsicElements>> {
  children?: ReactNode;

  className?: string;

  /**
   * @default 'items-center'
   */
  alignItems?: ClassNames<AlignItemsClassName>;

  /**
   * @default 'p'
   */
  component?: ElementType;

  /**
   * @default 'horizontal'
   */
  direction?: 'horizontal' | 'vertical' | 'dynamic';

  /**
   * @default 'space-x-1'
   */
  gap?: ClassNames<SpaceBetweenClassName>;

  /**
   * @default false
   */
  inline?: boolean;
}

function getDirectionClassNames({
  direction,
  inline,
}: Required<Pick<StackProps, 'direction' | 'inline'>>): string[] {
  if (direction === 'dynamic') {
    return ['flex', 'flex-col', 'md:flex-row'];
  }

  return direction === 'horizontal'
    ? ['flex-row', ...(inline ? ['inline-flex', 'flex-wrap'] : ['flex'])]
    : ['flex', 'flex-col'];
}

export function getStackClassName({
  className,
  direction = 'horizontal',
  alignItems = direction === 'dynamic'
    ? ['items-stretch', 'md:items-center']
    : direction === 'horizontal'
    ? 'items-center'
    : 'items-stretch',
  gap = direction === 'dynamic'
    ? ['space-x-1', 'md:space-y-1']
    : direction === 'horizontal'
    ? 'space-x-1'
    : 'space-y-1',
  inline = false,
}: Pick<
  StackProps,
  'className' | 'direction' | 'alignItems' | 'gap' | 'inline'
>) {
  return cx(
    gap,
    alignItems,
    getDirectionClassNames({
      direction,
      inline,
    }),
    className
  );
}

export const Stack = forwardRef<HTMLElement, StackProps>(
  (
    {
      children,
      component: Component = 'div',
      className,
      direction,
      alignItems,
      gap,
      inline,
      ...rest
    },
    ref
  ) => {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Component
        ref={ref}
        className={getStackClassName({
          className,
          direction,
          alignItems,
          gap,
          inline,
        })}
        {...rest}
      >
        {children}
      </Component>
    );
  }
);

Stack.displayName = 'Stack';
