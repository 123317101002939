import { createSlice } from '@reduxjs/toolkit';
import { DefaultNrOfResultsEnum } from 'src/types/user';
import { getCookie } from '../utils/cookies';

export interface UserReducerState {
  isLoggedIn: boolean;
  isCommingFromLearnMore: boolean;
  editCreateOption: string | null;
  userName: string | null;
  email: string | null;
  isEmailConfirmed: boolean;
  createdAt: string | null;
  userCountry: string | null;
  userId: number | null;
  defaultNrOfResults: DefaultNrOfResultsEnum;
  isNavigationFromTheApp: boolean;
}

const initialState: UserReducerState = {
  isLoggedIn: !!getCookie('userToken'), // presume user is logged in if there is a userToken cookie
  isCommingFromLearnMore: false,
  editCreateOption: null,
  userName: null,
  email: null,
  isEmailConfirmed: false,
  createdAt: null,
  userCountry: null,
  userId: null,
  defaultNrOfResults: DefaultNrOfResultsEnum.TEN,
  isNavigationFromTheApp: false,
};

const { actions, reducer: userReducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsUserLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;
    },
    setIsCommingFromLearnMore: (state, action) => {
      state.isCommingFromLearnMore = action.payload.isCommingFromLearnMore;
    },
    setEditCreateOption: (state, action) => {
      state.editCreateOption = action.payload.editCreateOption;
    },
    setUserName: (state, action) => {
      state.userName = action.payload.userName;
    },
    setUserEmail: (state, action) => {
      state.email = action.payload.email;
    },
    setEmailConfirmed: (state, action) => {
      state.isEmailConfirmed = action.payload.isEmailConfirmed;
    },
    setCreatedAt: (state, action) => {
      state.createdAt = action.payload.createdAt;
    },
    setUserCountry: (state, action) => {
      state.userCountry = action.payload.userCountry;
    },
    setUserId: (state, action) => {
      state.userId = action.payload.userId;
    },
    setDefaultNrOfResults: (state, action) => {
      state.defaultNrOfResults = action.payload.defaultNrOfResults;
    },
    setIsNavigationFromTheApp: (state, action) => {
      state.isNavigationFromTheApp = action.payload.isNavigationFromTheApp;
    },
  },
});

export { actions as userActions, userReducer };
