/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';

export interface LastNameDataObject {
  iso_2: string;
  iso_3: string;
  count: number;
}
export interface LastNameReducerState {
  lastNameData: LastNameDataObject[];
  lastNameAges: { [key: string]: number };
  lastNamesSoundingLike: any;
}

const initialState: LastNameReducerState = {
  lastNameData: [],
  lastNameAges: {},
  lastNamesSoundingLike: [],
};

const { actions, reducer: lastNameReducer } = createSlice({
  name: 'lastName',
  initialState,
  reducers: {
    setLastNameData: (state, action) => {
      state.lastNameData = action.payload.lastNameData;
    },
    setLastNameAges: (state, action) => {
      state.lastNameAges = action.payload.lastNameAges;
    },
    setLastNamesSoundingLike: (state, action) => {
      state.lastNamesSoundingLike = action.payload.lastNamesSoundingLike;
    },
  },
});

export { actions as lastNameActions, lastNameReducer };
