import * as React from 'react';

interface IconProperties {
  className?: string;
  width?: number;
  height?: number;
  viewBox?: string;
  title?: string;
  onClick?: () => void;
  opacity1?: number;
  opacity2?: number;
  fill?: string;
}

function ProfileIcon({
  opacity1,
  opacity2,
  className,
  fill = '#409F85',
  ...props
}: IconProperties) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99998 0.333252C7.88403 0.333252 8.73188 0.684441 9.357 1.30956C9.98212 1.93468 10.3333 2.78253 10.3333 3.66659C10.3333 4.55064 9.98212 5.39849 9.357 6.02361C8.73188 6.64873 7.88403 6.99992 6.99998 6.99992C6.11592 6.99992 5.26808 6.64873 4.64296 6.02361C4.01784 5.39849 3.66665 4.55064 3.66665 3.66659C3.66665 2.78253 4.01784 1.93468 4.64296 1.30956C5.26808 0.684441 6.11592 0.333252 6.99998 0.333252ZM6.99998 1.99992C6.55795 1.99992 6.13403 2.17551 5.82147 2.48807C5.50891 2.80063 5.33331 3.22456 5.33331 3.66659C5.33331 4.10861 5.50891 4.53254 5.82147 4.8451C6.13403 5.15766 6.55795 5.33325 6.99998 5.33325C7.44201 5.33325 7.86593 5.15766 8.17849 4.8451C8.49105 4.53254 8.66665 4.10861 8.66665 3.66659C8.66665 3.22456 8.49105 2.80063 8.17849 2.48807C7.86593 2.17551 7.44201 1.99992 6.99998 1.99992ZM6.99998 7.83325C9.22498 7.83325 13.6666 8.94158 13.6666 11.1666V13.6666H0.333313V11.1666C0.333313 8.94158 4.77498 7.83325 6.99998 7.83325ZM6.99998 9.41658C4.52498 9.41658 1.91665 10.6333 1.91665 11.1666V12.0833H12.0833V11.1666C12.0833 10.6333 9.47498 9.41658 6.99998 9.41658Z"
        fill="#6F757E"
        className={className}
      />
    </svg>
  );
}

export default ProfileIcon;
