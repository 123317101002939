import * as React from 'react';

function CloseIcon() {
  return (
    <svg
      width={31}
      height={31}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={0.4} stroke="#000" strokeWidth={2}>
        <path d="M22.8668 7.86702L7.77067 22.9631" />
        <path d="M22.6137 22.9633L7.51759 7.86716" />
      </g>
    </svg>
  );
}

export default CloseIcon;
