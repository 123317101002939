import * as React from 'react';
import type { IconProps } from '../types/icons';

export default function SearchIcon({
  width = 28,
  height = 28,
  fill = 'white',
  className = '',
  onClick = (f) => f,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width as number}
      height={height as number}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className as string}
      onClick={onClick}
    >
      <g id="magnify" transform={`scale(${width / 28},${height / 28})`}>
        <path
          id="Vector"
          d="M11.0833 3.5C13.0946 3.5 15.0234 4.29896 16.4456 5.72111C17.8677 7.14326 18.6667 9.07211 18.6667 11.0833C18.6667 12.9617 17.9783 14.6883 16.8467 16.0183L17.1617 16.3333H18.0833L23.9167 22.1667L22.1667 23.9167L16.3333 18.0833V17.1617L16.0183 16.8467C14.6883 17.9783 12.9617 18.6667 11.0833 18.6667C9.07211 18.6667 7.14326 17.8677 5.72111 16.4456C4.29896 15.0234 3.5 13.0946 3.5 11.0833C3.5 9.07211 4.29896 7.14326 5.72111 5.72111C7.14326 4.29896 9.07211 3.5 11.0833 3.5ZM11.0833 5.83333C8.16667 5.83333 5.83333 8.16667 5.83333 11.0833C5.83333 14 8.16667 16.3333 11.0833 16.3333C14 16.3333 16.3333 14 16.3333 11.0833C16.3333 8.16667 14 5.83333 11.0833 5.83333Z"
          fill={fill as string}
        />
      </g>
    </svg>
  );
}
