import cx from 'clsx';
import Link from 'next/link';
import type { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import { forwardRef } from 'react';

export const BaseTextLinkClasses =
  'cursor-pointer text-blue-600 hover:text-blue-800 visited:text-purple-600'; // dark:text-gray-50

export interface TextLinkProps
  extends DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  className?: string;

  href?: string;

  /**
   * @default 'internal'
   */
  type?: 'internal' | 'route' | 'external';
}

export const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>(
  ({ className, children, type = 'internal', href, ...rest }, ref) => {
    if (!href) {
      return (
        <span
          ref={ref}
          className={cx(BaseTextLinkClasses, className)}
          {...rest}
        >
          {children}
        </span>
      );
    }

    if (type === 'external') {
      return (
        <a
          ref={ref}
          className={cx(BaseTextLinkClasses, className)}
          href={href}
          rel="noopener noreferrer nofollow"
          target="_blank"
          {...rest}
        >
          {children}
        </a>
      );
    }
    return (
      // todo  - fix this if you'd like to, I don't think ref is necessary so it maybe can be removed? (ref is causing the type errors)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Link
        className={cx(BaseTextLinkClasses, className)}
        href={href}
        {...rest}
      >
        {children}
      </Link>
    );
  }
);

TextLink.displayName = 'TextLink';
