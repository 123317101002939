/* eslint-disable @typescript-eslint/naming-convention */
export interface User {
  name: string;
  picture?: string;
  title: string;
  email: string;
}

export interface EType {
  target: {
    value: string;
    innerText: string;
    textContent: string;
  };
}

export interface UserTypes {
  username: string;
  confirmed: boolean;
  email: string;
  createdAt: string;
  country: string;
  id: number;
  defaultNrOfResults?: DefaultNrOfResultsEnum;
}

export enum DefaultNrOfResultsEnum {
  'TEN' = '10',
  'TWENTY_FIVE' = '25',
  'FIFTY' = '50',
}

export interface NewHumanTypes {
  first_name1: string;
  first_name1_is_same: boolean;
  first_name2: string;
  first_name2_is_same: boolean;
  last_name1: string;
  last_name1_is_same: boolean;
  country: string | null;
  country_is_same: boolean;
  birth_year: number | null;
  birth_year_is_same: boolean;
  birth_month: number | null;
  birth_month_is_same: boolean;
  birth_day: number | null;
  birth_day_is_same: boolean;
  birth_location: string | undefined;
  birth_location_is_same: boolean;
  death_year: number | null;
  death_year_is_same: boolean;
  death_month: number | null;
  death_month_is_same: boolean;
  death_day: number | null;
  death_day_is_same: boolean;
  death_location: string | undefined;
  death_location_is_same: boolean;
  sex: string | null;
  sex_is_same: boolean;
  id: string | number | null | undefined;
}
