import { Stack } from 'src/components/core';

import type { SocialType } from './socialIconLink.component';
import { SocialIconLink } from './socialIconLink.component';
import SocialLinks from './socialLinks.json';

export interface SocialLinksProps {
  className?: string;
}

export function SocialIconList({ className }: SocialLinksProps) {
  return (
    <Stack component="ul" className={className}>
      {SocialLinks.map((link) => {
        return (
          <li
            className={`${
              link?.type === 'facebook' ? 'ml-[0px] mr-[0px]' : ''
            } `}
            key={link.type}
          >
            <SocialIconLink type={link.type as SocialType} href={link.url} />
          </li>
        );
      })}
    </Stack>
  );
}
