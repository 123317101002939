interface H3Props {
  children: string;
  className?: string;
  fontSize?: number;
}

export const H3 = ({ children, className, fontSize = 30 }: H3Props) => {
  return (
    <p
      className={`font-[500] text-[${fontSize}px] leading-[37px] ${className}`}
    >
      {children}
    </p>
  );
};
