import * as React from 'react';

function ErrorIcon() {
  return (
    <svg
      width={38}
      height={38}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 .667C8.875.667.667 8.875.667 19 .667 29.125 8.875 37.333 19 37.333c10.125 0 18.333-8.208 18.333-18.333C37.333 8.875 29.125.667 19 .667zm6.178 14.511a1.667 1.667 0 00-2.356-2.356L19 16.643l-3.822-3.821a1.667 1.667 0 10-2.356 2.356L16.643 19l-3.821 3.822a1.665 1.665 0 00-.021 2.377 1.667 1.667 0 002.377-.02L19 21.356l3.822 3.821a1.666 1.666 0 002.356-2.356L21.357 19l3.821-3.822z"
        fill="#D94F63"
      />
    </svg>
  );
}

export default ErrorIcon;
