/* eslint-disable @next/next/no-script-component-in-head */
import '../styles/global.css';
import 'react-modern-drawer/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { animated, useSpring } from '@react-spring/web';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { checkUserToken, getUserServerSide } from 'src/actions/userActions';
import { LogoNavbar } from 'src/components/nav/logoNavbar.component';
import { nextUrl } from 'src/config';
import { DefaultNrOfResultsEnum } from 'src/types/user';
import { deleteCookie, getCookie, setCookie } from 'src/utils/cookies';
import { displayToast } from 'src/utils/displayToast';
import { AppContext } from '../AppContext';
import ErrorIcon from '../assets/ErrorIcon';
import { ActionSheet } from '../components/action-sheet/action-sheet';
import { AppNavbar } from '../components/nav/appNavbar.component';
import { homepageActions } from '../reducers/homepageReducer';
import { userActions } from '../reducers/userReducers';
import type { RootState } from '../store';
import { wrapper } from '../store';
import type { OptionTypes } from '../types/actionSheetTypes';
import { cn } from '../utils/cn';
import EventEmitter from '../utils/EventEmitter';

const client = new EventEmitter();

function App({ Component, pageProps }: AppProps) {
  const { isMegaMenuOpen } = useSelector((state: RootState) => state.search);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();

  /* Options for cookies:
   * accepted - in this case we use all cookies
   * stricyly-necessary-cookies - in this case we let the user use the website feautres but without the cookies ment to save user choices (firstTimeOnTPP, user)
   * functional-cookies - in this case we let the user use the website features but with the cookies ment to save user choices (firstTimeOnTPP, mostVisitedCountriesIds, user)
   * performance-cookies - in this case we let everything work (default option)
   */

  const userAcceptedCookie = getCookie('userCookies');

  const [isCookiePopupOpen, setIsCookiePopupOpen] = useState<boolean>();
  const [isSecondCookiePopupOpen, setIsSecondCookiesPopup] = useState(false);

  useEffect(() => {
    const possibleOptionsForCookies = [
      'accepted',
      'stricyly-necessary-cookies',
      'functional-cookies',
      'performance-cookies',
    ];
    if (possibleOptionsForCookies.includes(userAcceptedCookie as string)) {
      setIsCookiePopupOpen(false);
    } else {
      setIsCookiePopupOpen(true);
    }
  }, [userAcceptedCookie]);

  const onClickDisable = () => {
    setIsCookiePopupOpen(false);
    setIsSecondCookiesPopup(true);
  };

  const cancelCustomCookies = () => {
    setIsCookiePopupOpen(true);
    setIsSecondCookiesPopup(false);
  };

  const onClickAccept = () => {
    setIsCookiePopupOpen(false);

    setCookie('userCookies', 'accepted', 43200);
  };

  const stylesForSecondCookiesPopup = useSpring({
    opacity: isSecondCookiePopupOpen ? 1 : 0,
  });

  const [strictlyNecessaryCookies, setStrictlyNecessaryCookies] =
    useState(false);
  const [functionalCookies, setFunctionatCookies] = useState(false);
  const [performanceCookies, setPerformanceCookies] = useState(true);

  const handleStrictlyNecessaryCookies = () => {
    setStrictlyNecessaryCookies(!strictlyNecessaryCookies);
    setFunctionatCookies(false);
    setPerformanceCookies(false);
  };

  const handleFunctionalCookies = () => {
    setStrictlyNecessaryCookies(false);
    setFunctionatCookies(!functionalCookies);
    setPerformanceCookies(false);
  };

  const handlePerformanceCookies = () => {
    setStrictlyNecessaryCookies(false);
    setFunctionatCookies(false);
    setPerformanceCookies(!performanceCookies);
  };

  const submitCustomCookies = () => {
    if (!strictlyNecessaryCookies && !functionalCookies && performanceCookies) {
      setCookie('userCookies', 'performance-cookies', 43200);
      setIsSecondCookiesPopup(false);
    }
    if (!strictlyNecessaryCookies && functionalCookies && !performanceCookies) {
      setCookie('userCookies', 'functional-cookies', 43200);
      setIsSecondCookiesPopup(false);
    }
    if (strictlyNecessaryCookies && !functionalCookies && !performanceCookies) {
      setCookie('userCookies', 'stricyly-necessary-cookies', 43200);
      setIsSecondCookiesPopup(false);
    }
    if (
      !strictlyNecessaryCookies &&
      !functionalCookies &&
      !performanceCookies
    ) {
      displayToast('Please select a type of cookie', 'error', <ErrorIcon />);
    }
  };

  const router = useRouter();

  const setCookieInformationForPrivacyPage = () => {
    dispatch(
      userActions.setIsCommingFromLearnMore({ isCommingFromLearnMore: true })
    );
  };

  const isOnPrivacyPage = router?.pathname === '/privacy' ? true : false;

  // Get user based on user_token
  const getUserBasedOnToken = () => {
    const userToken = getCookie('userToken');

    if (userToken && userToken?.toString()?.length > 0) {
      dispatch(userActions.setIsUserLoggedIn({ isLoggedIn: true }));
      dispatch(
        userActions.setEditCreateOption({
          editCreateOption: 'dissmissed-as-user',
        })
      );
      // Check if user token is valid
      checkUserToken(userToken as string)
        .then((res) => {
          if (res?.status === 'invalid') {
            displayToast(
              'Your session has expired. Please log in again.',
              'error',
              <ErrorIcon />
            );
            deleteCookie('userToken');
            deleteCookie('user');
            deleteCookie('rememberMe');
            dispatch(userActions.setIsUserLoggedIn({ isLoggedIn: false }));
            dispatch(
              userActions.setEditCreateOption({ editCreateOption: null })
            );
            dispatch(userActions.setUserName({ userName: null }));
            dispatch(
              userActions.setEmailConfirmed({ isEmailConfirmed: false })
            );
            dispatch(userActions.setCreatedAt({ createdAt: null }));
            dispatch(userActions.setUserCountry({ userCountry: null }));
          }
          if (res?.status === 'success') {
            getUserServerSide(userToken as string).then((res) => {
              const user = res;
              dispatch(userActions.setUserId({ userId: user?.id }));
              dispatch(userActions.setUserName({ userName: user?.username }));
              dispatch(
                userActions.setDefaultNrOfResults({
                  defaultNrOfResults:
                    DefaultNrOfResultsEnum[
                      user?.defaultNrOfResults as unknown as keyof typeof DefaultNrOfResultsEnum
                    ] || DefaultNrOfResultsEnum.TEN,
                })
              );
              if (!user?.email?.includes('@thepopulationproject.org')) {
                dispatch(userActions.setUserEmail({ email: user?.email }));
                dispatch(
                  userActions.setEmailConfirmed({ isEmailConfirmed: true })
                );
              } else {
                dispatch(userActions.setUserEmail({ email: null }));
                dispatch(
                  userActions.setEmailConfirmed({ isEmailConfirmed: false })
                );
              }

              if (user?.country?.length > 0) {
                dispatch(
                  userActions.setUserCountry({ userCountry: user?.country })
                );
              }

              dispatch(
                userActions.setCreatedAt({ createdAt: user?.createdAt })
              );
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const rememberMeCookie = getCookie('rememberMe');

  useEffect(() => {
    if (rememberMeCookie?.toString() === 'yes') {
      getUserBasedOnToken();
    }
  }, [rememberMeCookie]);

  const styleForHomapgeContainer = useSpring({
    opacity: isMegaMenuOpen ? 0.35 : 0,
  });

  useEffect(() => {
    // We need this to disable matomo to check on dev.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (nextUrl === 'https://thepopulationproject.org/api') {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-var
        var _mtm = (window._mtm = window._mtm || []);
        _mtm.push({
          'mtm.startTime': new Date().getTime(),
          event: 'mtm.Start',
        });
        const d = document,
          g = d.createElement('script'),
          s = d.getElementsByTagName('script')[0];

        g.async = true;
        g.src =
          'https://cdn.matomo.cloud/thepopulationproject.matomo.cloud/container_LPF3K9xC.js';

        const gtmScriptInit = d.createElement('script'),
          gtmScript = d.getElementsByTagName('script')[0];

        gtmScriptInit.async = true;
        gtmScriptInit.setAttribute('id', 'gtm-script');
        gtmScriptInit.setAttribute('strategy', 'afterInteractive');
        gtmScriptInit.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WL639LL')`;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gtmScript.parentNode.insertBefore(gtmScriptInit, gtmScript);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        s.parentNode.insertBefore(g, s);
      }, 100);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTimeout(() => {
      const tourCookie = getCookie('tour');
      const secondTourCookie = getCookie('secondTour');
      if (
        (document.referrer === '' ||
          document.referrer.startsWith(window.location.origin)) === false
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const _paq = window._paq || [];
        _paq.push([
          'AbTesting::create',
          {
            name: 'Homepage-Animations',
            percentage: 100,
            includedTargets: [
              {
                attribute: 'url',
                inverted: '0',
                type: 'equals_simple',
                value: 'https://thepopulationproject.org/',
              },
            ],
            excludedTargets: [],
            startDateTime: '2023/10/14 00:00:00 UTC',
            endDateTime: '2023/10/20 23:59:59 UTC',

            variations: [
              {
                name: 'original',
                // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
                activate: function (event: any) {
                  // nothing needs to be done here
                },
              },
              {
                name: 'Dorian',
                activate: function (event: {
                  onReady: (arg0: () => void) => void;
                }) {
                  event.onReady(() => {
                    if (typeof tourCookie === 'undefined') {
                      setCookie('tour', JSON.stringify(false), 60 * 60 * 24);
                    }
                    dispatch(homepageActions?.setDorianVariant(true));
                  });
                },
              },
              {
                name: 'Ionut',
                activate: function (event: {
                  onReady: (arg0: () => void) => void;
                }) {
                  event.onReady(() => {
                    if (typeof secondTourCookie === 'undefined') {
                      setCookie('secondTour', 'true', 60 * 60 * 24);
                    }
                    dispatch(homepageActions?.setIonutVariant(true));
                  });
                },
              },
            ],
            trigger: function () {
              return true; // here you can further customize which of your visitors will participate in this experiment
            },
          },
        ]);
      }
    }, 400);
  }, []);

  const [menuOption, setMenuOption] = useState<OptionTypes>('');

  const handleOption = (option: OptionTypes) => () => {
    setMenuOption(option);
  };

  const getNavbar = () => {
    // if router.pathname is /sign-in or sign up or set new password or continue as guest or forgot password, we don't want to show the navbar
    if (
      router.pathname === '/sign-in' ||
      router.pathname === '/sign-up' ||
      router.pathname === '/set-new-password' ||
      router.pathname === '/continue-as-guest' ||
      router.pathname === '/forgot-password'
    ) {
      return <LogoNavbar />;
    }
    return <AppNavbar countries={pageProps.countries} />;
  };

  const showActionSheet = useMemo(() => {
    if (
      router.pathname === '/sign-in' ||
      router.pathname === '/sign-up' ||
      router.pathname === '/set-new-password' ||
      router.pathname === '/continue-as-guest' ||
      router.pathname === '/forgot-password'
    ) {
      return false;
    }
    return true;
  }, [router.pathname]);

  return (
    <AppContext.Provider value={{ client: client }}>
      <GoogleOAuthProvider
        clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || ''}
      >
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, user-scalable=1, minimum-scale=1, maximum-scale=5"
          />
          <meta
            name="viewport"
            content="initial-scale=1.0001,viewport-fit=cover,user-scalable=1,maximum-scale=1"
          />
          {process.env.NODE_ENV !== 'development' && (
            <meta
              name="google-site-verification"
              content="vHEbbGBINsj52K51GVBxu6Hxxi7RUhl62OSf8PqiStI"
            />
          )}
        </Head>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <animated.div
          className={`fixed z-10 size-full bg-black ${
            isMegaMenuOpen ? '' : 'hidden'
          }`}
          style={styleForHomapgeContainer}
        />
        <NextNProgress
          color="#409F85"
          options={{
            showSpinner: false,
            minimum: 0.1,
          }}
          showOnShallow={false}
          stopDelayMs={0}
        />
        {pageProps.countries && getNavbar()}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Component {...pageProps} />
        {showActionSheet && (
          <ActionSheet
            countries={pageProps.countries}
            option={menuOption}
            handleOption={handleOption}
          />
        )}
        {/* FIRST COOKIE POPUP */}
        {!isOnPrivacyPage && (
          <div
            className={cn(
              'fixed bottom-0 left-0 z-[999] flex w-full flex-col shadow-[0_-1px_20px_rgba(0,0,0,.11)] transition-opacity opacity-0',
              isCookiePopupOpen === undefined && 'opacity-0',
              !isCookiePopupOpen && 'hidden',
              isCookiePopupOpen && 'opacity-100'
            )}
          >
            <div
              className={`mt-auto flex items-center bg-white px-[20px] max-md:w-full max-md:flex-col md:h-[154px] md:justify-between md:px-[60px] xl:h-[92px] xl:px-[68.5px]`}
            >
              <div
                className={`flex max-xl:flex-col max-md:mt-[23px] max-md:w-full md:pr-[200px] xl:items-center xl:pr-0`}
              >
                <p className="hidden text-[18px] font-[500] leading-[22px] max-xl:mr-auto max-xl:pb-[10px] xl:mr-[30px] xl:block">
                  This site uses cookies{' '}
                </p>
                <div className="flex xl:hidden">
                  <p className="text-[18px] font-[500] leading-[22px] max-xl:mr-auto max-xl:pb-[10px] xl:mr-[30px]">
                    This site uses cookies{' '}
                  </p>
                  <button
                    onClick={setCookieInformationForPrivacyPage}
                    aria-label="Learn More"
                  >
                    <Link
                      href="/privacy"
                      prefetch={false}
                      className={`text-[16px] font-[500] leading-[20px] text-[#409F85] underline`}
                    >
                      Learn More
                    </Link>
                  </button>
                </div>
                <div className="mb-[12px] h-px w-full bg-black xl:hidden" />
                <div className="mr-[30px] hidden h-[53px] w-px bg-black xl:block" />
                <p className="mb-[52px] mr-auto text-[16px] font-[400] leading-[20px] max-xl:mr-auto max-xl:pb-[10px] md:mb-0 xl:mr-[17px]">
                  We use cookies to improve your experience and support our
                  mission.{' '}
                </p>
                <button
                  className="hidden xl:block"
                  onClick={setCookieInformationForPrivacyPage}
                  aria-label="Learn More"
                >
                  <Link
                    href="/privacy"
                    prefetch={false}
                    className={`text-[16px] font-[500] leading-[20px] text-[#409F85] underline`}
                  >
                    Learn More
                  </Link>
                </button>
              </div>
              <div
                className={`flex flex-row-reverse items-center max-md:mb-[21px] max-md:w-full md:flex-col-reverse xl:flex-row`}
              >
                <button
                  onClick={onClickDisable}
                  className="px-[28px] py-[12px]"
                  title="Disable Cookies"
                >
                  <p className="text-[16px] font-[500] leading-[135.4%] tracking-[0.01em] text-[#D94F63]">
                    DISABLE
                  </p>
                </button>
                <button
                  onClick={onClickAccept}
                  className={`rounded-[4px] bg-[#409F85] py-[12px] max-md:w-full md:px-[28px]`}
                  title="Accept Cookies"
                >
                  <p className="text-[18px] font-[600] tracking-[0.04em] text-white">
                    ACCEPT
                  </p>
                </button>
              </div>
            </div>
          </div>
        )}
        {/* SECOND COOKIE POPUP */}
        {isSecondCookiePopupOpen && !isOnPrivacyPage && (
          <animated.div
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style={stylesForSecondCookiesPopup}
            className="fixed inset-y-0 left-0 z-[999] flex w-full flex-col bg-[#00000030] shadow-[0_-1px_20px_rgba(0,0,0,.11)] transition"
          >
            <div
              className={`my-auto flex size-full flex-col bg-white xl:mx-auto xl:h-[539px] xl:w-[700px]`}
            >
              <div className="flex items-center justify-between bg-[#409f8526] px-[26.30px] py-[24px]">
                <div className="w-[20px]" />
                <p className="text-[20px] font-[500] leading-[24px]">
                  Cookie Settings
                </p>
                <button className="opacity-80" onClick={cancelCustomCookies}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask id="path-1-inside-1_2375_662" fill="white">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.0001 11.4143L18.293 19.7072L19.7072 18.293L11.4143 10.0001L19.7072 1.70718L18.293 0.292969L10.0001 8.58586L1.70718 0.292969L0.292969 1.70718L8.58586 10.0001L0.292969 18.293L1.70718 19.7072L10.0001 11.4143Z"
                      />
                    </mask>
                    <path
                      d="M18.293 19.7072L16.8788 21.1214L18.293 22.5356L19.7072 21.1214L18.293 19.7072ZM10.0001 11.4143L11.4143 10.0001L10.0001 8.58586L8.58586 10.0001L10.0001 11.4143ZM19.7072 18.293L21.1214 19.7072L22.5356 18.293L21.1214 16.8788L19.7072 18.293ZM11.4143 10.0001L10.0001 8.58586L8.58586 10.0001L10.0001 11.4143L11.4143 10.0001ZM19.7072 1.70718L21.1214 3.1214L22.5356 1.70718L21.1214 0.292968L19.7072 1.70718ZM18.293 0.292969L19.7072 -1.12125L18.293 -2.53546L16.8788 -1.12124L18.293 0.292969ZM10.0001 8.58586L8.58586 10.0001L10.0001 11.4143L11.4143 10.0001L10.0001 8.58586ZM1.70718 0.292969L3.1214 -1.12124L1.70718 -2.53546L0.292969 -1.12124L1.70718 0.292969ZM0.292969 1.70718L-1.12124 0.292969L-2.53546 1.70718L-1.12124 3.1214L0.292969 1.70718ZM8.58586 10.0001L10.0001 11.4143L11.4143 10.0001L10.0001 8.58586L8.58586 10.0001ZM0.292969 18.293L-1.12124 16.8788L-2.53546 18.293L-1.12125 19.7072L0.292969 18.293ZM1.70718 19.7072L0.292968 21.1214L1.70718 22.5356L3.1214 21.1214L1.70718 19.7072ZM19.7072 18.293L11.4143 10.0001L8.58586 12.8285L16.8788 21.1214L19.7072 18.293ZM18.293 16.8788L16.8788 18.293L19.7072 21.1214L21.1214 19.7072L18.293 16.8788ZM10.0001 11.4143L18.293 19.7072L21.1214 16.8788L12.8285 8.58586L10.0001 11.4143ZM18.293 0.292969L10.0001 8.58586L12.8285 11.4143L21.1214 3.1214L18.293 0.292969ZM16.8788 1.70718L18.293 3.1214L21.1214 0.292968L19.7072 -1.12125L16.8788 1.70718ZM11.4143 10.0001L19.7072 1.70718L16.8788 -1.12124L8.58586 7.17165L11.4143 10.0001ZM0.292969 1.70718L8.58586 10.0001L11.4143 7.17165L3.1214 -1.12124L0.292969 1.70718ZM1.70718 3.1214L3.1214 1.70718L0.292969 -1.12124L-1.12124 0.292969L1.70718 3.1214ZM10.0001 8.58586L1.70718 0.292969L-1.12124 3.1214L7.17165 11.4143L10.0001 8.58586ZM1.70718 19.7072L10.0001 11.4143L7.17165 8.58586L-1.12124 16.8788L1.70718 19.7072ZM3.1214 18.293L1.70718 16.8788L-1.12125 19.7072L0.292968 21.1214L3.1214 18.293ZM8.58586 10.0001L0.292969 18.293L3.1214 21.1214L11.4143 12.8285L8.58586 10.0001Z"
                      fill="black"
                      mask="url(#path-1-inside-1_2375_662)"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex flex-col pl-[37px] pr-[48px] pt-[32px] ">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col">
                    <p className="mb-[8px] text-[16px] font-[600] leading-[20px] ">
                      Strictly necessary cookies
                    </p>
                    <p
                      className={`pr-[20px] text-[14px] font-[400] tracking-[139.4%] xl:pr-[71px]`}
                    >
                      These cookies are essential so that you can move around
                      the website and use its features. Without these cookies
                      services you have asked for cannot be provided.
                    </p>
                  </div>
                  <label className="cookie-input-container mb-[25px]">
                    <input
                      type="radio"
                      checked={strictlyNecessaryCookies}
                      onChange={handleStrictlyNecessaryCookies}
                      name="radio"
                      alt="Strictly necessary cookies"
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="mt-[23px] h-px bg-black opacity-20" />
              <div className="flex flex-col pl-[37px] pr-[48px] pt-[23px] ">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col">
                    <p className="mb-[8px] text-[16px] font-[600] leading-[20px] ">
                      Functional cookies
                    </p>
                    <p
                      className={`pr-[20px] text-[14px] font-[400] tracking-[139.4%] xl:pr-[71px]`}
                    >
                      These cookies allow the website to remember choices you
                      make to give you better functionality and personal
                      features.
                    </p>
                  </div>
                  <label className="cookie-input-container mb-[35px]">
                    <input
                      type="radio"
                      checked={functionalCookies}
                      onChange={handleFunctionalCookies}
                      alt="Functional cookies"
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="mt-[23px] h-px bg-black opacity-20" />
              <div className="flex flex-col pl-[37px] pr-[48px] pt-[23px] ">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col">
                    <p className="mb-[8px] text-[16px] font-[600] leading-[20px] ">
                      Performance cookies
                    </p>
                    <p
                      className={`pr-[20px] text-[14px] font-[400] tracking-[139.4%] xl:pr-[71px]`}
                    >
                      These cookies help to improve the performance of the
                      website.
                    </p>
                  </div>
                  <label className="cookie-input-container mb-[40px]">
                    <input
                      type="radio"
                      checked={performanceCookies}
                      onChange={handlePerformanceCookies}
                      alt="Performance cookies"
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="mt-auto flex justify-between pb-[30px] pr-[48px]">
                <div />
                <div className="flex">
                  <button
                    onClick={cancelCustomCookies}
                    className="px-[28px] py-[12px]"
                  >
                    <p className="font-[500] tracking-[0.01em] text-[#D94F63]">
                      CANCEL
                    </p>
                  </button>
                  <button
                    onClick={submitCustomCookies}
                    className="rounded-[4px] bg-[#409F85] px-[28px] py-[12px]"
                  >
                    <p className="text-[18px] font-[600] leading-[22px] tracking-[0.04em] text-white">
                      SUBMIT
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </animated.div>
        )}
      </GoogleOAuthProvider>
    </AppContext.Provider>
  );
}

export default appWithTranslation(wrapper.withRedux(App));
