import { animated, useSpring } from '@react-spring/web';
import type { ChangeEvent, RefObject } from 'react';
import { useState } from 'react';
import OutsideClickHandler from '../core/outsideclickhandler';

interface InputFieldProps {
  placeholder: string;
  value: string;
  children?: JSX.Element | boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  className?: string;
  inputClassName?: string;
  error?: boolean;
  warning?: boolean;
  type: 'text' | 'password';
  width?: number;
  height?: number;
  disabled?: boolean;
  inputRef: RefObject<HTMLInputElement>;
  readOnly?: boolean;
  widthFull?: boolean;
  defaultValue?: string;
  alt?: string;
  id?: string;
}

export const InputFieldNoLabel = ({
  placeholder,
  value,
  children,
  onChange,
  onClick,
  className,
  inputClassName,
  error,
  warning,
  type,
  width,
  height,
  disabled = false,
  inputRef,
  id,
  readOnly = false,
  widthFull = false,
  alt,
}: InputFieldProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const stopFocus = () => {
    setIsFocused(false);
  };

  const startFocus = () => {
    setIsFocused(true);
    inputRef?.current?.focus();
  };

  const onClickInput = () => {
    if (onClick) {
      onClick();
    }
  };

  const stylesForPlaceholder = useSpring({
    marginTop: isFocused || value?.length > 0 ? -40 : 0,
    config: { tension: 800, friction: 50 },
  });

  return (
    <OutsideClickHandler
      onOutsideClick={stopFocus}
      styles={`relative ${className} flex items-center justify-left`}
    >
      <animated.div
        style={stylesForPlaceholder}
        onClick={startFocus}
        className={`absolute ml-[7px] ${
          isFocused ? 'pb-[5px]' : ''
        } flex items-center justify-start px-[7px] h-[12.1px] bg-white transition`}
      >
        <p
          className={`${
            isFocused || value?.length > 0
              ? 'text-[14px] leading-[17px] font-[500] tracking-[0.01em] text-[#A8A8A8]'
              : 'text-[16px] leading-[20px] font-[400] text-[#8F8F8F]'
          } transition`}
        >
          {placeholder}
        </p>
      </animated.div>
      <input
        ref={inputRef}
        id={id}
        disabled={disabled}
        readOnly={readOnly}
        onClick={onClickInput}
        alt={alt}
        type={type}
        className={`pl-[7px] ${
          widthFull ? 'w-full' : width ? `w-[${width}px]` : ''
        } ${height ? `h-[${height}px]` : ''} ${
          error
            ? 'border-[#D94F63] bg-[#FFF6F6] shadow-[0px_0px_0px_2px_rgba(217,79,99,0.4)] focus:shadow-[0px_0px_0px_2px_rgba(217,79,99,0.4)]'
            : warning
            ? 'border-[#EE883B] bg-[#FFFBF6] shadow-[0px_0px_0px_2px_rgba(238,136,49,0.4)]'
            : 'border-[#949494] shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.2)] focus:shadow-[0px_0px_0px_2px_rgba(154,220,225,0.7)]'
        } ${
          inputClassName && inputClassName
        } border-[1px] disabled:opacity-[0.3] rounded-[5px] transition appearance-none account-input`}
        onFocus={startFocus}
        onChange={onChange}
        value={value ? value : ''}
      />
      {children && children}
    </OutsideClickHandler>
  );
};
