type SearchesIconProps = {
  className?: string;
};

export const SearchesIcon = (props: SearchesIconProps) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="13.5" cy="13.5" r="12.5" stroke="#5A5A5A" strokeWidth="2" />
      <g clipPath="url(#clip0_217_15796)">
        <path
          d="M13.7446 12.2728C15.778 12.2728 17.4264 10.6244 17.4264 8.591C17.4264 6.55759 15.778 4.90918 13.7446 4.90918C11.7112 4.90918 10.0627 6.55759 10.0627 8.591C10.0627 10.6244 11.7112 12.2728 13.7446 12.2728Z"
          stroke="#5A5A5A"
          strokeWidth="2"
        />
        <path
          d="M17.4263 13.7451H17.6855C18.2238 13.7453 18.7436 13.942 19.1471 14.2983C19.5506 14.6547 19.8101 15.1461 19.8769 15.6803L20.1648 17.9807C20.1907 18.1879 20.1722 18.3983 20.1106 18.5978C20.049 18.7974 19.9456 18.9815 19.8074 19.1381C19.6692 19.2946 19.4992 19.42 19.3088 19.5059C19.1185 19.5917 18.912 19.6361 18.7031 19.636H8.78579C8.57695 19.6361 8.37048 19.5917 8.18009 19.5059C7.98971 19.42 7.81976 19.2946 7.68152 19.1381C7.54329 18.9815 7.43993 18.7974 7.37831 18.5978C7.31669 18.3983 7.29821 18.1879 7.32411 17.9807L7.61129 15.6803C7.67808 15.1458 7.93781 14.6542 8.34164 14.2978C8.74547 13.9415 9.26559 13.7449 9.80418 13.7451H10.0626"
          stroke="#5A5A5A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_217_15796">
          <rect
            width="17.6727"
            height="17.6727"
            fill="white"
            transform="translate(4.9082 3.43652)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
