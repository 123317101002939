import { calls } from 'src/utils/calls';
import { axiosInstance } from 'src/utils/instances';

export const signUp = (email: string, username: string, password: string) => {
  return axiosInstance(calls.signUp(email, username, password))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const signUpWithNoEmail = (username: string, password: string) => {
  return axiosInstance(calls.signUpWithNoEmail(username, password))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const login = (username: string, password: string) => {
  return axiosInstance(calls.login(username, password))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const loginWithGoogle = (accessToken: string) => {
  return axiosInstance(calls.loginWithGoogle(accessToken))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const checkUserToken = (userToken: string) => {
  return axiosInstance(calls.checkUserToken(userToken))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getUser = (userToken: string) => {
  return axiosInstance(calls.getUser(userToken))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getUserServerSide = (userToken: string) => {
  return axiosInstance(calls.getUserServerSide(userToken))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const forgotPassword = (email: string) => {
  return axiosInstance(calls.forgotPassword(email))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const resetPassword = (
  password: string,
  passwordConfirmation: string,
  code: string
) => {
  return axiosInstance(
    calls.resetPassword(password, passwordConfirmation, code)
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const changePassword = (
  userToken: string,
  password: string,
  passwordConfirmation: string,
  currentPassword: string
) => {
  return axiosInstance(
    calls.changePassword(
      userToken,
      password,
      passwordConfirmation,
      currentPassword
    )
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteAccount = (
  userToken: string,
  userId: string,
  password: string
) => {
  return axiosInstance(calls.deleteAccount(userToken, userId, password))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const emailConfirmation = (code: string) => {
  return axiosInstance(calls.emailConfirmation(code))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const sendEmailConfirmation = (email: string) => {
  return axiosInstance(calls.sendEmailConfirmation(email))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateConfirmation = (
  userToken: string,
  userId: string,
  confirmed: boolean
) => {
  return axiosInstance(calls.updateConfirmation(userToken, userId, confirmed))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateEmail = (
  userToken: string,
  userId: string,
  email: string
) => {
  return axiosInstance(calls.updateEmail(userToken, userId, email))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateCountry = (
  userToken: string,
  userId: string,
  country: string
) => {
  return axiosInstance(calls.updateCountry(userToken, userId, country))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateDefaultNrOfRows = (
  userToken: string,
  userId: string,
  nrOfRows: string
) => {
  return axiosInstance(calls.updateNrOfRows(userToken, userId, nrOfRows))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
