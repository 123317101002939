import cx from 'clsx';
import type { ReactNode } from 'react';
import { Stack } from 'src/components/core';

import type { SpinnerProps, SpinnerSize } from './spinner.component';
import { Spinner } from './spinner.component';
import type { TypeVariant } from './type.component';
import { Type } from './type.component';

interface LoaderProps {
  className?: string;

  /**
   * @default false
   */
  inline?: boolean;

  /**
   * @default 'Loading...'
   */
  label?: ReactNode;

  /**
   * @default 'medium'
   */
  size?: SpinnerSize;

  spinnerProps?: Partial<SpinnerProps>;
}

const SizeVariants: Record<SpinnerSize, TypeVariant> = {
  small: 'text-sm',
  medium: 'text-base',
  large: 'text-lg',
};

export function Loader({
  className,
  inline = false,
  label = 'Loading...',
  size = 'medium',
  spinnerProps,
}: LoaderProps) {
  return (
    <Stack
      direction={size === 'large' ? 'vertical' : 'horizontal'}
      inline={inline}
      gap={size === 'large' ? 'space-y-2' : 'space-x-2'}
      alignItems="items-center"
      className={cx('justify-center', size === 'large' && 'h-full', className)}
    >
      <Spinner size={size} {...spinnerProps} />
      <Type variant={SizeVariants[size]}>{label}</Type>
    </Stack>
  );
}
