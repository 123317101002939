import { Menu, Transition } from '@headlessui/react';
import { deleteCookie } from 'cookies-next';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowDownIcon from 'src/assets/ArrowDownIcon';
import EditHistoryIcon from 'src/assets/EditHistoryIcon';
import LogOutIcon from 'src/assets/LogOutIcon';
import MyRecordsIcon from 'src/assets/MyRecordsIcon';
import ProfileIcon from 'src/assets/ProfileIcon';
import { userActions } from 'src/reducers/userReducers';
import type { RootState } from 'src/store';

import { cn } from 'src/utils/cn';

export default function AccountDropdown() {
  const { t } = useTranslation('new-navbar');
  const { userName, email } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const router = useRouter();

  const logout = () => {
    deleteCookie('userToken');
    deleteCookie('user');
    deleteCookie('rememberMe');
    deleteCookie('isSigneWithApple');
    dispatch(userActions.setIsUserLoggedIn({ isLoggedIn: false }));
    dispatch(userActions.setEditCreateOption({ editCreateOption: null }));
    dispatch(userActions.setUserName({ userName: null }));
    dispatch(userActions.setEmailConfirmed({ isEmailConfirmed: false }));
    dispatch(userActions.setCreatedAt({ createdAt: null }));
    dispatch(userActions.setUserCountry({ userCountry: null }));

    router?.push(router.asPath);
  };

  return (
    <Menu as="div" className="font-Montserrat relative inline-block text-left">
      <div>
        <Menu.Button className="tablet:block hidden  items-center justify-between gap-x-1.5 text-nowrap rounded-md border-0 bg-white px-3 py-2 text-left text-sm font-semibold text-[#0F1928] hover:text-[#257C6B]">
          <span className=" block-inline w-fit text-left font-medium">
            My Account
          </span>
          <ArrowDownIcon
            className="mx-2 inline size-3 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right text-nowrap rounded-md bg-white text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <div
                className={cn(
                  'block p-4 text-sm flex text-ellipsis flex-col text-sm'
                )}
              >
                <span className="overflow-hidden text-ellipsis">
                  {userName}
                </span>
                <span className="h-fit w-full overflow-hidden text-ellipsis text-xs text-gray-400">
                  {email}
                </span>
              </div>
            )}
          </Menu.Item>
          <div className="border-y py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  prefetch={false}
                  href="/account"
                  className={cn(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-4 text-sm font-normal flex gap-4 items-center'
                  )}
                >
                  <div className="flex size-4 items-center justify-center">
                    <ProfileIcon className="size-5" />
                  </div>
                  <span>Profile</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  prefetch={false}
                  href={'/account?option=my-records'}
                  className={cn(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-4 text-sm font-normal flex gap-4 items-center'
                  )}
                >
                  <div className="flex size-4 items-center justify-center">
                    <MyRecordsIcon className="size-5" />
                  </div>

                  <span>My records</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  prefetch={false}
                  href={'/account?option=edit-history'}
                  className={cn(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-4 text-sm font-normal flex gap-4 items-center'
                  )}
                >
                  <div className="flex size-4 items-center justify-center">
                    {' '}
                    <EditHistoryIcon className="size-[15px]" />
                  </div>
                  <span>Edit History</span>
                </Link>
              )}
            </Menu.Item>
          </div>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={logout}
                className={cn(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 rounded-b-lg mt-1 py-4 text-sm font-normal w-full text-left flex items-center gap-4'
                )}
              >
                <div className="size-5">
                  <LogOutIcon className="size-5" />
                </div>
                <span>Log out</span>
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
