/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import ChevronDown from 'src/assets/ChevronDown';
import XIcon from 'src/assets/XIcon';
import type { RootState } from 'src/store';
import useIsBrowser from '../../hooks/useIsBrowser';
import { SocialIconList } from '../social/socialIconList.component';

const Drawer: any = dynamic(() => import('react-modern-drawer'), {
  suspense: true,
});

interface MenuProps {
  isOpen: boolean;
  handleStateChange: (state: boolean) => () => void;
  isMobileLayout: boolean;
  isTabletLayout?: boolean;
}

export default function Menu({
  isOpen,
  handleStateChange,
  isMobileLayout,
  isTabletLayout,
}: MenuProps) {
  const { t, i18n } = useTranslation('new-navbar');
  const isBrowser = useIsBrowser();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoggedIn } = useSelector((state: RootState) => state.user);

  const styleForAccordion = useSpring({
    height: isAccordionOpen ? 124 : 24,
    config: {
      tension: 500,
      friction: 50,
    },
  });

  const handleOpenAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  if (!isBrowser) {
    return null;
  }

  return (
    <Drawer
      className="align-center z-[50] py-12 text-md font-medium flex flex-col justify-start overflow-y-auto"
      direction="right"
      open={isOpen}
      size={isMobileLayout ? 300 : 524}
      onClose={handleStateChange(false)}
    >
      <button
        onClick={handleStateChange(false)}
        className={`fixed right-2 p-3 top-4  cursor-pointer`}
      >
        <XIcon />
      </button>
      <div className="bg-gray-200 w-full h-[1px] my-4"></div>
      <animated.div
        className={`${isAccordionOpen ? 'mb-7' : 'mb-4'}`}
        // eslint-disable-next-line
        // @ts-ignore
        style={styleForAccordion}
      >
        <button
          onClick={handleOpenAccordion}
          className={`flex items-center w-full py-2 px-[15px]`}
        >
          <p className="text-sm font-medium pl-[15px]">
            The Population Project
          </p>
          <div
            className={`transition-all ${
              !isAccordionOpen ? 'rotate-0' : 'rotate-180'
            }`}
          >
            <ChevronDown fill="#9FA3A9" />
          </div>
        </button>
        {isAccordionOpen && (
          <div className="pl-1 text-sm font-normal">
            <Link
              prefetch={false}
              href="/what-is-the-population-project"
              passHref
              onClick={handleStateChange(false)}
              className={`flex items-center py-2 ${
                isMobileLayout ? 'px-[15px]' : 'px-[55px]'
              } hover:bg-gray-100`}
            >
              <p
                className={`${
                  isMobileLayout ? 'pl-[15px] text-[14px]' : 'pl-[40px]'
                } `}
              >
                {t('what-is-the-population-project-navbar')}
              </p>
            </Link>
            <Link
              prefetch={false}
              href={'/why-the-population-project'}
              passHref
              onClick={handleStateChange(false)}
              className={`flex items-center py-2 ${
                isMobileLayout ? 'px-[15px]' : 'px-[55px]'
              } hover:bg-gray-100`}
            >
              <p
                className={`${
                  isMobileLayout ? 'pl-[15px] text-[14px]' : 'pl-[40px]'
                } `}
              >
                {t('why-the-population-project-navbar')}
              </p>
            </Link>
            <Link
              prefetch={false}
              href={'/who-is-the-population-project'}
              passHref
              onClick={handleStateChange(false)}
              className={`flex items-center py-2 ${
                isMobileLayout ? 'px-[15px]' : 'px-[55px]'
              } hover:bg-gray-100`}
            >
              <p
                className={`${
                  isMobileLayout ? 'pl-[15px] text-[14px]' : 'pl-[40px]'
                } `}
              >
                {t('who-is-the-population-project-navbar')}
              </p>
            </Link>
          </div>
        )}
      </animated.div>
      <Link
        prefetch={false}
        href={'/methodology'}
        passHref
        onClick={handleStateChange(false)}
        className={`flex items-center py-2 ${
          isMobileLayout ? 'px-[15px]' : 'px-[55px]'
        } hover:bg-gray-100`}
      >
        <p
          className={`${
            isMobileLayout ? 'pl-[15px] text-[14px]' : 'pl-[40px]'
          } text-medium font-medium`}
        >
          {t('methodology')}
        </p>
      </Link>
      <Link
        prefetch={false}
        href={'/how-you-can-help'}
        passHref
        onClick={handleStateChange(false)}
        className={`flex items-center py-2 ${
          isMobileLayout ? 'px-[15px]' : 'px-[55px]'
        } hover:bg-gray-100`}
      >
        <p
          className={`${
            isMobileLayout ? 'pl-[15px] text-[14px]' : 'pl-[40px]'
          } text-medium font-medium`}
        >
          {t('how-you-can-help')}
        </p>
      </Link>
      <Link
        prefetch={false}
        href={'/partners'}
        passHref
        onClick={handleStateChange(false)}
        className={`flex items-center py-2 ${
          isMobileLayout ? 'px-[15px]' : 'px-[55px]'
        } hover:bg-gray-100`}
      >
        <p
          className={`${
            isMobileLayout ? 'pl-[15px] text-[14px]' : 'pl-[40px]'
          } text-medium font-medium`}
        >
          {t('our-partners')}
        </p>
      </Link>
      <Link
        prefetch={false}
        href={'/blog'}
        passHref
        onClick={handleStateChange(false)}
        className={`flex items-center py-2 ${
          isMobileLayout ? 'px-[15px]' : 'px-[55px]'
        } hover:bg-gray-100`}
      >
        <p
          className={`${
            isMobileLayout ? 'pl-[15px] text-[14px]' : 'pl-[40px]'
          } text-medium font-medium`}
        >
          {t('blog')}
        </p>
      </Link>
      <Link
        prefetch={false}
        href={'/contact-us'}
        passHref
        onClick={handleStateChange(false)}
        className={`flex items-center py-2 ${
          isMobileLayout ? 'px-[15px]' : 'px-[55px]'
        } hover:bg-gray-100`}
      >
        <p
          className={`${
            isMobileLayout ? 'pl-[15px] text-[14px]' : 'pl-[40px]'
          } text-medium font-medium`}
        >
          {t('contact-us')}
        </p>
      </Link>{' '}
      <Link
        prefetch={false}
        href={'/privacy'}
        passHref
        onClick={handleStateChange(false)}
        className={`flex items-center py-2 ${
          isMobileLayout ? 'px-[15px]' : 'px-[55px]'
        } hover:bg-gray-100`}
      >
        <p
          className={`${
            isMobileLayout ? 'pl-[15px] text-[14px]' : 'pl-[40px]'
          } text-medium font-medium`}
        >
          {t('privacy-policy')}
        </p>
      </Link>
      <div className="mt-auto mb-14">
        <SocialIconList className="text-gray-400 mx-[30px]" />
      </div>
    </Drawer>
  );
}
