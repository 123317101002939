/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  setCookie as set,
  getCookie as get,
  getCookies as getMany,
  hasCookie as has,
  deleteCookie as deleteOne,
} from 'cookies-next';

export const setCookie = (
  name: string,
  value: string,
  age = 30 * 24 * 60 * 60
) => {
  set(name, value, {
    maxAge: age,
    path: '/',
    sameSite: 'strict',
    secure: true,
  });
};

export const getCookie = (name: string) => get(name);

export const getServerSideCookies = (name: string, ssr: any) => get(name, ssr);

export const getCookies = () => getMany();

export const hasCookie = (name: string) => has(name);

export const deleteCookie = (name: string) => deleteOne(name);
