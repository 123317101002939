import * as React from 'react';

export default function XIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1667 1.34163L10.9917 0.166626L6.33333 4.82496L1.675 0.166626L0.5 1.34163L5.15833 5.99996L0.5 10.6583L1.675 11.8333L6.33333 7.17496L10.9917 11.8333L12.1667 10.6583L7.50833 5.99996L12.1667 1.34163Z"
        fill="#9FA3A9"
      />
    </svg>
  );
}
