import cx from 'clsx';
import type { IconType } from 'react-icons';
import { FaLinkedin } from 'react-icons/fa';
import { FaFacebook, FaInstagram, FaXTwitter } from 'react-icons/fa6';

export type SocialType = 'facebook' | 'twitter' | 'linkedin' | 'instagram';

export interface SocialIconLinkProps {
  className?: string;
  href: string;
  type: SocialType;
}

const Icons: Record<SocialType, IconType> = {
  facebook: FaFacebook,
  twitter: FaXTwitter,
  linkedin: FaLinkedin,
  instagram: FaInstagram,
};

export function SocialIconLink({ className, href, type }: SocialIconLinkProps) {
  const Icon = Icons[type];

  return (
    <a
      href={href}
      target="_blank"
      title={type}
      rel="noopener noreferrer nofollow"
      className={cx('inline-block p-1 rounded-sm', className)}
    >
      <Icon size={32} />
    </a>
  );
}
