/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { animated, useSpring } from '@react-spring/web';
import debounce from 'lodash/debounce';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { ChangeEvent, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { SearchesIcon } from 'src/assets/SearchesIcon';
import XIcon from 'src/assets/XIcon';
import { Logo } from 'src/components/brand/logo.component';
import { userActions } from 'src/reducers/userReducers';
import type { RootState } from 'src/store';
import type { SearchesTypes } from 'src/types/search';
import type { DataWorldChartTypes } from 'src/types/types';
import { getCookie, setCookie } from 'src/utils/cookies';
import { displayToast } from 'src/utils/displayToast';
import { listOfPopularSearches } from 'src/utils/listOfPopularSearches';
import { timeZoneCityToCountry } from 'src/utils/time-zone-city-to-country';
import { trackCustomEvent } from 'src/utils/trackCustomEvents';
import CloseIcon from '../../assets/CloseIcon';
import ErrorIcon from '../../assets/ErrorIcon';
import MenuIcon from '../../assets/MenuIcon';
import SearchIcon from '../../assets/SearchIcon';

import { actions as searchActions } from '../../reducers/searchReducer';
import { cn } from '../../utils/cn';
import { formatName } from '../../utils/format';
import Menu from '../core/menu';
import OutsideClickHandler from '../core/outsideclickhandler';
import { Spinner } from '../core/spinner.component';
import AccountDropdown from '../dropdown/account-dropdown';
import NavDropdown from '../dropdown/navDropdown';
import ReactTooltip from '../react-tooltip/ReactTooltip';
import type { PrimaryNavbarProps } from './primaryNavbar.component';
import { PrimaryNavbar } from './primaryNavbar.component';

export type AppNavbarProps = Partial<PrimaryNavbarProps>;

export function AppNavbar({ countries, ...navbarProps }: AppNavbarProps) {
  const {
    isMegaMenuOpen,
    isLoadingSearchResults,
    searchedCountry,
    allCountries,
    searchInAnotherCountry,
  } = useSelector((state: RootState) => state.search);
  const { userCountry, userName, isLoggedIn, defaultNrOfResults } = useSelector(
    (state: RootState) => state.user
  );
  const { ionutVariant } = useSelector((state: RootState) => state.homepage);
  const { i18n, t } = useTranslation(['navbar', 'new-navbar']);
  const [selectedOption, setSelectedOption] =
    useState<DataWorldChartTypes | null>(null);
  const [selectedLanguageOption, setSelectedLanguageOption] = useState<string>(
    t('english')
  );
  const [isMobileLayout, setIsMobileLayout] = useState<boolean>(false);
  const [isTabletLayout, setIsTabletLayout] = useState<boolean>(false);
  const [searchTermCountry, setSearchTermCountry] = useState<string>('');
  const [countryTerm, setCountryTerm] = useState<string>('');
  const [showCountriesOptions, setShowCountriesOptions] = useState(false);
  const [suggestedCountry, setSuggestedCountry] = useState('');
  const [lastSearches, setLastSearches] = useState<SearchesTypes[]>([]);
  const [lastSearchFocusIndex, setLastSearchFocusIndex] = useState<number>(0);
  const [lastPopularSearchFocusIndex, setLastPopularSearchFocusIndex] =
    useState<number>(0);
  const [isBottomOfTheCountriesDropdown, setBottomOfTheCountriesDropdown] =
    useState(false);
  const recentSearchesRef = useRef<HTMLAnchorElement>(null);
  const popularSearchesRef = useRef<HTMLAnchorElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isFiltersHidden, setIsFilterHidden] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const mostVisitedCountriesIds = getCookie('mostVisitedCountriesIds');

  const recentSearches = getCookie('recentSearches');

  const secondTour = getCookie('secondTour');

  const router = useRouter();
  const dispatch = useDispatch();

  const [countriesOptions, setCountriesOptions] = useState<
    readonly any[] | undefined
  >(undefined);

  const [popularSearches, setPopularSearches] = useState<any[] | undefined>([]);

  const [showRecentSearchesList, setShowRecentSearchesList] =
    useState<boolean>(true);

  const [showPopularSearchesList, setShowPopularSearchesList] =
    useState<boolean>(false);

  const showRecentSearches = () => {
    setShowRecentSearchesList(true);
    setShowPopularSearchesList(false);
  };

  const showPopularSearches = () => {
    setShowRecentSearchesList(false);
    setShowPopularSearchesList(true);
  };

  const clearRecentHistory = () => {
    setLastSearches([]);
    setCookie('recentSearches', JSON.stringify([]));
    setShowRecentSearchesList(true);
    setShowPopularSearchesList(false);
  };

  /*
    * handleSearch functionality:
    * First, it tries to find the country that was searched for by the user by checking if the ISO code
      of the selected country matches with the data in the list of all countries.
    * If the mostVisitedCountriesIds variable is defined, and the countryFound variable is also defined,
      it adds the ID of the found country to the beginning of the array and then removes any duplicates.
      If the new array has less than four items, it sets a cookie with the filtered array.
      This is done to save the most visited countries by the user in a cookie so we can use it later.
    * If the mostVisitedCountriesIds variable is not defined and we have a countryFound, it sets a cookie with an array containing only the ID of the found country.
    * If the countryFound variable is defined and the lastName and firstName variables have a length greater than zero,
      it sets search-related state values using Redux, closes a dropdown menu, and navigates to a new page.
    * If this component is rendered on the search page, it navigates to the same page with the same query parameters, but with the new search-related state values.
    * If this component is rendered on any other page, it navigates to the search page with the new search-related state values.
    * If there is no countryFound or there is no lastName and firstName, it displays an error toast.
    * Lastly, it closes the search bar.
  */
  const handleSearch = () => {
    if (firstName?.length === 0 && lastName?.length === 0) {
      openMegaMenu();
      return;
    }
    const countryFound =
      (countries || allCountries)?.find(
        (country: DataWorldChartTypes) =>
          country?.name?.toLowerCase() === searchTermCountry?.toLowerCase()
      ) ||
      (countries || allCountries)?.find(
        (country: DataWorldChartTypes) =>
          country?.iso_2?.toLowerCase() ===
          (router?.query?.country as string)?.toLowerCase()
      );

    if (mostVisitedCountriesIds) {
      if (countryFound) {
        const newMostVisitedCountriesIds = [
          ...JSON.parse(mostVisitedCountriesIds as string),
        ];

        newMostVisitedCountriesIds.unshift(countryFound.ID);

        const filteredMostVisitedCountriesIds = [
          ...new Set(newMostVisitedCountriesIds),
        ];

        if (newMostVisitedCountriesIds?.length < 4) {
          setCookie(
            'mostVisitedCountriesIds',
            JSON.stringify(filteredMostVisitedCountriesIds)
          );
        } else {
          setCookie(
            'mostVisitedCountriesIds',
            JSON.stringify(filteredMostVisitedCountriesIds.slice(0, 3))
          );
        }
      }
    } else {
      setCookie(
        'mostVisitedCountriesIds',
        JSON.stringify([(countryFound as DataWorldChartTypes)?.ID])
      );
    }

    if (countryFound && lastName?.length > 0 && firstName?.length > 0) {
      dispatch(
        searchActions.setIsLoadingSearchResults({
          isLoadingSearchResults: true,
        })
      );

      dispatch(
        searchActions.setFirstName({
          first_name: firstName,
        })
      );

      dispatch(
        searchActions.setLastName({
          last_name: lastName,
        })
      );

      closeMegaMenu();

      router.push({
        pathname: '/search',
        query: {
          firstName,
          lastName,
          // @ts-ignore
          country: countryFound?.iso_2,
          page: 1,
          pageSize: defaultNrOfResults,
        },
      });

      trackCustomEvent(
        'Search from search bar',
        `${firstName} ${lastName} - ${countryFound?.name}`
      );

      if (recentSearches) {
        const search = {
          country: countryFound?.name,
          firstName,
          lastName,
        };

        const newRecentSearches = [...JSON.parse(recentSearches as string)];

        newRecentSearches.unshift(search);

        const filteredRecentSearches = newRecentSearches.filter(
          (search, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.country === search.country &&
                t.firstName === search.firstName &&
                t.lastName === search.lastName
            )
        );

        if (filteredRecentSearches?.length <= 5) {
          setCookie('recentSearches', JSON.stringify(filteredRecentSearches));
        } else {
          const newfilteredRecentSearches = filteredRecentSearches?.slice(0, 5);

          setCookie(
            'recentSearches',
            JSON.stringify(newfilteredRecentSearches)
          );
        }
      } else {
        const search = {
          country: countryFound?.name,
          firstName,
          lastName,
        };

        setCookie('recentSearches', JSON.stringify([search]));
      }
    }

    if (
      !countryFound ||
      lastName?.length < 1 ||
      !lastName ||
      firstName?.length < 1 ||
      !firstName
    ) {
      displayToast(t('please-select'), 'error', <ErrorIcon />);
    }

    if (!isFiltersHidden) {
      setIsFilterHidden(true);
    }
    dispatch(
      searchActions.setIsSearchTriggeredWithFilters({
        isSearchTriggeredWithFilters: false,
      })
    );
  };
  useEffect(() => {
    !!router.query.firstName && setFirstName(String(router.query.firstName));
    !!router.query.lastName && setLastName(String(router.query.lastName));
  }, [router.query]);

  const handleStateChange = (state: boolean) => () => setIsOpen(state);

  const onOpenMenu = () => {
    setIsOpen(true);
  };

  const handleChangeLastName = (e: { target: HTMLInputElement }) => {
    const name = formatName(e.target.value);
    setLastName(name);
  };

  const handleChangeFirstName = (e: { target: HTMLInputElement }) => {
    const name = formatName(e.target.value);
    setFirstName(name);
  };

  const handleLanguageChange = (e: { value: SetStateAction<string> }) => {
    // @ts-ignore
    i18n
      .changeLanguage(e.value as string, () => {
        // @ts-ignore
        router.replace(router.asPath, router.asPath, { locale: e.value });
      })
      .catch((error) => {
        console.log('error', error);
      });
    // @ts-ignore
    setSelectedLanguageOption(e.value);
  };

  const handleOpenSearchModal = () => {
    setIsFilterHidden(!isFiltersHidden);
  };

  const closeMegaMenu = () => {
    if (isMegaMenuOpen) {
      dispatch(searchActions.setIsMegaMenuOpen({ isMegaMenuOpen: false }));
      router.query?.firstName &&
        setFirstName(router.query.firstName?.toString());
      router.query?.lastName && setLastName(router.query.lastName?.toString());
      setShowCountriesOptions(false);
      putCountryOutOfFocus();
    }
  };

  const openMegaMenu = () => {
    if (!isMobileLayout && !isMegaMenuOpen) {
      dispatch(searchActions.setIsMegaMenuOpen({ isMegaMenuOpen: true }));
      // close tour if it's open
      setCookie('tour', JSON.stringify(true), 60 * 60 * 24);
      setCookie('secondTour', 'false', 60 * 60 * 24);
      setCloseSearchBarAnimation(true);
    }
  };

  const handleChangeCountryInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTermCountry(formatName(e?.target?.value));
    setCountryTerm(formatName(e?.target?.value));

    const suggestedCountry = countriesOptions?.find(
      (country: DataWorldChartTypes) =>
        country?.name?.includes(e?.target?.value) &&
        country?.name?.charAt(0) === e?.target?.value?.charAt(0)
    );

    setSuggestedCountry(suggestedCountry?.label);

    if (e?.target?.value?.length === 0) {
      setOptionForCountryCursor(-1);
      setSuggestedCountry('');
      setCountryTerm('');
      dispatch(
        searchActions.setSearchedCountry({
          searchedCountry: {},
        })
      );
    }
  };

  const selectCountryByIndex = (index: number) => () => {
    const selectedOption = countriesOptions?.find(
      (option: { index: number }) => option.index === index
    );

    setSelectedOption(selectedOption as DataWorldChartTypes);
    setSearchTermCountry(selectedOption?.name as string);
    setOptionForCountryCursor(index);

    dispatch(
      searchActions.setSearchedCountry({
        searchedCountry: {
          ...selectedOption,
        },
      })
    );
  };

  const selectCountry = (countrySelected: DataWorldChartTypes) => () => {
    const country = countriesOptions?.find((country: DataWorldChartTypes) => {
      return (
        country?.name?.toLowerCase() === countrySelected?.name?.toLowerCase()
      );
    });

    const index = countriesOptions?.findIndex(
      (option: { name: string }) =>
        option?.name?.toLowerCase() === countrySelected?.name?.toLowerCase()
    ) as number;

    setSelectedOption(country as DataWorldChartTypes);
    setSearchTermCountry(country?.name as string);
    setOptionForCountryCursor(index);
    dispatch(
      searchActions.setSearchedCountry({
        searchedCountry: {
          ...country,
        },
      })
    );
    putCountryOutOfFocus();
    handleHideCountriesOptions();
  };

  const handleShowCountriesOptions = () => {
    setShowCountriesOptions(true);
  };

  const handleHideCountriesOptions = () => {
    setShowCountriesOptions(false);
  };

  const languageOptions = [
    {
      value: 'en',
      label: isTabletLayout || isMobileLayout ? 'EN' : t('english'),
    },
    {
      value: 'fr',
      label: isTabletLayout || isMobileLayout ? 'FR' : t('french'),
      isdisabled: true,
    },
  ];

  const [optionForCountryCursor, setOptionForCountryCursor] = useState(0);

  const countriesContainerRef = useRef<HTMLDivElement>(null);

  const checkScrollOfTheCountriesDropdown = () => {
    const div = countriesContainerRef.current;

    if (
      div &&
      div.scrollHeight - div.scrollTop === div.clientHeight &&
      countriesOptions &&
      countriesOptions?.length > 6
    ) {
      setBottomOfTheCountriesDropdown(true);
    } else {
      setBottomOfTheCountriesDropdown(false);
    }
  };

  useEffect(() => {
    if (countriesContainerRef?.current) {
      const container = countriesContainerRef?.current;
      const selectedOption = container?.children?.[optionForCountryCursor];

      if (selectedOption) {
        const containerRect = container?.getBoundingClientRect();
        const optionRect = selectedOption?.getBoundingClientRect();

        // Scroll if the selected option is not fully visible
        if (optionRect?.bottom > containerRect?.bottom) {
          container.scrollTop += optionRect?.bottom - containerRect?.bottom;
        } else if (optionRect?.top < containerRect?.top) {
          container.scrollTop -= containerRect?.top - optionRect?.top;
        }
      }
    }
  }, [optionForCountryCursor]);

  const handleKeyDown = useCallback(
    (e: { key: string; code: string; keyCode: number }) => {
      const cursorIndex = optionForCountryCursor;
      if (
        e?.keyCode === 40 &&
        cursorIndex < (countriesOptions as readonly any[])?.length - 1
      ) {
        setOptionForCountryCursor(cursorIndex + 1);
        setSuggestedCountry('');
        setSelectedOption(
          (countriesOptions as readonly any[])[cursorIndex + 1]
        );
        setSearchTermCountry(
          (countriesOptions as readonly any[])[cursorIndex + 1]?.label
        );
        dispatch(
          searchActions.setSearchedCountry({
            searchedCountry: {
              ...(countriesOptions as readonly any[])[cursorIndex + 1],
            },
          })
        );
        const countryFoundId = (countries || allCountries)?.find(
          // @ts-ignore
          (country, index) => index === cursorIndex + 1
        )?.ID;

        if (mostVisitedCountriesIds) {
          if (countryFoundId) {
            const newMostVisitedCountriesIds = [
              ...JSON.parse(mostVisitedCountriesIds as string),
            ];

            newMostVisitedCountriesIds.unshift(countryFoundId);

            const filteredMostVisitedCountriesIds = [
              ...new Set(newMostVisitedCountriesIds),
            ];

            if (newMostVisitedCountriesIds?.length < 4) {
              setCookie(
                'mostVisitedCountriesIds',
                JSON.stringify(filteredMostVisitedCountriesIds)
              );
            } else {
              setCookie(
                'mostVisitedCountriesIds',
                JSON.stringify(filteredMostVisitedCountriesIds.slice(0, 3))
              );
            }
          }
        } else {
          setCookie(
            'mostVisitedCountriesIds',
            JSON.stringify([countryFoundId])
          );
        }
      }
      if (e?.keyCode === 38 && cursorIndex > 0) {
        setOptionForCountryCursor(cursorIndex - 1);
        setSuggestedCountry('');
        setSelectedOption(
          (countriesOptions as readonly any[])[cursorIndex - 1]
        );
        setSearchTermCountry(
          (countriesOptions as readonly any[])[cursorIndex - 1]?.label
        );
        dispatch(
          searchActions.setSearchedCountry({
            searchedCountry: {
              ...(countriesOptions as readonly any[])[cursorIndex - 1],
            },
          })
        );
        const countryFoundId = (countries || allCountries)?.find(
          // @ts-ignore
          (country, index) => index === cursorIndex - 1
        )?.ID;

        if (mostVisitedCountriesIds) {
          if (countryFoundId) {
            const newMostVisitedCountriesIds = [
              ...JSON.parse(mostVisitedCountriesIds as string),
            ];

            newMostVisitedCountriesIds.unshift(countryFoundId);

            const filteredMostVisitedCountriesIds = [
              ...new Set(newMostVisitedCountriesIds),
            ];

            if (newMostVisitedCountriesIds?.length < 4) {
              setCookie(
                'mostVisitedCountriesIds',
                JSON.stringify(filteredMostVisitedCountriesIds)
              );
            } else {
              setCookie(
                'mostVisitedCountriesIds',
                JSON.stringify(filteredMostVisitedCountriesIds.slice(0, 3))
              );
            }
          }
        } else {
          setCookie(
            'mostVisitedCountriesIds',
            JSON.stringify([countryFoundId])
          );
        }
      }
      if (e?.key === 'Enter' || e?.code === 'Enter' || e?.keyCode === 13) {
        setSelectedOption((countriesOptions as readonly any[])[cursorIndex]);
        setSearchTermCountry(
          (countriesOptions as readonly any[])[cursorIndex]?.label
        );
        dispatch(
          searchActions.setSearchedCountry({
            searchedCountry: {
              ...(countriesOptions as readonly any[])[cursorIndex],
            },
          })
        );
        setOptionForCountryCursor(cursorIndex);
        handleHideCountriesOptions();
        putCountryOutOfFocus();

        const countryFoundId = (countries || allCountries)?.find(
          // @ts-ignore
          (country, index) => index === cursorIndex
        )?.ID;

        if (mostVisitedCountriesIds) {
          if (countryFoundId) {
            const newMostVisitedCountriesIds = [
              ...JSON.parse(mostVisitedCountriesIds as string),
            ];

            newMostVisitedCountriesIds.unshift(countryFoundId);

            const filteredMostVisitedCountriesIds = [
              ...new Set(newMostVisitedCountriesIds),
            ];

            if (newMostVisitedCountriesIds?.length < 4) {
              setCookie(
                'mostVisitedCountriesIds',
                JSON.stringify(filteredMostVisitedCountriesIds)
              );
            } else {
              setCookie(
                'mostVisitedCountriesIds',
                JSON.stringify(filteredMostVisitedCountriesIds.slice(0, 3))
              );
            }
          }
        } else {
          setCookie(
            'mostVisitedCountriesIds',
            JSON.stringify([countryFoundId])
          );
        }
      }
      if (e?.keyCode === 37 || e?.keyCode === 39) {
        if (!showCountriesOptions) {
          setShowCountriesOptions(true);
        }
      }
      if (e?.keyCode === 9) {
        const countryObject = countriesOptions?.find(
          (country: { name: string }) =>
            country?.name?.toLowerCase() === suggestedCountry?.toLowerCase()
        ) as DataWorldChartTypes;
        const index = countriesOptions?.findIndex(
          (country: { name: string }) =>
            country?.name?.toLowerCase() === suggestedCountry?.toLowerCase()
        ) as number;

        if (countryObject && (index || index === 0)) {
          setSelectedOption(countryObject);
          setSearchTermCountry(countryObject?.name);
          dispatch(
            searchActions.setSearchedCountry({
              searchedCountry: {
                ...countryObject,
              },
            })
          );
          setOptionForCountryCursor(index);
        }
        handleHideCountriesOptions();
      }
    },
    [
      allCountries,
      countries,
      mostVisitedCountriesIds,
      optionForCountryCursor,
      countriesOptions,
      showCountriesOptions,
      suggestedCountry,
    ]
  );

  const CustomOption = ({ innerProps, isDisabled, data, options }: any) => {
    const lastIndex = options.length - 1;
    const visitedCountries = options?.filter(
      (option: any) => option.visited === true
    );
    const restOfTheCountries = options?.filter(
      (option: any) => !option.visited
    );

    const lastVisitedCountryIndexByOrder =
      visitedCountries[visitedCountries?.length - 1];

    const isFirstElement =
      restOfTheCountries[0]?.label === data.label && !data.visited;

    const isFirstElementByVisited =
      visitedCountries[0]?.label === data.label && data.visited;

    const isLastElement = data.label !== options[lastIndex].label;

    const isLastElementByVisited =
      lastVisitedCountryIndexByOrder?.label === data.label &&
      data.visited === true;

    return !isDisabled ? (
      <div
        {...innerProps}
        onClick={() => {
          selectCountryByIndex(data.index)();
          innerProps?.onClick();
        }}
        className={`flex flex-col ${
          isMobileLayout || isTabletLayout ? 'px-[16px]' : 'px-[26px]'
        } ${
          isFirstElement
            ? isTabletLayout || isMobileLayout
              ? 'mt-[9px]'
              : 'mt-[18px]'
            : ''
        } ${isLastElement ? 'mb-[17px]' : ''} ${
          isLastElementByVisited ? 'border-b pb-[18px]' : ''
        } ${isFirstElementByVisited ? 'mt-[16px]' : ''}`}
      >
        <div className="flex cursor-pointer items-center">
          <ReactCountryFlag
            className={`${
              isMobileLayout || isTabletLayout ? 'mr-[10px]' : 'mr-[25px]'
            }`}
            alt={`Flag of ${data?.label}`}
            countryCode={data.iso_2}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={data.name}
            style={{
              borderRadius: '9999px',
              width: isMobileLayout || isTabletLayout ? '23px' : '30px',
              height: isMobileLayout || isTabletLayout ? '23px' : '30px',
              border: '1px solid black',
            }}
          />
          <p
            className={`${
              isMobileLayout || isTabletLayout ? 'text-[12px]' : 'text-[20px]'
            }  ${
              optionForCountryCursor === data.index
                ? 'text-[#5A5A5A]'
                : 'text-[#b6b6b6]'
            } ${
              optionForCountryCursor === -1 && data.index === 0
                ? 'text-[#b6b6b6]'
                : ''
            }`}
          >
            {data.label}
          </p>
        </div>
      </div>
    ) : null;
  };

  /**
   * useEffect hook to handle route change events.
   * This hook listens for route change start events and dispatches an action
   * to set the navigation status in the Redux store.
   * @returns {void}
   */
  useEffect(() => {
    const handleRouteChangeStart = () => {
      dispatch(
        userActions.setIsNavigationFromTheApp({ isNavigationFromTheApp: true })
      );
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, []);

  const selectedValue = () => {
    if (searchedCountry?.label) {
      return searchedCountry;
    }
    // @ts-ignore
    if (selectedOption?.label) {
      // @ts-ignore
      return selectedOption;
    }
  };

  const [isClient, setIsClient] = useState(false);

  const [selectedInput, setSelectedInput] = useState<null | string>();

  const handleSelectedInput = (input: string) => {
    setSelectedInput(input);
  };

  const [firstNameInFocus, setFirstNameInFocus] = useState(false);
  const [lastNameInFocus, setLastNameInFocus] = useState(false);
  const [countryInFocus, setCountryNameInFocus] = useState(false);

  const putFirstNameInFocus = () => {
    setFirstNameInFocus(true);
    setLastNameInFocus(false);
    setCountryNameInFocus(false);
  };

  const putLastNameInFocus = () => {
    setFirstNameInFocus(false);
    setLastNameInFocus(true);
    setCountryNameInFocus(false);
  };

  const putCountryInputInFocus = () => {
    setFirstNameInFocus(false);
    setLastNameInFocus(false);
    setCountryNameInFocus(true);
    setShowCountriesOptions(true);
  };

  const putFirstNameOutOfFocus = () => {
    setFirstNameInFocus(false);
  };

  const putLastNameOutOfFocus = () => {
    setLastNameInFocus(false);
  };

  const putCountryOutOfFocus = () => {
    setCountryNameInFocus(false);
  };

  const [opacityForCountryInput, setOpacityForCountryInput] = useState(
    'opacity-[0] bg-[#F4F4F4]'
  );

  const [isSearchAnimation, setIsSearchAnimation] = useState(false);
  const [closeSearchBarAnimation, setCloseSearchBarAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsSearchAnimation(true);
    }, 3500);
  }, []);

  const firstNameRef = useRef<HTMLInputElement>(null);

  const routeToAccountSettings = useMemo(() => {
    if (isLoggedIn) {
      return '/account';
    }
    return '/sign-in';
  }, [isLoggedIn]);

  const handleOnClickFirstName = () => {
    openMegaMenu();
  };

  const handleOptionsSorting = (options: any) => {
    const mostVisitedCountriesIdsArray = mostVisitedCountriesIds
      ? [...JSON.parse(mostVisitedCountriesIds as string)]
      : [];

    if (userCountry) {
      const userCountryIndex = options.findIndex(
        (option: any) => option?.label === userCountry
      );
      if (userCountryIndex !== -1) {
        const userCountryOption = options[userCountryIndex];

        const newOptions = options.filter(
          (option: any) => option?.label !== userCountry
        );
        newOptions.unshift(userCountryOption);
        return newOptions as readonly any[];
      }
    }

    if (mostVisitedCountriesIdsArray?.length > 0 && !userCountry) {
      const country = options?.find((option: any) => {
        return option?.ID === mostVisitedCountriesIdsArray?.[0];
      });

      if (country) {
        const newOptions = options.filter(
          (option: any) => option?.ID !== country?.ID
        );
        newOptions.unshift(country);
        return newOptions as readonly any[];
      }
    }

    if (mostVisitedCountriesIdsArray?.length === 0 && !userCountry) {
      let userCity;
      let userCountryFromTimeZone: string | undefined;
      let userTimeZone;

      if (Intl) {
        userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const tzArr = userTimeZone?.split('/');
        userCity = tzArr?.[tzArr?.length - 1];
        userCountryFromTimeZone = timeZoneCityToCountry?.[userCity];
      }

      if (userCountryFromTimeZone) {
        const country = options?.find(
          (option: any) => option?.label === userCountryFromTimeZone
        );
        if (country) {
          const newOptions = options.filter(
            (option: any) => option?.label !== country?.label
          );
          newOptions.unshift(country);
          return newOptions as readonly any[];
        }
      }
    }

    const mostVisitedCountry = options?.reduce(
      (prev: any, current: any) =>
        prev?.record_count > current?.record_count ? prev : current,
      null
    );

    if (mostVisitedCountry) {
      const newOptions = options.filter(
        (option: any) => option?.label !== mostVisitedCountry?.label
      );
      newOptions.unshift(mostVisitedCountry);
      return newOptions as readonly any[];
    }

    return options?.sort((a: any, b: any) => {
      if (a?.visited === b?.visited) {
        return 1;
      }
      return -1;
    }) as readonly any[];
  };

  const onKeyDownRecentSearches = useCallback(
    (e: {
      key: string;
      code: string;
      keyCode: number;
      preventDefault: () => void;
    }) => {
      const cursorIndex = lastSearchFocusIndex;
      const popularCursorIndex = lastPopularSearchFocusIndex;

      if (e.key === 'ArrowDown' || e.code === 'ArrowDown' || e.keyCode === 40) {
        if (cursorIndex < lastSearches?.length - 1) {
          setLastSearchFocusIndex(cursorIndex + 1);
        }
        if (popularCursorIndex < (popularSearches as any[])?.length - 1) {
          setLastPopularSearchFocusIndex(popularCursorIndex + 1);
        }
        e?.preventDefault();
      }

      if (e.key === 'ArrowUp' || e.code === 'ArrowUp' || e.keyCode === 38) {
        if (cursorIndex > 0) {
          setLastSearchFocusIndex(cursorIndex - 1);
        }
        if (popularCursorIndex > 0) {
          setLastPopularSearchFocusIndex(popularCursorIndex - 1);
        }
        e?.preventDefault();
      }

      if (e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13) {
        if (showRecentSearchesList) {
          if (lastSearches?.[cursorIndex]) {
            const country = countriesOptions?.find(
              (option: any) =>
                option?.label === lastSearches?.[cursorIndex]?.country
            );

            router?.push({
              pathname: '/search',
              query: {
                firstName: lastSearches?.[cursorIndex]?.firstName,
                lastName: lastSearches?.[cursorIndex]?.lastName,
                country: country?.iso_2,
                page: '1',
                pageSize: defaultNrOfResults,
              },
            });

            setFirstName(lastSearches?.[cursorIndex]?.firstName);
            setLastName(lastSearches?.[cursorIndex]?.lastName);
            setSearchTermCountry(lastSearches?.[cursorIndex]?.country);

            setSelectedOption(country);
            dispatch(
              searchActions.setSearchedCountry({
                searchedCountry: {
                  ...country,
                },
              })
            );
            setFirstNameInFocus(false);
            setLastNameInFocus(false);
            setCountryNameInFocus(false);

            trackCustomEvent(
              'Search from search bar',
              `${lastSearches?.[cursorIndex]?.firstName} ${lastSearches?.[cursorIndex]?.lastName} - ${country?.name}`
            );

            closeMegaMenu();
          }
        } else if (showPopularSearchesList) {
          if (popularSearches?.[popularCursorIndex]) {
            const country = countriesOptions?.find(
              (option: any) =>
                option?.iso_3 === popularSearches?.[popularCursorIndex]?.country
            );

            setFirstName(popularSearches?.[popularCursorIndex]?.firstName);
            setLastName(popularSearches?.[popularCursorIndex]?.lastName);
            setSearchTermCountry(country?.label);
            setSelectedOption(country);
            dispatch(
              searchActions.setSearchedCountry({
                searchedCountry: {
                  ...country,
                },
              })
            );
            setFirstNameInFocus(false);
            setLastNameInFocus(false);
            setCountryNameInFocus(false);

            trackCustomEvent(
              'Search from search bar',
              `${popularSearches?.[popularCursorIndex]?.firstName} ${popularSearches?.[popularCursorIndex]?.lastName} - ${popularSearches?.[popularCursorIndex]?.country}`
            );

            closeMegaMenu();
          }
        }
      }
    },
    [
      countriesOptions,
      lastSearchFocusIndex,
      lastSearches,
      lastPopularSearchFocusIndex,
      popularSearches,
      showPopularSearchesList,
      showRecentSearchesList,
    ]
  );

  const onHandlePopularSearchesClick =
    (search: SearchesTypes, index: number) => () => {
      const country = countriesOptions?.find(
        (option: any) => option?.iso_3 === search?.country
      );

      setLastPopularSearchFocusIndex(index);

      const recentSearchesUnparsed = getCookie('recentSearches');

      if (recentSearchesUnparsed) {
        const recentSearchesFromCookies = JSON.parse(
          String(recentSearchesUnparsed)
        );

        recentSearchesFromCookies.splice(index, 1);
        recentSearchesFromCookies.unshift({
          firstName: search?.firstName,
          lastName: search?.lastName,
          country: country?.name || country?.label,
        });
        setCookie('recentSearches', JSON.stringify(recentSearchesFromCookies));
      } else {
        setCookie(
          'recentSearches',
          JSON.stringify([
            {
              firstName: search?.firstName,
              lastName: search?.lastName,
              country: country?.name || country?.label,
            },
          ])
        );
      }

      trackCustomEvent(
        'Search from search bar',
        `${search?.firstName} ${search?.lastName} - ${country?.name}`
      );

      setFirstName(search?.firstName);
      setLastName(search?.lastName);
      setSearchTermCountry(country?.label);
      setSelectedOption(country);
      dispatch(
        searchActions.setSearchedCountry({
          searchedCountry: {
            ...country,
          },
        })
      );
      setFirstNameInFocus(false);
      setLastNameInFocus(false);
      setCountryNameInFocus(false);
      closeMegaMenu();
    };

  const handleClearFirstName = () => {
    setFirstName('');
    setFirstNameInFocus(true);
    setLastNameInFocus(false);
    setCountryNameInFocus(false);
  };

  const handleClearLastName = () => {
    setLastName('');
    setLastNameInFocus(true);
    setFirstNameInFocus(false);
    setCountryNameInFocus(false);
  };

  const onHandleRecentSearchesClick =
    (search: SearchesTypes, index: number) => () => {
      const country = countriesOptions?.find(
        (option: any) => option?.label === search?.country
      );

      const recentSearchesFromCookies = JSON.parse(
        String(getCookie('recentSearches'))
      );

      if (recentSearchesFromCookies?.length > 1) {
        recentSearchesFromCookies.splice(index, 1);
        recentSearchesFromCookies.unshift(search);
        setCookie('recentSearches', JSON.stringify(recentSearchesFromCookies));
        setLastSearchFocusIndex(index);
      }

      router?.push({
        pathname: '/search',
        query: {
          firstName: search?.firstName,
          lastName: search?.lastName,
          country: country?.iso_2,
          page: '1',
          pageSize: defaultNrOfResults,
        },
      });

      trackCustomEvent(
        'Search from search bar',
        `${search?.firstName} ${search?.lastName} - ${country?.name}`
      );

      setFirstName(search?.firstName);
      setLastName(search?.lastName);
      setSearchTermCountry(search?.country);
      setSelectedOption(country);
      dispatch(
        searchActions.setSearchedCountry({
          searchedCountry: {
            ...country,
          },
        })
      );
      setFirstNameInFocus(false);
      setLastNameInFocus(false);
      setCountryNameInFocus(false);
      closeMegaMenu();
    };

  const onHandleClickOnContainerOfRecentSearches = () => {
    if (lastSearchFocusIndex === -1) {
      setLastSearchFocusIndex(0);
      setLastPopularSearchFocusIndex(0);
      recentSearchesRef?.current?.focus();
    }
  };

  const handleFocusFirstName = () => {
    handleSelectedInput('first-name');
    if (lastSearches?.length === 0 || selectedInput === 'last-name') {
      putFirstNameInFocus();
    }
  };

  const resetNavbarFiels = () => {
    setSuggestedCountry('');
    setSelectedOption(null);
    setOptionForCountryCursor(0);
    setFirstName('');
    setLastName('');
    // router.reload();
  };

  const handleTheStringInSearchBar = () => {
    const locationName =
      searchedCountry?.label ||
      selectedOption?.name ||
      countries?.find((country) => country.iso_3 === 'USA')?.name;

    return !isMegaMenuOpen &&
      firstName?.length > 0 &&
      lastName?.length > 0 &&
      !isMobileLayout && // @ts-ignore
      locationName
      ? firstName +
          ' ' +
          lastName +
          ' in ' +
          // @ts-ignore
          locationName
      : firstName || '';
  };

  const scrollToBottomOfTheCountriesDropdown = () => {
    const div = countriesContainerRef.current;

    if (div) {
      div.scrollTop = div.scrollHeight;
    }
  };

  const leftNav = () => {
    return (
      <>
        {!isFiltersHidden && (
          <style>
            {`
            html, body {
              height: 100% !important;
              overflow: hidden !important;
            }
          `}
          </style>
        )}
        {isMobileLayout ? (
          <Logo
            resetNavbarFiels={resetNavbarFiels}
            hasLink
            size={'small-mediu'}
            responsive
          />
        ) : (
          <Logo
            className="xl:mr-12"
            resetNavbarFiels={resetNavbarFiels}
            hasLink
            size={'medium'}
            responsive
          />
        )}
        <div className={`relative w-full`}>
          <OutsideClickHandler>
            {isMegaMenuOpen ? (
              <animated.p
                style={styleForHeaderText}
                className={`absolute -top-2 right-0 z-[-1] ${
                  isMobileLayout || isTabletLayout
                    ? 'left-[-140px]'
                    : 'left-[-100px]'
                } translate-x-1/2 font-serif text-3xl font-normal text-[#409F85]`}
              >
                Find a Human
              </animated.p>
            ) : null}

            <animated.div
              // @ts-ignore
              style={stylesForSearchInputs}
              className={cn(
                'hidden md:flex w-[460px] justify-center',
                isMegaMenuOpen && 'left-[50%] translate-x-[-50%]'
              )}
            >
              <animated.div
                id="reactour__search"
                data-tut="reactour__search"
                style={styleForContainerInput}
                className={`flex rounded-lg border border-[#808080] hover:transition`}
              >
                {isMegaMenuOpen && (
                  <div
                    className={`absolute top-[88px] flex w-full flex-col rounded-lg border-gray-200 bg-[#ffffff] shadow-md  ${
                      isTabletLayout
                        ? 'px-[23px] py-[16px]'
                        : 'px-[46px] py-[32px]'
                    }`}
                    onClick={onHandleClickOnContainerOfRecentSearches}
                  >
                    <div className="flex items-center justify-between">
                      <button
                        onClick={showRecentSearches}
                        className={`font-[700]  tracking-[0.54px] ${
                          !showRecentSearchesList
                            ? 'text-[#5A5A5A]'
                            : 'text-[#409F85]'
                        } all-small-caps leading-[12px] ${
                          isTabletLayout
                            ? 'mb-[12px] text-[14px]'
                            : 'mb-[24px] text-[18px]'
                        }`}
                      >
                        RECENT SEARCHES
                      </button>
                      <button
                        onClick={showPopularSearches}
                        className={`font-[700] tracking-[0.54px] ${
                          !showPopularSearchesList
                            ? 'text-[#5A5A5A]'
                            : 'text-[#409F85]'
                        } all-small-caps leading-[12px] text-[#409F85] disabled:text-[#5A5A5A] ${
                          isTabletLayout
                            ? 'mb-[12px] text-[14px]'
                            : 'mb-[24px] text-[18px]'
                        }`}
                      >
                        POPULAR SEARCHES
                      </button>
                      <button
                        onClick={clearRecentHistory}
                        className={`all-small-caps font-[700] leading-[12px] tracking-[0.54px] text-[#5A5A5A] opacity-60 hover:opacity-100 ${
                          isTabletLayout
                            ? 'mb-[12px] text-[14px]'
                            : 'mb-[24px] text-[18px]'
                        }`}
                        aria-label="Clear History"
                      >
                        CLEAR HISTORY
                      </button>
                    </div>
                    {showRecentSearchesList ? (
                      lastSearches?.length > 0 ? (
                        lastSearches?.map((search, index) => {
                          const id =
                            search.firstName + search.lastName + search.country;
                          return (
                            <Link
                              key={id}
                              href={{
                                pathname: '/search',
                                query: {
                                  firstName: search?.firstName,
                                  lastName: search?.lastName,
                                  country: countriesOptions?.find(
                                    (option: any) =>
                                      option?.label === search?.country
                                  )?.iso_2,
                                  page: '1',
                                  pageSize: defaultNrOfResults,
                                },
                              }}
                              onKeyDown={onKeyDownRecentSearches}
                              onClick={onHandleRecentSearchesClick(
                                search,
                                index
                              )}
                              className={`flex items-center opacity-60 transition hover:opacity-100 ${
                                lastSearchFocusIndex === index
                                  ? 'opacity-100'
                                  : ''
                              } ${index !== 0 ? 'mt-[14px]' : ''}`}
                              ref={index === 0 ? recentSearchesRef : null}
                            >
                              <SearchesIcon
                                className={`${
                                  isTabletLayout ? 'mr-[12.5px]' : 'mr-[25px]'
                                }`}
                              />
                              <p
                                className={`font-[400] leading-[12px] tracking-[0.4px] text-[#5A5A5A] ${
                                  isTabletLayout ? 'text-[16px]' : 'text-[20px]'
                                }`}
                              >
                                {search?.firstName} {search?.lastName} in{' '}
                                {search?.country}
                              </p>
                            </Link>
                          );
                        })
                      ) : (
                        <span>There are no recent searches...</span>
                      )
                    ) : showPopularSearchesList ? (
                      popularSearches?.map((search, index) => {
                        const queryObject: {
                          firstName?: string;
                          lastName?: string;
                          country?: string;
                          page?: string;
                          pageSize?: string;
                          birthDay?: string;
                          birthMonth?: string;
                          birthYear?: string;
                          id?: string;
                          alive?: string;
                        } = {
                          firstName: search?.firstName,
                          lastName: search?.lastName,
                          country: countriesOptions?.find(
                            (option: any) => option?.iso_3 === search?.country
                          )?.iso_2,
                          page: '1',
                          pageSize: defaultNrOfResults,
                          alive: 'both',
                        };

                        if (search?.birth_day) {
                          queryObject['birthDay'] =
                            search?.birth_day?.toString();
                        }

                        if (search?.birth_month) {
                          queryObject['birthMonth'] =
                            search?.birth_month?.toString();
                        }

                        if (search?.birth_year) {
                          queryObject['birthYear'] =
                            search?.birth_year?.toString();
                        }

                        if (search?.id) {
                          queryObject['id'] = search?.id?.toString();
                        }
                        return (
                          <Link
                            key={search.id}
                            href={{
                              pathname: '/search',
                              query: queryObject,
                            }}
                            onKeyDown={onKeyDownRecentSearches}
                            onClick={onHandlePopularSearchesClick(
                              search,
                              index
                            )}
                            className={`flex items-center opacity-60 transition hover:opacity-100 ${
                              lastPopularSearchFocusIndex === index
                                ? 'opacity-100'
                                : ''
                            } ${index !== 0 ? 'mt-[14px]' : ''}`}
                            ref={index === 0 ? popularSearchesRef : null}
                          >
                            <SearchesIcon
                              className={`${
                                isTabletLayout ? 'mr-[12.5px]' : 'mr-[25px]'
                              }`}
                            />
                            <p
                              className={`font-[400] leading-[12px] tracking-[0.4px] text-[#5A5A5A] ${
                                isTabletLayout ? 'text-[16px]' : 'text-[20px]'
                              }`}
                            >
                              {search?.firstName} {search?.lastName} in{' '}
                              {search?.country}
                            </p>
                          </Link>
                        );
                      })
                    ) : null}
                  </div>
                )}
                <button
                  onClick={handleOnClickFirstName}
                  onFocus={handleFocusFirstName}
                  className={`relative h-fit rounded-lg ${
                    (isMobileLayout && !isMegaMenuOpen) ||
                    (isTabletLayout && !isMegaMenuOpen)
                      ? 'w-full'
                      : ''
                  }`}
                  aria-label="First Name"
                >
                  {isMegaMenuOpen ? (
                    <p
                      onFocus={putFirstNameInFocus}
                      className={`absolute left-[21px] z-[13] pt-2 text-xs font-semibold ${
                        firstNameInFocus ? 'text-[#409F85]' : 'text-gray-500'
                      }`}
                    >
                      {t('first-name')}
                    </p>
                  ) : null}
                  <OutsideClickHandler
                    onOutsideClick={putFirstNameOutOfFocus}
                    styles={'rounded-lg'}
                  >
                    <div
                      className={`relative w-full rounded-lg ${
                        firstNameInFocus ? 'z-[12]' : 'z-[1]'
                      } `}
                    >
                      {ionutVariant && !closeSearchBarAnimation && (
                        <animated.div
                          style={searchArrowAnimation}
                          className="absolute left-[-25px] top-[12px]"
                        >
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            className="bi bi-chevron-double-right mb-2 mr-1 rotate-180 "
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M25.5 6L16.5 15L25.5 24"
                              className="stroke-[#409F85] transition"
                              strokeWidth="2"
                            ></path>
                          </svg>
                        </animated.div>
                      )}
                      <animated.input
                        style={stylesForFirstInput}
                        value={handleTheStringInSearchBar()}
                        onChange={handleChangeFirstName}
                        type={'text'}
                        className={
                          isMegaMenuOpen
                            ? ` h-[52px] w-full overflow-auto text-ellipsis whitespace-nowrap rounded-l-lg !border-r !border-gray-500 pl-[21px] pr-4 pt-7 focus:ring-0 ${
                                isTabletLayout ? 'text-[14px]' : ''
                              }`
                            : 'focus:ring-color-none focus:box-shadow-none h-[52px] w-full overflow-auto text-ellipsis whitespace-nowrap rounded-lg border-none text-sm outline-none transition focus:outline-none focus:ring-0'
                        }
                        placeholder={
                          isMegaMenuOpen || isMobileLayout
                            ? `Enter ${t('first-name')}`
                            : t('search-for-a-name', { ns: 'new-navbar' })
                        }
                        autoComplete="off"
                        ref={firstNameRef}
                      />
                      {isMegaMenuOpen && firstName && (
                        <button
                          tabIndex={-1}
                          className="absolute bottom-[30%] right-[10%]"
                          onClick={handleClearFirstName}
                        >
                          <XIcon />
                        </button>
                      )}
                    </div>{' '}
                  </OutsideClickHandler>
                </button>
                <div
                  onClick={() => handleSelectedInput('last-name')}
                  onFocus={() => handleSelectedInput('last-name')}
                  className="relative"
                >
                  {isMegaMenuOpen ? (
                    <p
                      onFocus={putLastNameInFocus}
                      className={`absolute left-[21px] z-[13] pt-2 text-xs ${
                        lastNameInFocus ? 'text-[#409F85]' : 'text-gray-500'
                      } font-semibold`}
                    >
                      {t('last-name')}
                    </p>
                  ) : null}{' '}
                  <div
                    onFocus={putLastNameInFocus}
                    className={`relative flex border-r border-gray-500 ${
                      lastNameInFocus ? 'z-[11]' : 'z-[1]'
                    } bg-[#f4f4f4]`}
                  >
                    <OutsideClickHandler onOutsideClick={putLastNameOutOfFocus}>
                      <animated.input
                        style={stylesForSecondInput}
                        value={lastName || ''}
                        onChange={handleChangeLastName}
                        onBlur={putLastNameOutOfFocus}
                        className={`z-10 border-none  pl-[21px] pr-4 pt-7 placeholder-shown:pl-[21px] focus:ring-0 ${
                          lastNameInFocus ? '' : ''
                        } ${isTabletLayout ? 'text-[14px]' : ''}`}
                        type={'text'}
                        placeholder={`Enter ${t('last-name')}`}
                        autoComplete="off"
                      />
                    </OutsideClickHandler>{' '}
                    {isMegaMenuOpen && lastName && (
                      <button
                        tabIndex={-1}
                        className="absolute bottom-[30%] right-[10%]"
                        onClick={handleClearLastName}
                      >
                        <XIcon />
                      </button>
                    )}
                  </div>
                </div>
                {isMegaMenuOpen ? (
                  <animated.div
                    className={`country-input-select focus:ring-color-none focus:box-shadow-none relative z-[9] flex h-[100] flex-col focus:ring-0 ${opacityForCountryInput}`}
                    style={stylesForInput}
                    onBlur={putCountryOutOfFocus}
                  >
                    <p
                      onFocus={putCountryInputInFocus}
                      className={`absolute left-[21px] z-[13] pt-2 text-xs font-semibold ${
                        countryInFocus ? 'text-[#409F85]' : 'text-gray-500'
                      }`}
                    >
                      {t('country')}
                    </p>
                    <OutsideClickHandler
                      onOutsideClick={putCountryOutOfFocus}
                      styles="relative z-[9]"
                    >
                      <div onFocus={putCountryInputInFocus}>
                        <div className={`relative ${countryInFocus ? '' : ''}`}>
                          <animated.input
                            style={stylesForCountryInput}
                            value={searchTermCountry}
                            onChange={handleChangeCountryInput}
                            onKeyDown={handleKeyDown}
                            onClick={handleShowCountriesOptions}
                            type={'text'}
                            className={`z-10 border-none pl-[21px] pt-7 focus:ring-0 ${
                              countryInFocus ? '' : ''
                            } ${
                              isTabletLayout
                                ? 'overflow-auto text-ellipsis whitespace-nowrap pr-[30px] text-[14px]'
                                : ''
                            }`}
                            placeholder={`Choose ${t('country')}`}
                            autoComplete="off"
                          />
                          <p className="absolute left-[21px] top-[35px] select-none opacity-40">
                            {suggestedCountry}
                          </p>
                          <button
                            onClick={putCountryInputInFocus}
                            className={`absolute ${
                              isTabletLayout ? 'top-[25px]' : 'top-[35px]'
                            } right-[15px]`}
                            tabIndex={-1}
                          >
                            <svg
                              width="23"
                              height="22"
                              viewBox="0 0 23 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.61709 8.51849C6.25911 8.1605 5.67871 8.1605 5.32073 8.51849C4.96275 8.87647 4.96275 9.45687 5.32073 9.81485L10.8207 15.3148C11.1787 15.6728 11.7591 15.6728 12.1171 15.3148L17.6171 9.81485C17.9751 9.45687 17.9751 8.87647 17.6171 8.51849C17.2591 8.1605 16.6787 8.1605 16.3207 8.51849L11.4689 13.3703L6.61709 8.51849Z"
                                fill="#636363"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      {showCountriesOptions && (
                        <OutsideClickHandler
                          onOutsideClick={handleHideCountriesOptions}
                        >
                          <div
                            className={`absolute ${
                              countriesOptions && countriesOptions?.length > 6
                                ? 'overflow-y-scroll'
                                : 'overflow-y-auto'
                            } top-[85px] shadow-[1px_3px_20px_-3px_rgba(0,0,0,0.6)] ${
                              !isMobileLayout && !isTabletLayout
                                ? 'h-[300px] w-[372px]'
                                : 'h-[225px] w-[177px]'
                            } scroll-smooth rounded-[10px] bg-white`}
                            ref={countriesContainerRef}
                            onScroll={checkScrollOfTheCountriesDropdown}
                          >
                            {countriesOptions?.map(
                              (country: DataWorldChartTypes, index) => {
                                return (
                                  <button
                                    onClick={selectCountry(country)}
                                    key={country.ID}
                                    className={`flex w-full flex-col ${
                                      isMobileLayout || isTabletLayout
                                        ? 'px-[16px]'
                                        : 'px-[26px]'
                                    } py-[10px] hover:bg-gray-200 hover:text-[#5A5A5A] ${
                                      index === 0 ? 'rounded-t-[10px]' : ''
                                    } ${
                                      index === countriesOptions?.length - 1
                                        ? 'rounded-b-[10px]'
                                        : ''
                                    }`}
                                  >
                                    <div className="flex cursor-pointer items-center">
                                      <ReactCountryFlag
                                        className={`${
                                          isMobileLayout || isTabletLayout
                                            ? 'mr-[10px]'
                                            : 'mr-[25px]'
                                        }`}
                                        countryCode={country?.iso_2}
                                        svg
                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                        cdnSuffix="svg"
                                        title={country?.name}
                                        style={{
                                          borderRadius: '9999px',
                                          width:
                                            isMobileLayout || isTabletLayout
                                              ? '23px'
                                              : '30px',
                                          height:
                                            isMobileLayout || isTabletLayout
                                              ? '23px'
                                              : '30px',
                                          border: '1px solid black',
                                        }}
                                      />
                                      <p
                                        className={`text-left ${
                                          isMobileLayout || isTabletLayout
                                            ? 'text-[12px]'
                                            : 'text-[20px]'
                                        }  ${
                                          optionForCountryCursor === index
                                            ? 'text-[#5A5A5A]'
                                            : 'text-[#b6b6b6]'
                                        } ${
                                          optionForCountryCursor === -1 &&
                                          index === 0
                                            ? 'text-[#b6b6b6]'
                                            : ''
                                        }`}
                                      >
                                        {country?.name}
                                      </p>
                                    </div>
                                  </button>
                                );
                              }
                            )}
                          </div>
                          {!isBottomOfTheCountriesDropdown &&
                            countriesOptions &&
                            countriesOptions?.length > 6 && (
                              <button
                                className="absolute right-[-20%] top-[354px] size-6 animate-bounce border-none bg-none text-gray-900"
                                onClick={scrollToBottomOfTheCountriesDropdown}
                              >
                                <svg
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  stroke="#409F85"
                                >
                                  <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                                </svg>
                              </button>
                            )}
                        </OutsideClickHandler>
                      )}
                    </OutsideClickHandler>
                  </animated.div>
                ) : null}
                {isMobileLayout ? (
                  <div className="flex justify-center">
                    <button
                      type="button"
                      onClick={handleSearch}
                      aria-label="Search"
                      id="search-button"
                    >
                      <SearchIcon />
                    </button>
                  </div>
                ) : (
                  <div className="flex">
                    {isLoadingSearchResults && router.pathname !== '/search' ? (
                      <animated.button
                        style={stylesForSearchButton}
                        type="button"
                        className="flex h-[52px] w-[72px] items-center justify-center rounded-r-[3px] "
                        onClick={handleSearch}
                        aria-label="Search"
                        id="search-button"
                      >
                        <Spinner size={'medium'} color="text-gray-800" />{' '}
                      </animated.button>
                    ) : (
                      <div
                        className={cn(
                          `flex justify-center rounded-r-lg rounded-bl-none ${
                            isMegaMenuOpen &&
                            'border border-[#409F85] bg-[#409F85]'
                          }`
                        )}
                      >
                        <button
                          type="button"
                          aria-label="Search"
                          id="search-button"
                          onClick={handleSearch}
                          className={`flex flex-row items-center justify-center rounded-r-lg px-[24px] py-[22px] text-[16px] font-semibold  ${
                            isMegaMenuOpen
                              ? 'text-white hover:bg-[#257C6B]'
                              : 'text-black'
                          }`}
                        >
                          {isMegaMenuOpen ? `Search ` : ''}
                          <SearchIcon
                            height={24}
                            width={24}
                            fill={`${isMegaMenuOpen ? 'white' : 'gray'}`}
                            className={`${isMegaMenuOpen ? `ml-2` : ''}`}
                          />
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </animated.div>
            </animated.div>
          </OutsideClickHandler>
        </div>
      </>
    );
  };

  const checkForMobileLayout: () => void = debounce(() => {
    if (window.innerWidth <= 768) {
      setIsMobileLayout(true);
    } else {
      setIsMobileLayout(false);
    }
    if (window.innerWidth <= 1080 && window.innerWidth > 768) {
      setIsTabletLayout(true);
    } else {
      setIsTabletLayout(false);
    }

    if (window.outerWidth <= 768) {
      setIsMobileLayout(true);
    } else {
      setIsMobileLayout(false);
    }
    if (window.outerWidth <= 1080 && window.outerWidth > 768) {
      setIsTabletLayout(true);
    } else {
      setIsTabletLayout(false);
    }
  }, 50);

  const styleForHeaderText = useSpring({
    top: isMegaMenuOpen ? -15 : -100,
    config: { tension: 600, friction: 50 },
  });

  const stylesForSearchInputs = useSpring({
    marginTop: isMegaMenuOpen && !isMobileLayout ? 40 : 0,
    width: isMegaMenuOpen && !isMobileLayout ? 'max-content' : '',
    justifyContent:
      isTabletLayout && !isMegaMenuOpen ? 'flex-start' : 'flex-start',
    marginLeft:
      isTabletLayout && !isMegaMenuOpen && !isMobileLayout
        ? 50
        : isMegaMenuOpen && isTabletLayout
        ? -10
        : isMobileLayout && !isMegaMenuOpen && isTabletLayout
        ? -122
        : 'auto',
    marginRight: 'auto',
    position: isMegaMenuOpen && !isMobileLayout ? 'absolute' : '',
    config: { tension: 600, friction: 50 },
  });

  const stylesForSearchIcon = useSpring({
    width: isMegaMenuOpen && !isMobileLayout && !isTabletLayout ? 36 : 28,
    height: isMegaMenuOpen && !isMobileLayout && !isTabletLayout ? 35 : 28,
    config: { tension: 600, friction: 50 },
  });

  const stylesForSearchButton = useSpring({
    height: isMegaMenuOpen && !isMobileLayout && !isTabletLayout ? 70 : 52,
    config: { tension: 600, friction: 50 },
  });

  const styleForContainerInput = useSpring({
    height: isMegaMenuOpen && !isMobileLayout && !isTabletLayout ? 72 : 54,
    width:
      isMegaMenuOpen && !isMobileLayout && !isTabletLayout
        ? 1012
        : isMegaMenuOpen && isTabletLayout
        ? 650
        : isTabletLayout
        ? 400
        : 480,
    config: {
      tension: !isMegaMenuOpen ? 2000 : 600,
      friction: 50,
      precision: !isMegaMenuOpen ? 0.01 : 1,
      mass: 1,
      bounce: 0,
    },
  });

  const stylesForInput = useSpring({
    width:
      isMegaMenuOpen && !isMobileLayout && !isTabletLayout
        ? 291
        : isMegaMenuOpen && isTabletLayout
        ? 175
        : !isMegaMenuOpen && isTabletLayout
        ? 240
        : 300,
    height: isMegaMenuOpen && !isMobileLayout && !isTabletLayout ? 70 : 52,
    config: { tension: 600, friction: 50 },
  });

  const stylesForFirstInput = useSpring({
    width:
      isMegaMenuOpen && !isMobileLayout && !isTabletLayout
        ? 291
        : isMegaMenuOpen && isTabletLayout
        ? 175
        : !isMegaMenuOpen && isTabletLayout
        ? 314
        : 380,
    height: isMegaMenuOpen && !isMobileLayout && !isTabletLayout ? 70 : 52,
    border:
      ionutVariant &&
      !closeSearchBarAnimation &&
      secondTour &&
      isSearchAnimation &&
      !isMegaMenuOpen
        ? '1px solid #409F85'
        : '0px solid #000000',

    config: {
      tension: !isMegaMenuOpen ? 2000 : 600,
      friction: 50,
      precision: !isMegaMenuOpen ? 0.01 : 1,
      mass: 1,
      bounce: 0,
    },
  });

  const searchArrowAnimation = useSpring({
    opacity: secondTour && isSearchAnimation ? 1 : 0,
    config: { tension: 600, friction: 50 },
  });

  const stylesForSecondInput = useSpring({
    width:
      isMegaMenuOpen && !isMobileLayout && !isTabletLayout
        ? 291
        : isMegaMenuOpen && isTabletLayout
        ? 160
        : !isMegaMenuOpen && isTabletLayout
        ? 240
        : 415,
    height: isMegaMenuOpen && !isMobileLayout && !isTabletLayout ? 70 : 52,
    display: isMegaMenuOpen && !isMobileLayout ? 'flex' : 'none',
    config: {
      tension: !isMegaMenuOpen ? 2000 : 600,
      friction: 50,
      precision: !isMegaMenuOpen ? 0.01 : 1,
      mass: 1,
      bounce: 0,
    },
  });

  const stylesForCountryInput = useSpring({
    width:
      isMegaMenuOpen && !isMobileLayout && !isTabletLayout
        ? 291
        : isMegaMenuOpen && isTabletLayout
        ? 170
        : !isMegaMenuOpen && isTabletLayout
        ? 240
        : 415,
    height: isMegaMenuOpen && !isMobileLayout && !isTabletLayout ? 70 : 52,
    display: isMegaMenuOpen && !isMobileLayout ? 'flex' : 'none',
    config: {
      tension: !isMegaMenuOpen ? 2000 : 600,
      friction: 50,
      precision: !isMegaMenuOpen ? 0.01 : 1,
      mass: 1,
      bounce: 0,
    },
  });

  useEffect(() => {
    if (isMobileLayout || isTabletLayout) {
      if (i18n.language === 'fr') {
        setSelectedLanguageOption('FR');
      }
      if (i18n.language === 'en') {
        setSelectedLanguageOption('EN');
      }
    } else {
      if (i18n.language === 'fr') {
        setSelectedLanguageOption(t('french'));
      }
      if (i18n.language === 'en') {
        setSelectedLanguageOption(t('english'));
      }
    }
  }, [isMobileLayout, isTabletLayout]);

  useEffect(() => {
    const index = countriesOptions?.findIndex(
      (option) => option.iso_2 === searchedCountry?.value
    );
    if (index !== -1 && index !== optionForCountryCursor) {
      setOptionForCountryCursor(index as SetStateAction<number>);
    }
  }, [searchedCountry]);

  const randomizedFiveResults = (searches: any[]) => {
    const randomized = searches.sort(() => Math.random() - 0.5);
    return randomized.slice(0, 5);
  };

  useEffect(() => {
    // Searched country iso_3
    const searchedCountryIso3 = searchedCountry?.iso_3;

    const searches = listOfPopularSearches?.find(
      (search) => search?.country === searchedCountryIso3
    )?.searches as any[];

    // If firstName and lastName exists in listOfPopularSearches, remove that record and keep the rest
    const searchesToSearchFrom = searches?.filter((search) => {
      return search?.firstName !== firstName || search?.lastName !== lastName;
    });

    let results = [];

    if (searchesToSearchFrom) {
      results = randomizedFiveResults(searchesToSearchFrom);
    } else {
      results = randomizedFiveResults(
        (listOfPopularSearches?.[0]?.searches as [])?.filter(
          (search: { firstName: string; lastName: string }) => {
            return (
              search?.firstName !== firstName || search?.lastName !== lastName
            );
          }
        )
      );
    }

    dispatch(
      searchActions.setPopularSearches({
        popularSearches: results,
      })
    );

    setPopularSearches(results);
  }, [searchedCountry]);

  useEffect(() => {
    const optionsFromPropsOrRedux = (
      (countries as DataWorldChartTypes[]) || allCountries
    )?.map((country: DataWorldChartTypes) => ({
      value: country.iso_2,
      label: country.name,
      ...country,
    }));

    if (countryTerm?.length > 0) {
      const countriesSorted = handleOptionsSorting(optionsFromPropsOrRedux);

      const newCountriesOptions = countriesSorted?.filter(
        (option) =>
          option.label.toLowerCase().includes(countryTerm.toLowerCase()) &&
          option?.label?.charAt(0) === countryTerm?.charAt(0)
      );

      setCountriesOptions(newCountriesOptions);
    } else {
      setCountriesOptions(optionsFromPropsOrRedux);
    }
  }, [allCountries, countries, countryTerm]);

  useEffect(() => {
    if (
      (router.query?.firstName as string)?.length > 0 &&
      (router.query?.lastName as string)?.length > 0
    ) {
      setFirstName(router.query?.firstName as string);
      setLastName(router.query?.lastName as string);
    }
    if (router.locale) {
      const detectedLanguage = languageOptions?.find(
        (languageOption) => languageOption?.value === (router.locale as string)
      )?.label;
      // @ts-ignore
      setSelectedLanguageOption(detectedLanguage);
    }
  }, []);

  useEffect(() => {
    if (isMegaMenuOpen) {
      if (firstNameRef.current) {
        handleSelectedInput('first-name');
        putFirstNameInFocus();
        setTimeout(() => {
          firstNameRef.current?.focus();
        }, 200);
      }
      setFirstName(router.query?.firstName as string);
      setLastName(router.query?.lastName as string);
      if (recentSearchesRef?.current) {
        recentSearchesRef?.current?.focus();
      }
      setTimeout(() => {
        setOpacityForCountryInput('opacity-[1]');
      }, 50);
    } else {
      dispatch(
        searchActions.setSearchInAnotherCountry({
          searchInAnotherCountry: false,
        })
      );

      setOpacityForCountryInput('opacity-[0] bg-[#F4F4F4]');
    }
  }, [isMegaMenuOpen]);

  useEffect(() => {
    const optionsFromPropsOrRedux = (
      (countries as DataWorldChartTypes[]) || allCountries
    )?.map((country: DataWorldChartTypes) => ({
      value: country.iso_2,
      label: country.name,
      ...country,
    }));
    const newOptions = handleOptionsSorting(optionsFromPropsOrRedux);
    if (optionsFromPropsOrRedux?.length > 0 && newOptions?.length > 0) {
      const newOptionsWithCorrectIndexes = newOptions.map(
        (option: any, index: number) => ({
          ...option,
          index: index,
        })
      );
      setCountriesOptions(newOptionsWithCorrectIndexes);
      setOptionForCountryCursor(0);
    }
  }, [allCountries, countries, router?.pathname]);

  useEffect(() => {
    if (recentSearches) {
      const newRecentSearches = [...JSON.parse(recentSearches as string)];
      setLastSearches(newRecentSearches);
    }
  }, [recentSearches]);

  useEffect(() => {
    if (
      isMegaMenuOpen &&
      recentSearches &&
      [...JSON.parse(recentSearches as string)]?.length > 0 &&
      !router?.query?.country &&
      firstName?.length === 0 &&
      lastName?.length === 0 &&
      searchTermCountry?.length === 0
    ) {
      const newRecentSearches = [...JSON.parse(recentSearches as string)];

      setSearchTermCountry(newRecentSearches?.[0]?.country);

      const countryObject = countriesOptions?.find(
        (countryOption) =>
          countryOption?.label?.toLowerCase() ===
          newRecentSearches?.[0]?.country?.toLowerCase()
      );
      setSelectedOption(countryObject);
      dispatch(
        searchActions.setSearchedCountry({
          searchedCountry: {
            ...countryObject,
          },
        })
      );
    }
    if (
      firstName?.length > 0 &&
      lastName?.length > 0 &&
      searchTermCountry?.length === 0 &&
      !selectedOption?.name &&
      searchedCountry?.name
    ) {
      setSearchTermCountry(searchedCountry?.name);
      setSelectedOption(searchedCountry);
    }
  }, [recentSearches, isMegaMenuOpen, router]);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (firstNameInFocus || lastNameInFocus || countryInFocus) {
      setLastSearchFocusIndex(-1);
      setLastPopularSearchFocusIndex(-1);
    }

    if (
      !isMegaMenuOpen &&
      !firstNameInFocus &&
      !lastNameInFocus &&
      !countryInFocus
    ) {
      setLastSearchFocusIndex(0);
      setLastPopularSearchFocusIndex(0);
    }
  }, [firstNameInFocus, lastNameInFocus, countryInFocus, isMegaMenuOpen]);

  useEffect(() => {
    if (countries) {
      dispatch(searchActions.setAllCountries({ allCountries: countries }));
    }
    if (router.pathname !== 'first-name' && router.pathname !== 'last-name') {
      if ((router.query.country as string)?.length > 0) {
        const foundCountry = (countries as DataWorldChartTypes[]).find(
          (country: any) => {
            return (
              country.iso_2.toLowerCase() ===
              router.query.country?.toString().toLowerCase()
            );
          }
        );
        if (foundCountry) {
          dispatch(
            searchActions.setSearchedCountry({
              searchedCountry: {
                ...foundCountry,
                label: foundCountry?.name,
                value: foundCountry?.iso_2,
              },
            })
          );
        }
      }
    }
  }, [countries, router]);

  useEffect(() => {
    checkForMobileLayout();
    window.addEventListener('resize', checkForMobileLayout);

    return () => {
      window.removeEventListener('resize', checkForMobileLayout);
    };
  }, []);

  return (
    <OutsideClickHandler onOutsideClick={closeMegaMenu}>
      <div
        className={`${
          isMobileLayout
            ? 'h-[65px]'
            : router?.pathname === '/first-name/[firstname_slug]' ||
              router?.pathname === '/last-name/[lastname_slug]'
            ? 'h-0'
            : 'h-[100px]'
        }`}
      />
      {!isFiltersHidden && (
        <style>
          {`
                  body {
                    height: 100% !important;
                    overflow: hidden !important;
                    touch-action: none;
                  }
                `}
        </style>
      )}
      {!isFiltersHidden ? (
        <div className="absolute left-0 top-0 z-[99] h-screen w-full pt-[5px]">
          <div className="flex size-full flex-col justify-between bg-white pb-[50px]">
            <div className="flex">
              <button
                onClick={handleOpenSearchModal}
                className="ml-auto h-[31px]"
                title="Close Search Country Modal"
              >
                <CloseIcon />
              </button>
            </div>
            <div className="mt-[-100px] flex flex-col">
              <div className="h-[60px]" />
              <div className="px-[10px]">
                <input
                  style={{
                    outline: 'none',
                    WebkitAppearance: 'none',
                    boxShadow: 'none',
                  }}
                  onFocus={putFirstNameInFocus}
                  value={
                    !isMegaMenuOpen &&
                    firstName.length > 0 &&
                    lastName.length > 0 &&
                    !isMobileLayout &&
                    searchedCountry
                      ? firstName +
                          ' ' +
                          lastName +
                          ' in ' +
                          // @ts-ignore
                          searchedCountry.label || selectedOption.label
                      : firstName || ''
                  }
                  onChange={handleChangeFirstName}
                  className="input w-full rounded-[5px] border-[#808080]"
                  placeholder="First name"
                  type="text"
                  alt="First name"
                />
              </div>
              <div className="my-[20px] px-[10px]">
                <input
                  style={{
                    outline: 'none',
                    WebkitAppearance: 'none',
                    boxShadow: 'none',
                  }}
                  value={lastName}
                  onChange={handleChangeLastName}
                  className={`input w-full rounded-[5px] border-[#808080]`}
                  placeholder="Last name"
                  type="text"
                  alt="Last name"
                />
              </div>
              <div className="px-[10px]">
                <Select
                  instanceId={'country'}
                  aria-labelledby="country"
                  placeholder={`Choose ${t('country')}`}
                  isSearchable={false}
                  defaultValue={selectedValue()}
                  tabSelectsValue={false}
                  options={countriesOptions}
                  onMenuOpen={openMegaMenu}
                  components={{ Option: CustomOption }}
                  onKeyDown={handleKeyDown}
                  blurInputOnSelect={true}
                  styles={{
                    control: () => ({
                      position: 'relative',
                      width: '100%',
                      height: '42px',
                      display: 'flex',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: '#000000',
                      borderRadius: '10px',
                      transition: '300ms',
                      ':focus-within': {
                        borderWidth: '1px',
                        borderColor: 'black',
                      },
                    }),
                    placeholder: () => ({
                      marginTop: '-30px',
                      color: 'grey',
                      padding: '0',
                    }),
                    dropdownIndicator: () => ({
                      marginTop: '0px',
                      paddingRight: '10px',
                    }),
                    indicatorSeparator: () => ({
                      display: 'none',
                    }),
                    menu: () => ({
                      position: 'absolute',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      marginTop: 10,
                      width: '100%',
                      height: '200px',
                      overflow: 'scroll',
                    }),
                    menuList: (base) => ({
                      ...base,
                      // border: '1px solid black',
                      boxShadow: '0px 0px 15px -4px #000000',
                      borderRadius: '10px',
                      width: '100%',
                      height: '200px',
                      transition: '300ms',
                      overflow: 'scroll',
                    }),
                  }}
                />
              </div>
            </div>
            <button
              onClick={handleOpenSearchModal}
              className="ml-auto h-[31px]"
              title="Close Search Country Modal"
            >
              <CloseIcon />
            </button>
          </div>
          <div className="mt-[-100px] flex flex-col">
            <div className="h-[60px]" />
            <div className="px-[10px]">
              <input
                style={{
                  outline: 'none',
                  WebkitAppearance: 'none',
                  boxShadow: 'none',
                }}
                onFocus={putFirstNameInFocus}
                value={
                  !isMegaMenuOpen &&
                  firstName.length > 0 &&
                  lastName.length > 0 &&
                  !isMobileLayout &&
                  searchedCountry
                    ? firstName +
                        ' ' +
                        lastName +
                        ' in ' +
                        // @ts-ignore
                        searchedCountry.label || selectedOption.label
                    : firstName
                }
                onChange={handleChangeFirstName}
                className="input w-full rounded-[5px] border-[#808080]"
                placeholder="First name"
                type="text"
                alt="First name"
              />
            </div>
            <div className="my-[20px] px-[10px]">
              <input
                style={{
                  outline: 'none',
                  WebkitAppearance: 'none',
                  boxShadow: 'none',
                }}
                value={lastName}
                onChange={handleChangeLastName}
                className="input w-full rounded-[5px] border-[#808080]"
                placeholder="Last name"
                type="text"
                alt="Last name"
              />
            </div>
            <div className="px-[10px]">
              <Select
                instanceId={'country'}
                aria-labelledby="country"
                placeholder={`Choose ${t('country')}`}
                isSearchable={false}
                defaultValue={selectedValue()}
                tabSelectsValue={false}
                options={countriesOptions}
                onMenuOpen={openMegaMenu}
                components={{ Option: CustomOption }}
                onKeyDown={handleKeyDown}
                blurInputOnSelect={true}
                styles={{
                  control: () => ({
                    position: 'relative',
                    width: '100%',
                    height: '42px',
                    display: 'flex',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: '#000000',
                    borderRadius: '10px',
                    transition: '300ms',
                    ':focus-within': {
                      borderWidth: '1px',
                      borderColor: 'black',
                    },
                  }),
                  placeholder: () => ({
                    marginTop: '-30px',
                    color: 'grey',
                    padding: '0',
                  }),
                  dropdownIndicator: () => ({
                    marginTop: '0px',
                    paddingRight: '10px',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  menu: () => ({
                    position: 'absolute',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    marginTop: 10,
                    width: '100%',
                    height: '200px',
                    overflow: 'scroll',
                  }),
                  menuList: (base) => ({
                    ...base,
                    // border: '1px solid black',
                    boxShadow: '0px 0px 15px -4px #000000',
                    borderRadius: '10px',
                    width: '100%',
                    height: '200px',
                    transition: '300ms',
                    overflow: 'scroll',
                  }),
                }}
              />
            </div>
          </div>
          <button
            onClick={handleSearch}
            className="mx-[20px] mb-[5px] rounded-[5px] bg-[#409F85] py-[10px] "
          >
            <p className="text-white">Search</p>
          </button>
        </div>
      ) : null}
      <PrimaryNavbar
        className="max-md:bg-[#F4F4F4]"
        leftNav={leftNav()}
        {...navbarProps}
      >
        <div
          className={`flex w-full items-center font-medium max-md:bg-[#F4F4F4]`}
        >
          <NavDropdown />
          <Link
            href={'/how-you-can-help'}
            passHref
            className="desktop:block mx-4 hidden min-w-[54px] items-center justify-center text-nowrap text-sm hover:text-[#257C6B]"
            aria-label="Help us"
          >
            Help us
          </Link>
          {isClient && !isLoggedIn ? (
            <Link
              href={routeToAccountSettings}
              passHref
              className="tablet:block mx-4 hidden min-w-[54px] items-center justify-center text-nowrap text-sm hover:text-[#257C6B]"
              id="logged-user"
              data-for="logged-user-icon"
              data-tip={userName}
              aria-label="Account Settings"
            >
              Sign in
            </Link>
          ) : (
            <AccountDropdown />
          )}
          {isClient && isLoggedIn ? (
            // @ts-ignore
            <ReactTooltip
              className="tooltip-opacity"
              id="logged-user-icon"
              type={'info'}
              effect={'solid'}
              place="bottom"
              backgroundColor={'#ECECEC'}
              textColor={'#454545'}
            />
          ) : null}
          {isClient && !isLoggedIn && (
            <>
              <span className="tablet:block mx-1 hidden text-gray-400">|</span>
              <Link
                href={'/sign-up'}
                passHref
                className="tablet:block mx-4 hidden min-w-[84px] items-center justify-center text-nowrap text-sm text-[#257C6B] hover:text-[#409F85]"
                id="logged-user"
                data-for="logged-user-icon"
                data-tip={userName}
                aria-label="Account Settings"
              >
                Get Started
              </Link>{' '}
            </>
          )}
          <Menu
            isOpen={isOpen}
            handleStateChange={handleStateChange}
            isMobileLayout={isMobileLayout}
            isTabletLayout={isTabletLayout}
          />
          <button
            type="button"
            className="duration-50 tablet:hidden flex items-center border-0 transition-opacity hover:opacity-50 focus:outline-0"
            onClick={onOpenMenu}
            title="Open Menu"
            id="open-menu"
          >
            <MenuIcon
              width={isMobileLayout ? 25 : 35}
              height={isMobileLayout ? 25 : 35}
            />
          </button>{' '}
        </div>
      </PrimaryNavbar>
    </OutsideClickHandler>
  );
}
