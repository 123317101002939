import * as React from 'react';

interface IconProperties {
  className?: string;
  width?: number;
  height?: number;
  viewBox?: string;
  title?: string;
  onClick?: () => void;
  opacity1?: number;
  opacity2?: number;
  fill?: string;
}

function LogOutIcon({
  opacity1,
  opacity2,
  className,
  fill = '#409F85',
  ...props
}: IconProperties) {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.83331 3.83333L6.00831 5.00833L3.85831 7.16667H12.3333V8.83333H3.85831L6.00831 10.9833L4.83331 12.1667L0.666645 8M15.6666 2.16667H8.99998V0.5H15.6666C16.5833 0.5 17.3333 1.25 17.3333 2.16667V13.8333C17.3333 14.75 16.5833 15.5 15.6666 15.5H8.99998V13.8333H15.6666V2.16667Z"
        fill="#6F757E"
        className={className}
      />
    </svg>
  );
}

export default LogOutIcon;
