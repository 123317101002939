/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';

export interface FirstNameDataObject {
  count: number;
  iso_2: string;
  iso_3: string;
}

export interface FirstNameReducerState {
  firstNameData: FirstNameDataObject[];
  firstNameAges: { [key: string]: number };
  firstNamesSoundingLike: any;
}

const initialState: FirstNameReducerState = {
  firstNameData: [],
  firstNameAges: {},
  firstNamesSoundingLike: [],
};

const { actions, reducer: firstNameReducer } = createSlice({
  name: 'firstName',
  initialState,
  reducers: {
    setFirstNameData: (state, action) => {
      state.firstNameData = action.payload.firstNameData;
    },
    setFirstNameAges: (state, action) => {
      state.firstNameAges = action.payload.firstNameAges;
    },
    setFirstNamesSoundingLike: (state, action) => {
      state.firstNamesSoundingLike = action.payload.firstNamesSoundingLike;
    },
  },
});

export { actions as firstNameActions, firstNameReducer };
