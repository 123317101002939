export const listOfPopularSearches = [
  {
    country: 'WOR',
    searches: [
      {
        country: 'USA',
        firstName: 'Michael',
        lastName: 'Jackson',
        sex: 'M',
        birth_day: 29,
        birth_month: 8,
        birth_year: 1958,
        birth_city: 'Gary',
        birth_state: 'Indiana',
        birth_country: 'USA',
        id: 'MICHAEL_JACKSON_8254170',
      },
      {
        country: 'GBR',
        firstName: 'Diana',
        lastName: 'Spencer',
        sex: 'F',
        birth_day: 1,
        birth_month: 7,
        birth_year: 1961,
        birth_city: 'Sandringham',
        birth_state: 'England',
        birth_country: 'GBR',
        id: 'DIANA_SPENCER_2264887',
      },
      {
        country: 'FRA',
        firstName: 'Kylian',
        lastName: 'Mbappé',
        sex: 'M',
        birth_day: 20,
        birth_month: 12,
        birth_year: 1998,
        birth_city: 'Paris',
        birth_state: 'Ile-de-France',
        birth_country: 'FRA',
        id: 'KYLIAN_MBAPPÉ_7737266',
      },
      {
        country: 'USA',
        firstName: 'Steven',
        lastName: 'Spielberg',
        sex: 'M',
        birth_month: 12,
        birth_year: 1946,
        birth_city: 'Cincinnati',
        birth_state: 'Ohio',
        birth_country: 'USA',
        id: 'STEVEN_SPIELBERG_5120078',
      },
      {
        country: 'USA',
        firstName: 'Mark',
        lastName: 'Zuckerberg',
        sex: 'M',
        birth_day: 14,
        birth_month: 5,
        birth_year: 1984,
        birth_city: 'White Plains',
        birth_state: 'New York',
        birth_country: 'USA',
        id: 'MARK_ZUCKERBERG_4443699',
      },
    ],
  },
  {
    country: 'USA',
    searches: [
      {
        country: 'USA',
        firstName: 'Michael',
        lastName: 'Jackson',
        sex: 'M',
        birth_day: 29,
        birth_month: 8,
        birth_year: 1958,
        birth_city: 'Gary',
        birth_state: 'Indiana',
        birth_country: 'USA',
        id: 'MICHAEL_JACKSON_8254170',
      },
      {
        country: 'USA',
        firstName: 'Joseph',
        lastName: 'Biden',
        sex: 'M',
        birth_day: 20,
        birth_month: 11,
        birth_year: 1942,
        birth_city: 'Scranton',
        birth_state: 'Pennsylvania',
        birth_country: 'USA',
        id: 'JOSEPH_BIDEN_2164721',
      },
      {
        country: 'USA',
        firstName: 'Donald',
        lastName: 'Trump',
        sex: 'M',
        birth_day: 14,
        birth_month: 6,
        birth_year: 1946,
        birth_city: 'Queens',
        birth_state: 'New York',
        birth_country: 'USA',
        id: 'DONALD_TRUMP_1831956',
      },
      {
        country: 'USA',
        firstName: 'Taylor',
        lastName: 'Swift',
        sex: 'F',
        birth_day: 13,
        birth_month: 12,
        birth_year: 1989,
        birth_city: 'West Reading',
        birth_state: 'Pennsylvania',
        birth_country: 'USA',
        id: 'TAYLOR_SWIFT_10277168',
      },
      {
        country: 'USA',
        firstName: 'Oprah',
        lastName: 'Winfrey',
        sex: 'F',
        birth_day: 29,
        birth_month: 1,
        birth_year: 1954,
        birth_city: 'Kosciusko',
        birth_state: 'Mississippi',
        birth_country: 'USA',
        id: 'OPRAH_WINFREY_2944524',
      },
      {
        country: 'USA',
        firstName: 'Michael',
        lastName: 'Jordan',
        sex: 'M',
        birth_day: 17,
        birth_month: 2,
        birth_year: 1963,
        birth_city: 'New York City',
        birth_state: 'New York',
        birth_country: 'USA',
        id: 'MICHAEL_JORDAN_2403334',
      },
      {
        country: 'USA',
        firstName: 'Leonardo',
        lastName: 'DiCaprio',
        sex: 'M',
        birth_day: 11,
        birth_month: 11,
        birth_year: 1974,
        birth_city: 'Los Angeles',
        birth_state: 'California',
        birth_country: 'USA',
        id: 'LEONARDO_DICAPRIO_5441256',
      },
      {
        country: 'USA',
        firstName: 'Steven',
        lastName: 'Spielberg',
        sex: 'M',
        birth_month: 12,
        birth_year: 1946,
        birth_city: 'Cincinnati',
        birth_state: 'Ohio',
        birth_country: 'USA',
        id: 'STEVEN_SPIELBERG_5120078',
      },
      {
        country: 'USA',
        firstName: 'Steven',
        lastName: 'Jobs',
        sex: 'M',
        birth_day: 24,
        birth_month: 2,
        birth_year: 1955,
        birth_city: 'San Francisco',
        birth_state: 'California',
        birth_country: 'USA',
        id: 'STEVEN_JOBS_6785092',
      },
      {
        country: 'USA',
        firstName: 'Thomas',
        lastName: 'Brady',
        sex: 'M',
        birth_day: 3,
        birth_month: 8,
        birth_year: 1977,
        birth_city: 'San Mateo',
        birth_state: 'California',
        birth_country: 'USA',
        id: 'THOMAS_BRADY_5016854',
      },
      {
        country: 'USA',
        firstName: 'Kamala',
        lastName: 'Harris',
        sex: 'F',
        birth_day: 20,
        birth_month: 10,
        birth_year: 1964,
        birth_city: 'Oakland',
        birth_state: 'California',
        birth_country: 'USA',
        id: 'KAMALA_HARRIS_6498323',
      },
      {
        country: 'USA',
        firstName: 'Elon',
        lastName: 'Musk',
        sex: 'M',
        birth_day: 28,
        birth_month: 6,
        birth_year: 1971,
        birth_city: 'Pretoria',
        birth_country: 'ZAF',
        id: 'ELON_MUSK_384979',
      },
      {
        country: 'USA',
        firstName: 'Mark',
        lastName: 'Zuckerberg',
        sex: 'M',
        birth_day: 14,
        birth_month: 5,
        birth_year: 1984,
        birth_city: 'White Plains',
        birth_state: 'New York',
        birth_country: 'USA',
        id: 'MARK_ZUCKERBERG_4443699',
      },
    ],
  },
  {
    country: 'GBR',
    searches: [
      {
        country: 'GBR',
        firstName: 'Harry',
        lastName: 'Kane',
        sex: 'M',
        birth_day: 28,
        birth_month: 7,
        birth_year: 1993,
        birth_city: 'Walthamstow',
        birth_state: 'England',
        birth_country: 'GBR',
        id: 'HARRY_KANE_8779237',
      },
      {
        country: 'GBR',
        firstName: 'Diana',
        lastName: 'Spencer',
        sex: 'F',
        birth_day: 1,
        birth_month: 7,
        birth_year: 1961,
        birth_city: 'Sandringham',
        birth_state: 'England',
        birth_country: 'GBR',
        id: 'DIANA_SPENCER_2264887',
      },
      {
        country: 'GBR',
        firstName: 'Daniel',
        lastName: 'Craig',
        sex: 'M',
        birth_day: 2,
        birth_month: 3,
        birth_year: 1968,
        birth_city: 'Chester',
        birth_state: 'England',
        birth_country: 'GBR',
        id: 'DANIEL_CRAIG_9775966',
      },
      {
        country: 'GBR',
        firstName: 'Harry',
        lastName: 'Styles',
        sex: 'M',
        birth_day: 1,
        birth_month: 2,
        birth_year: 1994,
        birth_city: 'Redditch',
        birth_state: 'England',
        birth_country: 'GBR',
        id: 'HARRY_STYLES_7980509',
      },
      {
        country: 'GBR',
        firstName: 'Rishi',
        lastName: 'Sunak',
        sex: 'M',
        birth_day: 12,
        birth_month: 5,
        birth_year: 1980,
        birth_city: 'Southampton',
        birth_state: 'England',
        birth_country: 'GBR',
        id: 'RISHI_SUNAK_7452993',
      },
      {
        country: 'GBR',
        firstName: 'Richard',
        lastName: 'Branson',
        sex: 'M',
        birth_day: 18,
        birth_month: 7,
        birth_year: 1950,
        birth_city: 'London',
        birth_state: 'England',
        birth_country: 'GBR',
        id: 'RICHARD_BRANSON_3430366',
      },
    ],
  },
  {
    country: 'FRA',
    searches: [
      {
        country: 'FRA',
        firstName: 'Kylian',
        lastName: 'Mbappé',
        sex: 'M',
        birth_day: 20,
        birth_month: 12,
        birth_year: 1998,
        birth_city: 'Paris',
        birth_state: 'Ile-de-France',
        birth_country: 'FRA',
        id: 'KYLIAN_MBAPPÉ_7737266',
      },
      {
        country: 'FRA',
        firstName: 'Emmanuel',
        lastName: 'Macron',
        sex: 'M',
        birth_day: 21,
        birth_month: 12,
        birth_year: 1977,
        birth_city: 'Amiens',
        birth_state: 'Hauts-de-France',
        birth_country: 'FRA',
        id: 'EMMANUEL_MACRON_1060407',
      },
      {
        country: 'FRA',
        firstName: 'Jean',
        lastName: 'Dujardin',
        sex: 'M',
        birth_day: 19,
        birth_month: 6,
        birth_year: 1972,
        birth_city: 'Rueil-Malmaison',
        birth_state: 'Ile-de-France',
        birth_country: 'FRA',
        id: 'JEAN_DUJARDIN_5122372',
      },
      {
        country: 'FRA',
        firstName: 'Marion',
        lastName: 'Cotillard',
        sex: 'F',
        birth_day: 30,
        birth_month: 9,
        birth_year: 1975,
        birth_city: 'Paris',
        birth_state: 'Ile-de-France',
        birth_country: 'FRA',
        id: 'MARION_COTILLARD_1457670',
      },
      {
        country: 'FRA',
        firstName: 'Annie',
        lastName: 'Ernaux',
        sex: 'F',
        birth_day: 1,
        birth_month: 9,
        birth_year: 1940,
        id: 'ANNIE_ERNAUX_6388311',
      },
    ],
  },
];
