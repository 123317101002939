import cx from 'clsx';
import type { IconType } from 'react-icons';
import { BiLoaderAlt } from 'react-icons/bi';
import type {
  ClassNames,
  PxSize,
  TextColorClassName,
} from 'src/types/classNames';

export type SpinnerSize = 'small' | 'medium' | 'large';

export interface SpinnerProps {
  className?: string;
  color?: ClassNames<TextColorClassName>;

  /**
   * @default Fi
   */
  icon?: IconType;

  /**
   * @default 'medium'
   */
  size?: SpinnerSize;
}

const Sizes: Record<SpinnerSize, PxSize> = {
  small: 12,
  medium: 20,
  large: 64,
};

export function Spinner({
  color = ['text-gray-800' /* , 'dark:text-gray-100' */],
  className,
  icon: Icon = BiLoaderAlt,
  size = 'medium',
}: SpinnerProps) {
  return (
    <Icon className={cx('animate-spin', color, className)} size={Sizes[size]} />
  );
}
