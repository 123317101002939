/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { animated } from '@react-spring/web';

import type { HTMLAttributes, ReactNode } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageContainer } from 'src/components/layout/pageContainer.component';
import type { RootState } from 'src/store';
import type { DataWorldChartTypes } from 'src/types/types';
import { actions as searchActions } from '../../reducers/searchReducer';
import { cn } from '../../utils/cn';

export interface PrimaryNavbarProps extends HTMLAttributes<HTMLDivElement> {
  countries?: DataWorldChartTypes[];
  children: ReactNode;
  contentClassName?: string;
  leftNav: ReactNode;
}

export function PrimaryNavbar({
  className,
  children,
  contentClassName,
  leftNav,
  ...props
}: PrimaryNavbarProps) {
  const dispatch = useDispatch();
  const { isMegaMenuOpen } = useSelector((state: RootState) => state.search);

  const closeMegaMenuOnEsc = (e: KeyboardEvent) => {
    if (e.code?.toLowerCase() === 'escape') {
      dispatch(searchActions.setIsMegaMenuOpen({ isMegaMenuOpen: false }));
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', closeMegaMenuOnEsc);

    return () => {
      window.removeEventListener('keydown', closeMegaMenuOnEsc);
    };
  }, []);

  return (
    <animated.nav
      className={cn(
        'bg-white fixed w-full top-0 z-50 h-[66px] md:h-[101px] border-b',
        isMegaMenuOpen && 'md:h-[180px]',
        className
      )}
      aria-label="primary"
      {...props}
    >
      <PageContainer
        paddingYClassName="pt-2.5 pb-2"
        className={`flex min-h-[65px] items-center w-full mx-auto max-md:bg-white md:min-h-[100px] justify-between max-w-screen-2xl`}
      >
        {leftNav}
        <div className="bg-white">{children}</div>
      </PageContainer>
    </animated.nav>
  );
}
