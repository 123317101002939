/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import type { CountryData } from '../types/country';

export interface CountryReducerState {
  countryData: CountryData;
  isLoadingCountryData: boolean;
  lastRun: string;
}

const initialState: CountryReducerState = {
  countryData: {} as CountryData,
  isLoadingCountryData: false,
  lastRun: '',
};

const { actions, reducer: countryReducer } = createSlice({
  name: 'country',
  initialState,
  reducers: {
    setCountryData: (state, action) => {
      state.countryData = action.payload.countryData;
    },
    setIsLoadingCountryData: (state, action) => {
      state.isLoadingCountryData = action.payload.isLoadingCountryData;
    },
    setLastRun: (state, action) => {
      state.lastRun = action.payload.lastRun;
    },
  },
});

export { actions as countryActions, countryReducer };
