import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { Fragment } from 'react';

import ArrowDownIcon from 'src/assets/ArrowDownIcon';

import { cn } from 'src/utils/cn';

export default function NavDropdown() {
  const { t } = useTranslation('new-navbar');

  return (
    <Menu as="div" className="relative inline-block text-left font-Montserrat">
      <div>
        <Menu.Button className="min-w-[216px] hidden tablet:block text-left border-0 justify-between text-nowrap items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-[#0F1928] hover:text-[#257C6B]">
          <span className=" text-left w-fit font-medium block-inline">
            The Population Project
          </span>
          <ArrowDownIcon
            className="inline h-3 w-3 mx-2 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  prefetch={false}
                  href="/what-is-the-population-project"
                  className={cn(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  <span>{t('about-us')}</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  prefetch={false}
                  href={'/why-the-population-project'}
                  className={cn(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  <span>{t('why')}</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  prefetch={false}
                  href={'/who-is-the-population-project'}
                  className={cn(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  <span>{t('who-is')}</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  prefetch={false}
                  href={'/partners'}
                  className={cn(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  <span>{t('our-partners')}</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  prefetch={false}
                  href={'/methodology'}
                  className={cn(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  <span>{t('methodology')}</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  prefetch={false}
                  href={'/privacy'}
                  className={cn(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  <span>{t('privacy-policy')}</span>
                </Link>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <Link
                  prefetch={false}
                  href={'/blog'}
                  className={cn(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  <span>{t('blog')}</span>
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
