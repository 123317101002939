import cx from 'clsx';
import type {
  DetailedHTMLProps,
  ElementType,
  HTMLAttributes,
  ReactNode,
} from 'react';
import { forwardRef } from 'react';
import type {
  BgColorClassName,
  ClassNames,
  PaddingClassName,
  RoundedClassName,
  ShadowClassName,
} from 'src/types/classNames';

export type CardPadding = 'compact' | 'regular';

type CardSize = 'xsmall' | 'small' | 'regular' | 'large' | 'xlarge';

export interface CardProps
  extends Partial<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  > {
  /**
   * @default 'div'
   */
  component?: ElementType;

  header?: ReactNode;
  footer?: ReactNode;

  /**
   * @default transparent ? 'bg-transparent' : 'bg-gray-100'
   */
  bgColor?: ClassNames<BgColorClassName>;

  /**
   * @default 'rounded-md'
   */
  rounded?: ClassNames<RoundedClassName>;

  /**
   * @default 'shadow-sm'
   */
  shadow?: ShadowClassName;

  /**
   * @default 'regular'
   */
  size?: CardSize;

  /**
   * @default false
   */
  transparent?: boolean;
}

export function getPaddingClassNames(
  size: CardSize
): ClassNames<PaddingClassName> {
  switch (size) {
    case 'xsmall':
      return 'p-2';

    case 'small':
      return 'p-3';

    case 'regular':
      return 'p-4';

    case 'large':
      return ['py-6', 'px-6', 'sm:px-8'];

    case 'xlarge':
    default:
      return ['py-10', 'px-8', 'sm:px-12', 'sm:py-12'];
  }
}

export function getCardClassNames({
  rounded = 'rounded-md',
  shadow = 'shadow-none',
  transparent = false,
  bgColor = transparent ? 'bg-transparent' : 'bg-white',
  className,
}: Pick<
  CardProps,
  'bgColor' | 'rounded' | 'size' | 'shadow' | 'transparent' | 'className'
>) {
  return cx(
    'border',
    'overflow-hidden',
    bgColor,
    transparent ? 'border-gray-300' : 'border-gray-200',
    rounded,
    shadow,
    className
  );
}

export const Card = forwardRef<HTMLElement, CardProps>(
  (
    {
      bgColor,
      component: Component = 'div',
      children,
      className,
      header = null,
      footer = null,
      rounded,
      shadow,
      size = 'regular',
      transparent,
      ...rest
    },
    ref
  ) => {
    const sizeClassNames = getPaddingClassNames(size);
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Component
        ref={ref}
        className={getCardClassNames({
          bgColor,
          className,
          rounded,
          shadow,
          transparent,
        })}
        {...rest}
      >
        {!!header && (
          <header className={cx(sizeClassNames, 'border-b', 'border-gray-100')}>
            {header}
          </header>
        )}
        <div className={cx(sizeClassNames)}>{children}</div>
        {!!footer && (
          <footer className={cx(sizeClassNames, 'bg-gray-50')}>{footer}</footer>
        )}
      </Component>
    );
  }
);

Card.displayName = 'Card';
