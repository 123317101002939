import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { Stack } from 'src/components/core';
import { appConfig } from 'src/config';
import { homepageActions } from '../../reducers/homepageReducer';
import { actions as searchActions } from '../../reducers/searchReducer';

export type LogoSize =
  | 'small'
  | 'medium'
  | 'large'
  | 'xl'
  | '2xl'
  | 'small-mediu';

export interface LogoProps {
  className?: string;

  /**
   * @default 'regular'
   */
  contrast?: 'regular' | 'inverse';

  /**
   * @default false
   */
  hasLink?: boolean;

  /**
   * @default false
   */
  responsive?: boolean;

  /**
   * @default 'medium'
   */
  size?: LogoSize;

  /**
   * @default 'wide'
   */
  type?: 'icon' | 'wide' | 'stacked';

  /**
   * @default () => {}
   */
  resetNavbarFiels?: () => void;
}

const Dimensions: Record<LogoSize, number> = {
  small: 24,
  'small-mediu': 36,
  medium: 66,
  large: 64,
  xl: 96,
  '2xl': 148,
};

const TextAspectRatio = 6.4;
const StackedAspectRatio = 2.5;

export function Logo({
  className,
  contrast = 'regular',
  hasLink = false,
  responsive = false,
  size = 'medium',
  type = 'stacked',
  resetNavbarFiels,
}: LogoProps) {
  const dimension = Dimensions[size];
  const dispatch = useDispatch();
  const router = useRouter();
  const logo = (
    <Stack className={className}>
      {(type === 'icon' || type === 'wide') && (
        <Image
          alt={`${appConfig.brand.appName} Logo`}
          src="/brand/logo.svg"
          height={dimension}
          width={dimension}
        />
      )}
      {type === 'wide' && (
        <div className={responsive ? 'hidden sm:block' : undefined}>
          <Image
            alt={appConfig.brand.appName}
            src="/brand/logo-text.png"
            height={dimension}
            width={dimension * TextAspectRatio}
          />
        </div>
      )}
      {type === 'stacked' && (
        <Image
          alt={appConfig.brand.appName}
          src={
            contrast === 'inverse'
              ? '/brand/logo-stacked-inverse.png'
              : '/brand/logo-stacked.png'
          }
          height={dimension}
          width={dimension * StackedAspectRatio}
        />
      )}
    </Stack>
  );

  const resetHomepageState = () => {
    dispatch(homepageActions.reset());
    dispatch(searchActions.reset());
    resetNavbarFiels?.();
  };

  if (hasLink) {
    return (
      <Link href="/" className="shrink-0" onClick={resetHomepageState}>
        {logo}
      </Link>
    );
  }

  return logo;
}
