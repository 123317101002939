import cx from 'clsx';
import type {
  CSSProperties,
  DOMAttributes,
  ElementType,
  ReactNode,
} from 'react';
import { forwardRef } from 'react';
import type {
  ClassNames,
  FontSizeClassName,
  FontWeightClassName,
  Responsive,
  TextColorClassName,
} from 'src/types/classNames';

export type ColorVariant = 'normal' | 'faded';

export type TypeVariant =
  | FontSizeClassName
  | Array<FontSizeClassName | Responsive<FontSizeClassName>>;

export interface TypeProps
  extends Partial<DOMAttributes<JSX.IntrinsicElements>> {
  /**
   * @default false
   */
  bold?: boolean;

  color?: ClassNames<TextColorClassName>;

  children?: ReactNode;

  className?: string;

  id?: string;

  /**
   * @default 'p'
   */
  component?: ElementType;

  /**
   * @default 'normal'
   */
  colorVariant?: ColorVariant;

  style?: CSSProperties;

  /**
   * @default 'text-base'
   */
  variant?: TypeVariant | TypeVariant[];

  /**
   * @default 'normal'
   */
  weight?: FontWeightClassName;
}

type TypeClassNameArgs = Pick<
  TypeProps,
  'bold' | 'color' | 'className' | 'colorVariant' | 'variant' | 'weight'
>;

function getTypeColorClassName({
  color,
  colorVariant,
}: Pick<
  TypeClassNameArgs,
  'color' | 'colorVariant'
>): ClassNames<TextColorClassName> {
  if (!color) {
    switch (colorVariant) {
      case 'faded':
        return 'text-gray-500';

      case 'normal':
      default:
        return 'text-gray-900';
    }
  }

  return color;
}

export function getTypeClassName({
  bold = false,
  color,
  className,
  colorVariant = 'normal',
  variant = 'text-base',
  weight = bold ? 'font-bold' : 'font-normal',
}: TypeClassNameArgs = {}) {
  return cx(
    variant,
    getTypeColorClassName({
      color,
      colorVariant,
    }),
    weight,
    className
  );
}

export const Type = forwardRef(
  (
    {
      bold,
      color,
      children,
      className,
      component: Component = 'p',
      colorVariant,
      style,
      variant,
      weight,
      ...rest
    }: TypeProps,
    ref
  ) => {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Component
        className={getTypeClassName({
          bold,
          color,
          className,
          colorVariant,
          variant,
          weight,
        })}
        ref={ref}
        style={style}
        {...rest}
      >
        {children}
      </Component>
    );
  }
);

Type.displayName = 'Type';
