import React, { createRef } from 'react';

class OutsideClickHandler extends React.Component {
  wrapperRef = createRef();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  static defaultProps = { onOutsideClick: () => {}, styles: '' };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  handleClickOutside = (event) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { onOutsideClick } = this.props;
    if (
      this.wrapperRef.current &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      !this.wrapperRef.current.contains(event.target)
    ) {
      onOutsideClick();
    }
  };

  render() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { children, styles } = this.props;
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <div className={styles} ref={this.wrapperRef}>
        {children}
      </div>
    );
  }
}

export default OutsideClickHandler;
