import * as React from 'react';

interface IconProperties {
  className?: string;
  width?: number;
  height?: number;
  viewBox?: string;
  title?: string;
  onClick?: () => void;
  opacity1?: number;
  opacity2?: number;
  fill?: string;
}

function MyRecordsIcon({
  opacity1,
  opacity2,
  className,
  fill = '#409F85',
  ...props
}: IconProperties) {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1667 13L6.00002 11.1833L1.83335 13V2.16667H10.1667M10.1667 0.5H1.83335C1.39133 0.5 0.967403 0.675595 0.654842 0.988155C0.342282 1.30072 0.166687 1.72464 0.166687 2.16667V15.5L6.00002 13L11.8334 15.5V2.16667C11.8334 1.24167 11.0834 0.5 10.1667 0.5Z"
        fill="#6F757E"
        className={className}
      />
    </svg>
  );
}

export default MyRecordsIcon;
