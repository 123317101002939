import * as React from 'react';

interface IconProperties {
  className?: string;
  width?: number;
  height?: number;
  viewBox?: string;
  title?: string;
  onClick?: () => void;
  opacity1?: number;
  opacity2?: number;
  fill?: string;
}

function EditHistoryIcon({
  opacity1,
  opacity2,
  className,
  fill = '#409F85',
  ...props
}: IconProperties) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.16667 0.5C1.24167 0.5 0.5 1.24167 0.5 2.16667V13.8333C0.5 14.2754 0.675595 14.6993 0.988155 15.0118C1.30072 15.3244 1.72464 15.5 2.16667 15.5H13.8333C14.2754 15.5 14.6993 15.3244 15.0118 15.0118C15.3244 14.6993 15.5 14.2754 15.5 13.8333V8H13.8333V13.8333H2.16667V2.16667H8V0.5H2.16667ZM12.8167 1.33333C12.675 1.33333 12.525 1.39167 12.4167 1.5L11.4 2.50833L13.4833 4.59167L14.5 3.58333C14.7167 3.36667 14.7167 3 14.5 2.79167L13.2083 1.5C13.1 1.39167 12.9583 1.33333 12.8167 1.33333ZM10.8083 3.1L4.66667 9.25V11.3333H6.75L12.8917 5.18333L10.8083 3.1Z"
        fill="#6F757E"
        className={className}
      />
    </svg>
  );
}

export default EditHistoryIcon;
