import React from 'react';
import type { IconProps } from '../types/icons';

export default function ChevronDown({
  width = 25,
  height = 24,
  fill = 'none',
  className = '',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill={fill}
      className={className}
    >
      <g id="chevron-down" transform={`scale(${width / 25},${height / 24})`}>
        <path
          id="Vector"
          d="M8.0765 8.58002L12.6665 13.17L17.2565 8.58002L18.6665 10L12.6665 16L6.6665 10L8.0765 8.58002Z"
          fill="#6F757E"
        />
      </g>
    </svg>
  );
}
