import { Logo } from '../brand/logo.component';
import { SimpleLogo } from '../brand/simple-logo.component';

export function LogoNavbar() {
  return (
    <nav className="flex w-full items-center justify-center py-4">
      <SimpleLogo
        hasLink
        size={'small-mediu'}
        responsive
        className="mx-auto flex size-11 items-center justify-center md:hidden"
      />
      <Logo
        hasLink
        size={'medium'}
        responsive
        className="hidden  items-center justify-center md:flex"
      />
    </nav>
  );
}
