import cx from 'clsx';

interface DividerProps {
  className?: string;

  /**
   * @default 'horizontal'
   */
  variant?: 'horizontal' | 'vertical';
}

export function Divider({ className, variant = 'horizontal' }: DividerProps) {
  return (
    <div
      className={cx(
        'bg-gray-200',
        variant === 'horizontal' ? 'h-px' : ['w-px', 'h-full'],
        className
      )}
    />
  );
}
