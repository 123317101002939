import type { TooltipProps } from 'react-tooltip';
import Tooltip from 'react-tooltip';
import useIsBrowser from '../../hooks/useIsBrowser';

export default function ReactTooltip(props: Readonly<TooltipProps>) {
  const isBrowser = useIsBrowser();

  return (
    <div className="absolute">
      {isBrowser && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Tooltip {...props} />
      )}
    </div>
  );
}
