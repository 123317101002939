import cx from 'clsx';
import type { ReactNode } from 'react';

export interface PageContainerProps {
  children: ReactNode;
  className?: string;
  /**
   * @default false
   */
  fullWidth?: boolean;

  /**
   * @default 'py-2 sm:pt-4'
   */
  paddingYClassName?: string;

  /**
   * @default props.fullWidth ? undefined : 'px-2 sm:px-4'
   */
  paddingXClassName?: string;
}

export function PageContainer({
  children,
  className,
  fullWidth = false,
  paddingYClassName = 'pb-2',
  paddingXClassName = fullWidth ? undefined : 'px-2 sm:px-4',
}: PageContainerProps) {
  return (
    <div
      className={cx(
        paddingYClassName,
        paddingXClassName,
        !fullWidth && 'max-w-7xl',
        className
      )}
    >
      {children}
    </div>
  );
}
