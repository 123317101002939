/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { animated, useSpring } from '@react-spring/web';
import { getCookie } from 'cookies-next';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { Dispatch, MouseEventHandler, SetStateAction } from 'react';
import { useEffect, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import WorldIcon from 'src/assets/WorldIcon';
import XIcon from 'src/assets/XIcon';
import { homepageActions } from 'src/reducers/homepageReducer';
import type { RootState } from 'src/store';
import type { OptionTypes } from 'src/types/actionSheetTypes';
import type { SearchesTypes } from 'src/types/search';
import type { DataWorldChartTypes } from 'src/types/types';
import { setCookie } from 'src/utils/cookies';
import { displayToast } from 'src/utils/displayToast';
import { listOfPopularSearches } from 'src/utils/listOfPopularSearches';
import { trackCustomEvent } from 'src/utils/trackCustomEvents';
import ErrorIcon from '../../assets/ErrorIcon';
import useScreenLayout from '../../hooks/useScreenLayout';
import { actions as searchActions } from '../../reducers/searchReducer';
import Menu from '../core/menu';
import { H3 } from '../h3/h3';
import { InputFieldNoLabel } from '../input-field-no-label/input-field-no-label';
import { Paragraph } from '../paragraph/paragraph';

interface ActionSheetProps {
  countries: DataWorldChartTypes[];
  option: OptionTypes;
  handleOption: (option: OptionTypes) => MouseEventHandler<HTMLButtonElement>;
  extraClassName?: string;
  isOpenSearchFromClickToFindOut?: boolean;
  setIsOpenSearchFromClickToFindOut?: Dispatch<SetStateAction<boolean>>;
}

export const ActionSheet = ({
  countries,
  option,
  handleOption,
  extraClassName = '',
  isOpenSearchFromClickToFindOut,
  setIsOpenSearchFromClickToFindOut,
}: ActionSheetProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { isMobileLayout, isTabletLayout } = useScreenLayout();

  const { isLoggedIn } = useSelector((state: RootState) => state.user);

  const [isOpen, setIsOpen] = useState(false);

  const { isMegaMenuOpen, searchedCountry, allCountries, isFiltersHidden } =
    useSelector((state: RootState) => state.search);

  const { i18n, t } = useTranslation(['navbar', 'new-navbar']);

  const [selectedLanguageOption, setSelectedLanguageOption] = useState<string>(
    t('english')
  );

  const [selectedOption, setSelectedOption] = useState(null);

  const handleStateChange = (state: boolean) => () => {
    setIsOpen(state);
  };
  const handleMenuOption = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    handleOption('menu');
    setIsOpen(true);
  };

  const openSearch = (
    event?:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    event && event.preventDefault();
    dispatch(
      searchActions.setIsFiltersHidden({ isFiltersHidden: !isFiltersHidden })
    );
  };

  const [firstName, setFirstName] = useState('');

  const [lastName, setLastName] = useState('');

  const handleChangeLastName = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setLastName(e.target?.value);
  };

  const handleChangeFirstName = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setFirstName(e.target?.value);
  };

  useEffect(() => {
    router.query.firstName && setFirstName(router.query.firstName?.toString());
    router.query.lastName && setLastName(router.query.lastName?.toString());
  }, [router.query]);

  const selectedValue = () => {
    if (searchedCountry?.label) {
      return searchedCountry;
    }
    // @ts-ignore
    if (selectedOption?.label) {
      // @ts-ignore
      return selectedOption;
    }
  };

  const mostVisitedCountriesIds = getCookie('mostVisitedCountriesIds');

  const openMegaMenu = () => {
    if (!isMobileLayout && !isMegaMenuOpen) {
      dispatch(searchActions.setIsMegaMenuOpen({ isMegaMenuOpen: true }));
    }
  };

  const closeMegaMenu = () => {
    if (isMegaMenuOpen) {
      dispatch(searchActions.setIsMegaMenuOpen({ isMegaMenuOpen: false }));
    }
  };

  const handleSearch = () => {
    openMegaMenu();
    const countryFound = (countries || allCountries)?.find(
      // @ts-ignore
      (country: { iso_2: string }) => country.iso_2 === searchedCountry?.value
    );

    if (mostVisitedCountriesIds) {
      if (countryFound) {
        const newMostVisitedCountriesIds = [
          ...JSON.parse(mostVisitedCountriesIds as string),
          countryFound.ID,
        ];

        const filteredMostVisitedCountriesIds = [
          ...new Set(newMostVisitedCountriesIds),
        ];

        if (newMostVisitedCountriesIds.length < 4) {
          setCookie(
            'mostVisitedCountriesIds',
            JSON.stringify(filteredMostVisitedCountriesIds)
          );
        }
      }
    } else {
      setCookie(
        'mostVisitedCountriesIds',
        JSON.stringify([(countryFound as DataWorldChartTypes).ID])
      );
    }

    if (countryFound && lastName?.length > 0 && firstName?.length > 0) {
      dispatch(
        searchActions.setIsLoadingSearchResults({
          isLoadingSearchResults: true,
        })
      );

      trackCustomEvent(
        'Search from search bar',
        `${firstName} ${lastName} - ${countryFound?.name}`
      );

      closeMegaMenu();
      router.push({
        pathname: '/search',
        query: {
          firstName,
          lastName,
          // @ts-ignore
          country: searchedCountry?.value || selectedOption?.value,
          page: 1,
          pageSize: 10,
        },
      });
    }

    if (
      !countryFound ||
      lastName?.length < 1 ||
      !lastName ||
      firstName?.length < 1 ||
      !firstName
    ) {
      displayToast(t('please-select'), 'error', <ErrorIcon />);
    }

    if (!isFiltersHidden) {
      dispatch(searchActions.setIsFiltersHidden({ isFiltersHidden: true }));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectCountry = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    dispatch(
      searchActions.setSearchedCountry({
        searchedCountry: {
          ...selectedOption,
        },
      })
    );
  };

  const options = ((countries as DataWorldChartTypes[]) || allCountries)
    // .concat(mostVisitedCountries() as DataWorldChartTypes[]) // ! don't forget to put it back when in dev
    ?.map((country: DataWorldChartTypes, index: number) => ({
      value: country.iso_2,
      label: country.name,
      ...country,
      index: index,
    }));

  const [optionForCountryCursor, setOptionForCountryCursor] = useState(0);

  useEffect(() => {
    if (searchedCountry?.label !== options[optionForCountryCursor]?.label) {
      const foundCountry = options.find((option) => {
        return option.label === searchedCountry?.label;
      });
      if (foundCountry) {
        setOptionForCountryCursor(foundCountry?.index);
      }
    }
  }, [searchedCountry]);

  useEffect(() => {
    if (option === 'menu') {
      setIsOpen(true);
    }

    if (option === 'stats' || option === 'info') {
      dispatch(homepageActions.setOption({ option: option }));
    }

    if (option === 'account' && isLoggedIn) {
      router?.push({
        pathname: '/account',
      });
    }
  }, [option]);

  useEffect(() => {
    if (isOpenSearchFromClickToFindOut) {
      openSearch();
      setIsOpenSearchFromClickToFindOut?.(false);
    }
  }, [isOpenSearchFromClickToFindOut]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomOption = ({ innerProps, isDisabled, data, options }: any) => {
    const lastIndex = options.length - 1;
    const visitedCountries = options?.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (option: any) => option.visited === true
    );
    const restOfTheCountries = options?.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (option: any) => !option.visited
    );

    const lastVisitedCountryIndexByOrder =
      visitedCountries[visitedCountries?.length - 1];

    const isFirstElement =
      restOfTheCountries[0]?.label === data.label && !data.visited;

    const isFirstElementByVisited =
      visitedCountries[0]?.label === data.label && data.visited;

    const isLastElement = data.label !== options[lastIndex].label;

    const isLastElementByVisited =
      lastVisitedCountryIndexByOrder?.label === data.label &&
      data.visited === true;

    return !isDisabled ? (
      <div
        {...innerProps}
        className={`flex flex-col ${
          isMobileLayout || isTabletLayout ? 'px-[16px]' : 'px-[26px]'
        } ${
          isFirstElement
            ? isTabletLayout || isMobileLayout
              ? 'mt-[9px]'
              : 'mt-[18px]'
            : ''
        } ${isLastElement ? 'mb-[17px]' : ''} ${
          isLastElementByVisited ? 'border-b pb-[18px]' : ''
        } ${isFirstElementByVisited ? 'mt-[16px]' : ''}`}
      >
        <div className="flex cursor-pointer items-center">
          <ReactCountryFlag
            className={`${
              isMobileLayout || isTabletLayout ? 'mr-[10px]' : 'mr-[25px]'
            }`}
            countryCode={data.iso_2}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={data.name}
            style={{
              borderRadius: '9999px',
              width: isMobileLayout || isTabletLayout ? '23px' : '30px',
              height: isMobileLayout || isTabletLayout ? '23px' : '30px',
              border: '1px solid black',
            }}
          />
          <p
            className={`${
              isMobileLayout || isTabletLayout ? 'text-[12px]' : 'text-[20px]'
            } text-[#5A5A5A] hover:text-[#b6b6b6] ${
              optionForCountryCursor === data.index
                ? 'text-[#b6b6b6]'
                : 'text-[#5A5A5A]'
            } ${
              optionForCountryCursor === -1 && data.index === 0
                ? 'text-[#b6b6b6]'
                : ''
            }`}
          >
            {data.label}
          </p>
        </div>
      </div>
    ) : null;
  };

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);

  // Bottom Search

  const stylesForBottomSearch = useSpring({
    top: !isFiltersHidden ? '0px' : '1000px',
    display: !isFiltersHidden ? 'block' : 'none',
  });

  // The 3 popular results

  const [popularSearches, setPopularSearches] = useState<any[]>([]);

  const randomizedFiveResults = (searches: any[]) => {
    const randomized = searches.sort(() => Math.random() - 0.5);
    return randomized.slice(0, 3);
  };

  const handleClearFirstName = () => {
    setFirstName('');
  };

  const handleClearLastName = () => {
    setLastName('');
  };

  const { defaultNrOfResults } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    // Searched country iso_3
    const searchedCountryIso3 = searchedCountry?.iso_3;

    const searches = listOfPopularSearches?.find(
      (search) => search?.country === searchedCountryIso3
    )?.searches;

    let results = [];

    if (searches) {
      results = randomizedFiveResults(searches);
    } else {
      results = randomizedFiveResults(listOfPopularSearches?.[0]?.searches);
    }

    setPopularSearches(results);
  }, [searchedCountry]);

  // TODO - this should be a href instead of an onClick action
  const onHandlePopularSearchesClick = (search: SearchesTypes) => () => {
    const country = (countries || allCountries)?.find(
      (option: any) => option?.iso_3 === search?.country
    );

    const queryObject: {
      firstName?: string;
      lastName?: string;
      country?: string;
      page?: string;
      pageSize?: string;
      birthDay?: string;
      birthMonth?: string;
      birthYear?: string;
      id?: string;
      alive?: string;
    } = {
      firstName: search?.firstName,
      lastName: search?.lastName,
      country: country?.iso_2,
      page: '1',
      pageSize: defaultNrOfResults,
      alive: 'both',
    };

    if (search?.birth_day) {
      queryObject['birthDay'] = search?.birth_day?.toString();
    }

    if (search?.birth_month) {
      queryObject['birthMonth'] = search?.birth_month?.toString();
    }

    if (search?.birth_year) {
      queryObject['birthYear'] = search?.birth_year?.toString();
    }

    if (search?.id) {
      queryObject['id'] = search?.id?.toString();
    }

    router
      ?.push({
        pathname: '/search',
        query: queryObject,
      })
      ?.finally(() => {
        dispatch(
          searchActions.setSearchedCountry({
            searchedCountry: {
              ...country,
            },
          })
        );
        dispatch(searchActions.setIsFiltersHidden({ isFiltersHidden: true }));
      });

    trackCustomEvent(
      'Search from search bar',
      `${search?.firstName} ${search?.lastName} - ${country?.name}`
    );

    setSelectedOption(country as any);

    closeMegaMenu();
  };

  return (
    <div className="md:hidden">
      {!isFiltersHidden && (
        <style>
          {`
            html, body {
              height: 100% !important;
              overflow: hidden !important;
            }
          `}
        </style>
      )}
      <animated.div
        style={stylesForBottomSearch}
        className="fixed left-0 z-[1000] h-screen w-full pt-[5px]"
      >
        <div className="flex size-full flex-col justify-between bg-white pb-[100px]">
          <div className="flex">
            <button
              onClick={openSearch}
              className="ml-auto h-[31px]"
              aria-label="Open Search"
              id="open-search"
            >
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none">
                <g opacity="0.4">
                  <line
                    x1="22.8668"
                    y1="7.86702"
                    x2="7.77067"
                    y2="22.9631"
                    stroke="black"
                    strokeWidth="2"
                  />
                  <line
                    x1="22.6137"
                    y1="22.9633"
                    x2="7.51759"
                    y2="7.86716"
                    stroke="black"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </button>
          </div>
          <div className="mt-[-70px] flex flex-col">
            <H3 className="mx-auto text-[#409F85]">Find a Human</H3>
            <div className="h-[23px]" />
            <div className="relative px-[10px]">
              <InputFieldNoLabel
                inputRef={firstNameRef}
                onChange={handleChangeFirstName}
                placeholder="First Name"
                alt="First Name Input"
                inputClassName="w-full"
                value={
                  !isMegaMenuOpen &&
                  firstName.length > 0 &&
                  lastName.length > 0 &&
                  !isMobileLayout &&
                  searchedCountry
                    ? firstName +
                        ' ' +
                        lastName +
                        ' in ' +
                        // @ts-ignore
                        searchedCountry.label || selectedOption.label
                    : firstName
                }
                type="text"
              />
              {firstName && (
                <button
                  className="absolute bottom-[30%] right-[6%]"
                  onClick={handleClearFirstName}
                >
                  <XIcon />
                </button>
              )}
            </div>
            <div className="relative my-[20px] px-[10px]">
              <InputFieldNoLabel
                inputRef={lastNameRef}
                onChange={handleChangeLastName}
                placeholder="Last Name"
                inputClassName="w-full"
                value={lastName}
                type="text"
                alt="Last Name Input"
              />
              {lastName && (
                <button
                  className="absolute bottom-[30%] right-[6%]"
                  onClick={handleClearLastName}
                >
                  <XIcon />
                </button>
              )}
            </div>
            <div className="px-[10px]">
              <Select
                instanceId={'country'}
                placeholder={`Choose ${t('country')}`}
                isSearchable={false}
                defaultValue={selectedValue()}
                onChange={selectCountry}
                tabSelectsValue={false}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                options={options?.sort((a: any, b: any) => {
                  if (a?.visited === b?.visited) {
                    return 1;
                  }
                  return -1;
                })}
                onMenuOpen={openMegaMenu}
                components={{ Option: CustomOption }}
                blurInputOnSelect={true}
                styles={{
                  control: () => ({
                    position: 'relative',
                    width: '100%',
                    height: '42px',
                    display: 'flex',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'rgba(0,0,0,0.5)',
                    borderRadius: '5px',
                    transition: '300ms',
                    ':focus-within': {
                      borderWidth: '2px',
                      borderColor: 'rgba(154,220,225,0.7)', // #409F85
                    },
                  }),
                  placeholder: () => ({
                    marginTop: '-30px',
                    color: 'grey',
                    padding: '0',
                  }),
                  dropdownIndicator: () => ({
                    marginTop: '0px',
                    paddingRight: '10px',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  menu: () => ({
                    position: 'absolute',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    marginTop: 10,
                    width: '100%',
                    height: '150px',
                    boxShadow: '1px 1px 23px -10px rgba(0,0,0,0.75)',
                    overflow: 'scroll',
                  }),
                  menuList: (base) => ({
                    ...base,
                    borderRadius: '10px',
                    width: '100%',
                    height: '150px',
                    overflow: 'scroll',
                  }),
                }}
              />
            </div>
          </div>
          <div>
            <div className="flex flex-col px-[10px]">
              <Paragraph fontSize={20} className="text-center text-[#409F85]">
                Popular Searches
              </Paragraph>
              <div className="mt-[10px] flex flex-col">
                {popularSearches?.map((search) => (
                  <button
                    key={search.id}
                    className="flex items-center justify-between border-[#DCDCDC] p-[8px]"
                    onClick={onHandlePopularSearchesClick(search)}
                  >
                    <p className="text-[14px] text-[#5A5A5A]">
                      {search?.firstName} {search?.lastName}
                    </p>
                    <p className="text-[14px] text-[#5A5A5A]">
                      {search?.country}
                    </p>
                  </button>
                ))}
              </div>
            </div>
          </div>
          <button
            onClick={handleSearch}
            className="mx-[20px] mb-[5px] rounded-[5px] bg-[#409F85] py-[10px]"
            aria-label="Search"
            id="search-button"
          >
            <p className="text-white">Search</p>
          </button>
        </div>
      </animated.div>
      <div
        className={`fixed bottom-0 z-[49] flex h-[85px] w-full items-center justify-between border-t border-t-[#DCDCDC] bg-[#F4F4F4] px-[12px] ${extraClassName}`}
      >
        <Link
          href="/?display=info"
          rel="canonical"
          // @ts-ignore
          onClick={handleOption('info')}
          className={`relative mb-auto flex w-[44px] flex-col items-center pt-[10px] transition`}
          aria-label="Open Info"
          id="open-info"
        >
          {option === 'info' && (
            <div className="absolute top-0 h-[2px] w-full bg-[#409F85]" />
          )}
          <svg
            width="28"
            height="29"
            viewBox="0 0 28 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity={option === 'info' ? '1' : '0.5'}>
              <path
                d="M2.33337 3.72852H9.33337C10.5711 3.72852 11.758 4.22018 12.6332 5.09535C13.5084 5.97052 14 7.15751 14 8.39518V24.7285C14 23.8003 13.6313 22.91 12.9749 22.2536C12.3185 21.5973 11.4283 21.2285 10.5 21.2285H2.33337V3.72852Z"
                stroke={option === 'info' ? '#409F85' : 'black'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M25.6667 3.72852H18.6667C17.429 3.72852 16.242 4.22018 15.3668 5.09535C14.4917 5.97052 14 7.15751 14 8.39518V24.7285C14 23.8003 14.3687 22.91 15.0251 22.2536C15.6815 21.5973 16.5717 21.2285 17.5 21.2285H25.6667V3.72852Z"
                stroke={option === 'info' ? '#409F85' : 'black'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
          <p
            className={`mt-[2px] text-[10px] font-[500] leading-[12px] ${
              option === 'info' ? 'text-[#409F85]' : 'opacity-60'
            }`}
          >
            Info
          </p>
        </Link>
        <Link
          href="/?display=stats"
          rel="canonical"
          // @ts-ignore
          onClick={handleOption('stats')}
          className="relative mb-auto flex w-[44px] flex-col items-center pt-[10px]"
          aria-label="Open Stats"
          id="open-stats"
        >
          {option === 'stats' && (
            <div className="absolute top-0 h-[2px] w-full bg-[#409F85]" />
          )}
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity={option === 'stats' ? '1' : '0.5'}>
              <path
                d="M21 23.3333V11.6666"
                stroke={option === 'stats' ? '#409F85' : 'black'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 23.3333V4.66663"
                stroke={option === 'stats' ? '#409F85' : 'black'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 23.3334V16.3334"
                stroke={option === 'stats' ? '#409F85' : 'black'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
          <p
            className={`mt-[2px] text-[10px] font-[500] leading-[12px] ${
              option === 'stats' ? 'text-[#409F85]' : 'opacity-60'
            }`}
          >
            Stats
          </p>
        </Link>
        <Link
          href="/?display=search"
          rel="canonical"
          // @ts-ignore
          onClick={openSearch}
          className="mt-[-50px] flex flex-col items-center"
          aria-label="Open Search"
          id="open-search"
        >
          <svg
            className="rounded-[50px] border border-[#F4F4F4]"
            width="65"
            height="66"
            viewBox="0 0 65 66"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx="32.5"
              cy="32.5948"
              rx="32.5"
              ry="32.5948"
              fill="#409F85"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M30.3154 40.3961C35.8179 40.3961 40.2785 35.9224 40.2785 30.4039C40.2785 24.8854 35.8179 20.4117 30.3154 20.4117C24.8129 20.4117 20.3523 24.8854 20.3523 30.4039C20.3523 35.9224 24.8129 40.3961 30.3154 40.3961Z"
              stroke="white"
              strokeWidth="1.74004"
            />
            <path
              d="M36.6558 36.7625L45.7131 45.8462"
              stroke="white"
              strokeWidth="1.74004"
            />
          </svg>
        </Link>
        <Link
          href="/sign-in"
          className="relative mb-auto flex w-[44px] flex-col items-center pt-[10px]"
          aria-label="Open Account"
          // @ts-ignore
          onClick={handleOption('account')}
          id="open-account"
        >
          {(router.pathname == '/sign-in' || router.pathname == '/account') && (
            <div className="absolute top-0 h-[2px] w-full bg-[#409F85]" />
          )}
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              opacity={
                router.pathname == '/sign-in' || router.pathname == '/account'
                  ? '1'
                  : '0.5'
              }
            >
              <path
                d="M23.3334 24.5V22.1667C23.3334 20.929 22.8417 19.742 21.9666 18.8668C21.0914 17.9917 19.9044 17.5 18.6667 17.5H9.33341C8.09574 17.5 6.90875 17.9917 6.03358 18.8668C5.15841 19.742 4.66675 20.929 4.66675 22.1667V24.5"
                stroke={
                  router.pathname == '/sign-in' || router.pathname == '/account'
                    ? '#409F85'
                    : 'black'
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.9999 12.8333C16.5772 12.8333 18.6666 10.744 18.6666 8.16667C18.6666 5.58934 16.5772 3.5 13.9999 3.5C11.4226 3.5 9.33325 5.58934 9.33325 8.16667C9.33325 10.744 11.4226 12.8333 13.9999 12.8333Z"
                stroke={
                  router.pathname == '/sign-in' || router.pathname == '/account'
                    ? '#409F85'
                    : 'black'
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
          <p
            className={`mt-[2px] text-[10px] font-[500] leading-[12px] opacity-60 ${
              router.pathname == '/sign-in' || router.pathname == '/account'
                ? 'text-[#409F85]'
                : 'opacity-60'
            }`}
            id="account-info"
          >
            Account
          </p>
        </Link>
        <Link
          href="/world"
          rel="canonical"
          // @ts-ignore
          onClick={handleOption('world')}
          className="relative mb-auto flex w-[44px] flex-col items-center pt-[10px]"
          aria-label="Open Menu"
          id="open-menu"
        >
          {' '}
          {router.pathname == '/world' && (
            <div className="absolute top-0 h-[2px] w-full bg-[#409F85]" />
          )}
          <WorldIcon
            fill={router.pathname === '/world' ? '#409F85' : 'gray'}
            width={26}
            height={26}
          />
          <p
            className={`mt-1 text-[10px] font-[500] leading-[12px] ${
              router.pathname === '/world' ? 'text-[#409F85]' : 'opacity-60'
            }`}
          >
            World
          </p>
        </Link>
      </div>
      <Menu
        isOpen={isOpen}
        handleStateChange={handleStateChange}
        isMobileLayout={isMobileLayout}
        isTabletLayout={isTabletLayout}
      />
    </div>
  );
};
