import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

export default function useScreenLayout() {
  const [isSmallMobileLayout, setIsSmallMobileLayout] =
    useState<boolean>(false);
  const [isMobileLayout, setIsMobileLayout] = useState<boolean>(false);
  const [isTabletLayout, setIsTabletLayout] = useState<boolean>(false);
  const [isBigDesktopLayout, setIsBigDesktopLayout] = useState<boolean>(false);
  const [isDesktopLayout, setIsDesktopLayout] = useState<boolean>(false);
  const [isBetweenTabletAndDesktop, setIsBetweenTabletAndDesktop] =
    useState<boolean>(false);

  const checkForMobileLayout: () => void = debounce(() => {
    if (window.outerWidth < 370) {
      setIsSmallMobileLayout(true);
    } else {
      setIsSmallMobileLayout(false);
    }
    if (window.outerWidth <= 768) {
      setIsMobileLayout(true);
    } else {
      setIsMobileLayout(false);
    }
    if (window.outerWidth < 1230 && window.outerWidth > 768) {
      setIsTabletLayout(true);
    } else {
      setIsTabletLayout(false);
    }
    if (window.innerWidth < 1450 && window.innerWidth > 1080) {
      setIsDesktopLayout(true);
    } else {
      setIsDesktopLayout(false);
    }
    if (window.innerWidth > 1450) {
      setIsBigDesktopLayout(true);
    } else {
      setIsBigDesktopLayout(false);
    }
    if (window.outerWidth > 1230 && window.outerWidth < 1350) {
      setIsBetweenTabletAndDesktop(true);
    } else {
      setIsBetweenTabletAndDesktop(false);
    }

    if (window.innerWidth <= 768) {
      setIsMobileLayout(true);
    } else {
      setIsMobileLayout(false);
    }
    if (window.innerWidth < 1230 && window.innerWidth > 768) {
      setIsTabletLayout(true);
    } else {
      setIsTabletLayout(false);
    }
    if (window.outerWidth < 1450 && window.outerWidth > 1080) {
      setIsDesktopLayout(true);
    } else {
      setIsDesktopLayout(false);
    }
    if (window.outerWidth > 1450) {
      setIsBigDesktopLayout(true);
    } else {
      setIsBigDesktopLayout(false);
    }
  }, 100);

  useEffect(() => {
    checkForMobileLayout();
    window.addEventListener('resize', checkForMobileLayout);

    return () => {
      window.removeEventListener('resize', checkForMobileLayout);
    };
  }, []);

  return {
    isSmallMobileLayout,
    isMobileLayout,
    isTabletLayout,
    isBigDesktopLayout,
    isDesktopLayout,
    isBetweenTabletAndDesktop,
  };
}
