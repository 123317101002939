import type { AnyAction } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import thunk from 'redux-thunk';
import { countryReducer } from './reducers/countryReducer';
import { editReducer } from './reducers/editFlowReducer';
import { firstNameReducer } from './reducers/firstNameReducer';
import { homepageReducer } from './reducers/homepageReducer';
import { lastNameReducer } from './reducers/lastNameReducer';
import { searchReducer } from './reducers/searchReducer';
import { userReducer } from './reducers/userReducers';

const combinedReducer = combineReducers({
  search: searchReducer,
  user: userReducer,
  country: countryReducer,
  firstName: firstNameReducer,
  lastName: lastNameReducer,
  homepage: homepageReducer,
  edit: editReducer,
});

const rootReducer = (
  state: ReturnType<typeof combinedReducer>,
  action: AnyAction
) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.payload,
    };
  } else {
    return combinedReducer(state, action);
  }
};

export const createStore = () =>
  configureStore({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    reducer: rootReducer,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ immutableCheck: { warnAfter: 200 } }).concat(
        thunk
      ),
  });

export const wrapper = createWrapper(createStore);

export type RootState = ReturnType<typeof combinedReducer>;
