import { format } from 'date-fns';
import moment from 'moment';

const integerFormatter = new Intl.NumberFormat('en-US');

export function formatInteger(num: number) {
  return integerFormatter.format(num);
}

export function formatDay(dateStr: string) {
  return format(new Date(dateStr), 'LLLL d, yyyy');
}

export function formatNumber(number: string | number) {
  return number?.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
}

export function formatString(string: string, len = 10) {
  if (string?.length > len) {
    return string?.slice(0, len) + '...';
  } else {
    return string;
  }
}

export function formatName(string: string) {
  if (!string) {
    return '';
  }
  const words = string.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = (words[i][0]?.toUpperCase() || '') + (words[i]?.slice(1) || '');
  }

  return words.join(' ');
}

export function formatOldBlogPostDates(
  dateAfterStrapi: string,
  id: number,
  authorName: string
) {
  if (id === 26) {
    return authorName + ' · ' + 'June 6, 2021';
  }
  if (id === 25) {
    return authorName + ' · ' + 'June 10, 2021';
  }
  if (id === 24) {
    return authorName + ' · ' + 'July 12, 2021';
  }
  if (id === 23) {
    return authorName + ' · ' + 'September 19, 2021';
  }
  if (id === 22) {
    return authorName + ' · ' + 'October 6, 2021';
  }
  if (id === 21) {
    return authorName + ' · ' + 'December 8, 2021';
  }
  if (id === 20) {
    return authorName + ' · ' + 'March 4, 2022';
  }
  if (id === 19) {
    return authorName + ' · ' + 'August 2, 2022';
  }
  if (id === 18) {
    return authorName + ' · ' + 'October 6, 2022';
  }
  if (id === 17) {
    return authorName + ' · ' + 'November 3, 2022';
  }
  if (id === 16) {
    return authorName + ' · ' + 'January 9, 2023';
  }
  if (id === 15) {
    return authorName + ' · ' + 'February 9, 2023';
  }
  return dateAfterStrapi;
}

export const monthDayYearFormatRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
export const monthYearFormatRegex = /^\d{1,2}\/\d{4}$/;
export const yearFormatRegex = /^\d{4}$/;
export const nameRegex =
  /^[\sa-zA-Z\u00C0-\u00FFœĞğİıŞş'ŚśŠšĀāĆćČčĒēĖėĘęŪūĪīĮįŌōŁłŽžŹźŻż-]+$/u;
export const optionalNameRegex =
  /^[\sa-zA-Z\u00C0-\u00FFœĞğİıŞş'ŚśŠšĀāĆćČčĒēĖėĘęŪūĪīĮįŌōŁłŽžŹźŻż-]*$/u;

export const isLeapYear = (year: number): boolean => {
  // Leap years are divisible by 4 except for end-of-century years, which must be divisible by 400
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const getMaxDaysInMonth = (year: number, month: number): number => {
  const daysInMonth = [
    31, // January
    isLeapYear(year) ? 29 : 28, // February (leap year has 29 days)
    31, // March
    30, // April
    31, // May
    30, // June
    31, // July
    31, // August
    30, // September
    31, // October
    30, // November
    31, // December
  ];
  return daysInMonth[month - 1];
};

/**
 * Formats a date string to uppercase month and year.
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted date string with uppercase month and year.
 */
export const formatMMMMYYYYUpperCase = (dateString?: string) => {
  if (!dateString) return '';

  return moment(dateString).format('MMMM YYYY').toUpperCase();
};

/**
 * Checks if the given ISO_3 country code is in the array of countries with "the" in their names.
 * @param {string} iso3 - The ISO_3 country code to check.
 * @returns {boolean} Returns true if the country is in the array, otherwise false.
 */
export const isInCountriesWithThe = (iso3?: string) => {
  if (!iso3) return false;
  const countriesWithThe = [
    'ATG', // Antigua and Barbuda
    'BHS', // The Bahamas
    'BTN', // Bhutan
    'FJI', // Fiji
    'GMB', // The Gambia
    'GIN', // Guinea
    'GRC', // Greece
    'CYM', // Cayman Islands
    'NER', // Niger
    'NLD', // Netherlands
    'PHL', // Philippines
    'SOM', // Somalia
    'SDN', // Sudan
    'TCA', // Turks and Caicos Islands
    'GBR', // United Kingdom
    'USA', // United States
    'VGB', // British Virgin Islands
  ];

  return countriesWithThe.includes(iso3);
};
